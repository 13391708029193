import {observer} from "mobx-react-lite";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "./loader.less"

const LoaderPage = observer(({ classDefault="loader_page" }) => {

    return(
        <div className={ classDefault }>
            <div className="loader_page_block">
                <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
        </div>
    )
})

export default LoaderPage