import {makeAutoObservable} from "mobx";
import deleteFile from "../../store/deleteFile.controller";

class _editor {

    blocks = []
    block = {}
    link = {}
    image = {}

    constructor() {
        makeAutoObservable(this)
    }

    setBlock(name, value) {
        this.block[name] = value
    }

    blurInput() {
        let data = [];
        let i = 1;
        if(this.block.id && this.block.id >= 0) {
            this.blocks.map(item => {
                if(this.block.id === i) {
                    data = [...data, {
                        id: i,
                        type: this.block.type,
                        value: this.block.value
                    }];
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
        } else {
            this.blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: this.block.type,
                value: this.block.value
            }];
        }
        this.blocks = data
        this.block = {
            type: "",
            value: ""
        }
    }

    onKeyUpInput(e, id = 0) {
        if(e.code === "Enter") {
            // setBlock({
            //     type: "",
            //     value: ""
            // });
            //newBlock("text", id);
        }
    }

    selectBlock(id) {
        let element = this.blocks.find(item => item.id === id);
        this.block = {
            id: id,
            type: element.type,
            value: element.value
        }
    }

    selectLink(id) {
        this.link = {
            id: id,
            display: true,
            name: this.blocks.find(el => el.id === id).name,
            url: this.blocks.find(el => el.id === id).value,
        }
    }

    deleteInput(id = 0) {
        let i = 1;
        let data = [];
        this.blocks.map(item => {
            if(id !== item.id) {
                data = [...data, {
                    id: i,
                    type: item.type,
                    value: item.value
                }];
            } else {
                if(item.type === "image") {
                    deleteFile("file", item.value.path)
                }
            }
            i = i + 1;
        });
        this.blocks = data
    }

    deleteInput(id = 0) {
        let i = 1;
        let data = [];
        this.blocks.map(item => {
            if(id !== item.id) {
                data = [...data, {
                    id: i,
                    type: item.type,
                    value: item.value
                }];
            } else {
                if(item.type === "image") {
                    deleteFile("file", item.value.path)
                }
            }
            i = i + 1;
        });
        this.blocks = data
    }

    newImage(id = 0) {
        if(id > 0) {
            this.image = { ...this.image, display: true, id: id }
        } else {
            this.image = { ...this.image, display: true }
        }
    }

    addInput = (id = 0, type="text") => {
        let i = 1;
        let data = [];
        let selected = 0;
        this.blocks.map(item => {
            if(id === i) {
                data = [...data, {
                    id: i,
                    type: item.type,
                    value: item.value
                }];
                i = i + 1;



                if(type === "link") {
                    data = [...data, {
                        id: i,
                        type: type,
                        name: "",
                        value: ""
                    }];
                    selected = i;
                } else {
                    data = [...data, {
                        id: i,
                        type: type,
                        value: ""
                    }];
                }
                this.block = {
                    id: i,
                    type: type,
                    value: ""
                }
                if(selected > 0) {
                    this.link = {
                        id: i,
                        display: true,
                        name: "",
                        url: "",
                    }
                }

            } else {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
            }
            i = i + 1;
        });
        this.blocks = data
    }

    newBlock = (type, id = 0) => {
        console.log(id);
        let data = [];
        let i = 1;
        let select = 0;
        if(this.block.id && this.block.id >= 0) {
            this.blocks.map(item => {
                if(this.block.id === i) {
                    data = [...data, {
                        id: i,
                        type: this.block.type,
                        value: this.block.value
                    }];
                    if(id > 0) {
                        i = i + 1;
                        data = [...data, {
                            id: i,
                            type: "text",
                            value: ""
                        }];
                        select = i;
                        this.block = {
                            id: i,
                            type: "text",
                            value: ""
                        }
                    }
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
        } else {
            this.blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            if(this.block.type !== "") {
                data = [...data, {
                    id: i,
                    type: this.block.type,
                    value: this.block.value
                }];
            }
        }
        this.blocks = data


        if(id === 0) {
            if(type === "text") {
                let count = 1;
                if(i > 0) {
                    count = i + 1
                }
                this.block = {
                    type: "text",
                    value: ""
                }
            } else if(type === "image") {
                this.block = {
                    type: "image",
                    value: ""
                }
            } else if(type === "link") {
                this.block = {
                    type: "link",
                    value: ""
                }
            }
        } else {
            this.block = {
                id: select,
                type: "text",
                value: ""
            }
        }
    }

    newLink(id = 0) {
        if(id > 0) {
            this.link = { ...this.link, display: true, id: id }
        } else {
            this.link = { ...this.link, display: true }
        }
    }

    saveImage = () => {
        if(this.image.id > 0) {
            let i = 1;
            let data = [];
            this.blocks.map(item => {
                if(this.image.id === i) {
                    data = [...data, {
                        id: i,
                        type: item.type,
                        value: item.value
                    }];
                    i = i + 1;
                    data = [...data, {
                        id: i,
                        type: "image",
                        value: this.image.url
                    }];
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
            this.blocks = data
            this.closeImage();
        } else {
            let i = 1;
            let data = [];
            this.blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: "image",
                value: this.image.url
            }];
            this.blocks = data
            this.closeImage();
        }
    }

    closeImage() {
        this.image = {
            display: false,
            url: {},
            id: 0
        }
    }

    setImage(value) {
        this.image = { ...this.image, url: value }
    }



    saveLink = () => {
        if(this.link.id > 0) {
            let i = 1;
            let data = [];
            this.blocks.map(item => {
                if(this.link.id === i) {
                    data = [...data, {
                        id: i,
                        type: "link",
                        name: this.link.name,
                        value: this.link.url
                    }];
                } else {
                    if(item.type === "link") {
                        data = [...data, {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }];
                    } else {
                        data = [...data, {
                            id: i,
                            type: item.type,
                            value: item.value
                        }];
                    }

                }
                i = i + 1;
            });
            this.blocks = data
            this.closeLink()
        } else {
            let i = 1;
            let data = [];
            this.blocks.map(item => {
                data = [...data, {
                    id: i,
                    type: item.type,
                    name: item.name,
                    value: item.value
                }];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: "link",
                name: this.link.name,
                value: this.link.url
            }];
            this.blocks = data
            this.closeLink()
        }
    }

    closeLink() {
        this.link = {
            display: false,
            name: "",
            url: "",
            id: 0
        }
    }

    setLink(name, value) {
        this.link[name] = value
    }

    setBlocks(data) {
        this.blocks = data
    }

}

export default new _editor()