import { observer } from "mobx-react-lite";
import { Breadcrumb, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

const TemplateTitleBreadcrumb = observer(({ breadcrumb=[] }) => {

    const { t } = useTranslation();

    return(
        <Breadcrumb>
            <Breadcrumb.Item key="breadcrumb-home" href={"/"}>{t('order:main')}</Breadcrumb.Item>
            {breadcrumb.length > 0 && breadcrumb.map(item => (
                <Breadcrumb.Item key={"breadcrumb-" + item.key} href={item.link ? item.link : null}>{ item.value }</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
})

const TemplateTitle = observer(({ title, breadcrumb, buttons = [] }) => {

    return(
        <Row justify="space-around" align="middle" className="title_page">
            <Col span={24}>
                <TemplateTitleBreadcrumb breadcrumb={breadcrumb} />
            </Col>
            <Col span={buttons.length > 0 ? 12 : 24}>
                <h1>{ title }</h1>
            </Col>
            {buttons.length > 0 &&
                <Col span={12} className="right">
                    <Space>
                        {buttons.map(item =>
                            <>
                                {item.type === "link" &&
                                    <a href={item.link} className={item.active ? "button-main" : "button-main_no-active"}>{ item.title }</a>
                                }
                                {item.type === "button" &&
                                    <button onClick={item.ref} className="button-main">{ item.title }</button>
                                }
                                {item.type === "submit" &&
                                    <button type="submit" className="button-main">{ item.title }</button>
                                }
                            </>
                        )}
                    </Space>
                </Col>
            }
        </Row>
    )
})

export default TemplateTitle