import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import _promo from "../controllers/promo.controller"

import React, { useEffect } from "react"

import TemplateTitle from "../../../template/title/TemplateTitle";
import {Col, DatePicker, Input, InputNumber, Row, Checkbox, Select, Tag} from "antd";
import moment from "moment";

const ShopPromo = observer(() => {

    let { couponID } = useParams();

    useEffect(() => {
        _promo.getCoupon(couponID)
    }, [])

    return(
        <>
            <TemplateTitle
                title="Редактирование промокода"
                breadcrumb={_promo.breadcrumb}
                buttons={[
                    { type: "button", title: "Сохранить", ref: (e) => _promo.saveCoupon(e), primary: true },
                    { type: "link", title: "Вернуться к списку", link: "/shop/promo" }
                ]}
            />

            <p><Tag color="#108ee9">Осталось применений: { _promo.coupon.countall > 0 ? _promo.coupon.count : "Не ограничено" }</Tag></p>

            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <label htmlFor="name" className="all-label">Наименование купона</label>
                    <Input
                        value={_promo.coupon.name}
                        id="name"
                        onChange={(e) => _promo.onChange("name", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <label htmlFor="code" className="all-label">Код купона</label>
                    <Input
                        value={_promo.coupon.code}
                        id="name"
                        onChange={(e) => _promo.onChange("code", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <label htmlFor="name" className="all-label">Общее кол-во использований (0 - бесконечно)</label>
                    <InputNumber
                        value={_promo.coupon.countall ? _promo.coupon.countall : 0}
                        id="count"
                        style={{ width: 100 + "%" }}
                        onChange={(e) => _promo.onChange("countall", e)}
                        required
                    />
                </Col>
                <Col span={24}>
                    <label htmlFor="text" className="all-label">Описание</label>
                    <Input.TextArea
                        rows={4}
                        value={_promo.coupon.text}
                        onChange={e => _promo.onChange("text", e.target.value)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">Начало действия купона</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.coupon.datestart > 0 ? moment(new Date(_promo.coupon.datestart), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChange("datestart", timeString)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">Окончание действия купона</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.coupon.dateend > 0 ? moment(new Date(_promo.coupon.dateend), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChange("dateend", timeString)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">Только 1 раз на пользователя</label>
                    <Checkbox
                        onChange={e => _promo.onChange("onetouser", e.target.checked)}
                        checked={_promo.coupon.onetouser}
                    >{_promo.coupon.onetouser ? "Включено" : "Выключено"}</Checkbox>
                </Col>
                <Col span={6}>
                    <label className="all-label">Только на первый заказ пользователя</label>
                    <Checkbox
                        onChange={e => _promo.onChange("firstorder", e.target.checked)}
                        checked={_promo.coupon.firstorder}
                    >{_promo.coupon.firstorder ? "Включено" : "Выключено"}</Checkbox>
                </Col>
                <Col span={6}>
                    <label className="all-label">Тип скидки</label>

                    <Select
                        style={{ width: 100 + "%" }}
                        value={_promo.coupon.type ? _promo.coupon.type : 0}
                        onChange={ e => _promo.onChange("type", e) }
                    >
                        <Select.Option value={0}>Скидка в рублях</Select.Option>
                        <Select.Option value={1}>Скидка в процентах</Select.Option>
                    </Select>
                </Col>
                <Col span={6}>
                    <label className="all-label">Значение скидки</label>

                    <InputNumber
                        value={_promo.coupon.value ? _promo.coupon.value : 0}
                        id="count"
                        style={{ width: 100 + "%" }}
                        onChange={(e) => _promo.onChange("value", e)}
                        required
                    />
                </Col>
                <Col span={12}>
                    <label className="all-label">Минимальная сумма корзины для действия купона</label>

                    <InputNumber
                        value={_promo.coupon.summcard ? _promo.coupon.summcard : 0}
                        id="count"
                        style={{ width: 100 + "%" }}
                        onChange={(e) => _promo.onChange("summcard", e)}
                        required
                    />
                </Col>
            </Row>
        </>
    )
})

export default ShopPromo