import {
    Button,
    Col,
    Input,
    InputNumber,
    Row,
    Typography,
    Upload,
    Select,
    Tag,
    notification,
    Tooltip,
    Space,
    Breadcrumb
} from "antd";
import React, {useEffect, useState} from "react";

import {DeleteOutlined, InboxOutlined} from '@ant-design/icons';
import {useTranslation, withTranslation} from "react-i18next";
import instance from "../../../../store/axios";
import deleteFile from "../../../../store/deleteFile.controller";
import getLoader from "../../../../store/uploadFiles.controller";
import {useParams} from "react-router-dom";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

function SubProduct() {
    let { subID, productID } = useParams();
    const { t } = useTranslation();

    const [product, setProduct] = useState({
        id: subID,
        name: "",
        id_crm: "",
        priceold: null,
        price: null,
        count: null,
        text: "",
        property: [],
        pictures: [],
        breadcrumb: []
    });

    const [list, setList] = useState([]);

    const [selectProps, setSelectProps] = useState({
        name: "",
        value: ""
    });

    const [nameProduct, setNameProduct] = useState("");

    const [update, setUpdate] = useState(0);



    useEffect(() => {
        if(Number(subID) > 0) {
            instance.get("shop/sub/" + subID)
                .then(success => {
                    console.log(success);
                    setProduct({
                        ...product,
                        name: success.data.data.name,
                        id_crm: success.data.data.id_crm,
                        priceold: success.data.data.priceold,
                        price: success.data.data.price,
                        count: success.data.data.count,
                        text: success.data.data.text,
                        property: success.data.data.property,
                        pictures: success.data.data.photo,
                        breadcrumb: success.data.data.breadcrumb,
                    });
                })
                .catch(error => console.log(error.response));
        } else {
            instance.get("shop/product/" + productID)
                .then(success => {
                    console.log(success);
                    setProduct({
                        ...product,
                        name: success.data.data.name,
                        id_crm: success.data.data.id_crm,
                        priceold: success.data.data.priceold,
                        price: success.data.data.price,
                        count: success.data.data.count,
                        text: success.data.data.text,
                        breadcrumb: success.data.data.breadcrumb,
                    });
                    setNameProduct(success.data.data.name);
                })
                .catch(error => console.log(error.response));
        }
    }, [update]);

    const updateData = (value) => {
        let data = product.pictures.length > 0 ? product.pictures : []
        data.push(value)
        setProduct({ ...product, pictures: data })
    }

    async function deletePicture(url) {
        await deleteFile("productvariation", url, Number(subID))
        setProduct({ ...product, pictures: product.pictures.filter(el => el.path !== url) })
    }

    useEffect(() => {
        instance.get('/shop/props/')
            .then(success => {
                console.log(success);
                setList(success.data.data )
            });
    }, []);

    const sendForm = (e) => {
        e.preventDefault();
        let facts = new FormData();
        facts.append('id', product.id);
        facts.append('name', product.name);
        facts.append('id_crm', product.id_crm);
        facts.append('priceold', product.priceold);
        facts.append('price', product.price);
        facts.append('count', product.count);
        facts.append('text', product.text);
        facts.append('props', JSON.stringify(product.property) );
        facts.append('productID', productID);
        facts.append('pictures', JSON.stringify(product.pictures));

        instance.post('/shop/sub/', facts).then(response => {
            console.log(response);
            if(response.data.code === 200 || response.data.code === 201){
                if(response.data.code === 201) {
                    document.location.href = "/shop/catalog/product/" + productID;
                } else {
                    notification.success({
                        placement: "bottomLeft",
                        message: t('sub:success'),
                        description: t('sub:editSuccess'),
                    });

                    setUpdate(update + 1);
                }
            }
        });
    };



    const selectType = (value) => {
        setSelectProps({ ...selectProps, name: value });
    };

    const selectTypeValue = (value) => {
        if(value !== "" && selectProps.name !== "") {
            let facts = [];
            if(product.property.length > 0) {
                facts = product.property;
            }

            let arr = {
                name: selectProps.name,
                value: value,
                sort: 1,
            };

            facts = [...facts, arr];

            setProduct({...product, property: facts});

            setSelectProps({ name: "", value: "" });
        }
    };

    const deleteTag = (name, value) => {
        let facts = [];
        product.property.map(item => {
            if(item.name === name && item.value === value) {

            } else {
                facts = [...facts, item];
            }
        });
        setProduct({...product, property: facts});
    };

    return(
        <>
            <form onSubmit={sendForm}>
                <Row justify="space-around" align="middle" className="template-title">
                    <Col span={16}>
                        <Typography.Title>{t('sub:edit')}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Space>
                            <Button type="primary" htmlType="submit">{t('sub:save')}</Button>
                            <Button type="dashed" href={"/shop/catalog/product/" + productID}>{t('sub:product')}</Button>
                        </Space>
                    </Col>
                    <Col span={24} className="template-breadcrumb">
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>{t('sub:main')}</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/shop"}>{t('sub:shop')}</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/shop/catalog"}>{t('sub:catalog')}</Breadcrumb.Item>
                            {product.breadcrumb.map(item => {
                                let link = "/shop/catalog/category/";
                                if(item[2] === "product") {
                                    link = "/shop/catalog/product/";
                                }
                                return(
                                    <Breadcrumb.Item href={link + item[0]}>{item[1]}</Breadcrumb.Item>
                                )
                            })}
                            <Breadcrumb.Item href={"/shop/catalog/product/" + productID}>{nameProduct}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t('sub:editProduct')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>


                <Row className="product">
                    <Col span={6} className="product-input_block left">
                        <label htmlFor="input1">{t('sub:name')}</label>
                        <Input
                            name="name"
                            value={ product.name }
                            onChange={(e) => setProduct({ ...product, name: e.target.value })}
                            required
                        />
                    </Col>
                    <Col span={6} className="product-input_block left">
                        <label htmlFor="input1">{t('sub:idCrm')}</label>
                        <Input
                            name="name"
                            value={ product.id_crm }
                            onChange={(e) => setProduct({ ...product, id_crm: e.target.value })}
                            required
                        />
                    </Col>
                    <Col span={6} className="product-input_block left">
                        <label htmlFor="input3">{t('sub:oldPrice')}</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={ product.priceold }
                            onChange={(e) => setProduct({ ...product, priceold: e })}
                        />
                    </Col>
                    <Col span={6} className="product-input_block left">
                        <label htmlFor="input2">{t('sub:price')}</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={ product.price }
                            onChange={(e) => setProduct({ ...product, price: e })}
                        />
                    </Col>
                    <Col span={6} className="product-input_block">
                        <label htmlFor="count">{t('sub:count')}</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={ product.count }
                            onChange={(e) => setProduct({ ...product, count: e })}
                        />
                    </Col>
                    <Col span={24} className="product-input_block">
                        <label htmlFor="text">{t('sub:description')}</label>
                        <Input.TextArea
                            value={product.text}
                            onChange={(e) => setProduct({ ...product, text: e.target.value })}
                        />
                    </Col>
                    <Col span={24} className="product-input_block">
                        <Typography.Title level={3}>{t('sub:props')}</Typography.Title>
                        {product.property.map(item => (
                            <Tag key={"tag" + item.name} closable onClose={() => deleteTag(item.name, item.value)}>
                                {item.name}: {item.value}
                            </Tag>
                        ))}
                    </Col>
                    <Col span={12} className="product-input_block">
                        <label htmlFor="text">{t('sub:selectNewProp')}</label>
                        <Select placeholder={t('sub:selectProp')} onChange={selectType} style={{ width: 100 + "%" }}>
                            {list.map(item => (
                                <Select.Option key={"optionProps" + item.name} value={item.name}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12} className="product-input_block">
                        {selectProps.name !== "" &&
                            <>
                                <label htmlFor="text">{t('sub:selectValueProp')}</label>
                                <Select placeholder={t('sub:selectValue')} onChange={selectTypeValue} style={{ width: 100 + "%" }}>
                                    {list.map(item => {
                                        if(item.name === selectProps.name)  {

                                            return(
                                                <>
                                                    {item.value.map(prop => (
                                                        <Select.Option key={"option" + prop.name} value={prop.name}>{prop.name}</Select.Option>
                                                    ))}
                                                </>
                                            )
                                        }
                                    })}
                                </Select>
                            </>
                        }
                    </Col>
                    <Col span={24} className="product-input_block">
                        <Typography.Title level={3}>{t('sub:pictures')}</Typography.Title>
                    </Col>
                    {product.pictures && product.pictures.length > 0 &&
                        <Col span={24} className="product-input_block">
                            {product.pictures.map(item => (
                                <div className="product-item_picture">
                                    <img src={item.path} alt="" />
                                    <Tooltip title={t('sub:delete')}>
                                        <Button
                                            danger
                                            type="dashed"
                                            className="picture-delete"
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            onClick={() => deletePicture(item.path)}
                                        />
                                    </Tooltip>
                                </div>
                            ))}
                        </Col>
                    }
                    <Col span={24} className="product-input_block">
                        <Upload.Dragger
                            multiple={true}
                            fileList={[]}
                            customRequest={e => getLoader(e, "images", "file", "sub", updateData)}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{t('sub:drop')}</p>
                            <p className="ant-upload-hint">
                                {t('sub:multiple')}
                            </p>
                        </Upload.Dragger>
                    </Col>
                </Row>
            </form>
        </>
    )
}

export default withTranslation()(SubProduct);