import { makeAutoObservable } from "mobx"
import hashController from "../../template/controllers/hash.controller";
import i18n from '../../../i18n';
import instance from '../../../store/axios';
import sender from '../../template/controllers/sender';

class __onboarding {

    breadcrumb = [
        { key: "element", link: null, value: "OnBoarding" }
    ]

    list = []
    element = {}

    modal = false

    filter = {
        location: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    setBlock(name, value, blockID) {
        this.editBlock(blockID, [ { name: name, value: value } ])
    }

    set(name, value) {
        this.element[name] = value
    }

    get(onBoardingID=0) {
        let url = `/home/onboarding`
        if(onBoardingID > 0) {
            url = `${url}/${onBoardingID}`
        }

        url += `?location=${this.filter.location}`
        sender.get(url)
            .then(response => {
                console.log(response)
                if(onBoardingID > 0) {
                    this.element = response.data
                } else {
                    this.list = response.data[0] ? response.data[0] : []
                }
            })
            .catch(response => console.log(response))
    }

    editBlock(blockID=0, data=[]) {
        let facts = new FormData()

        if(blockID > 0 && data.length > 0) {
            data.map(item => facts.append(item.name, item.value))
        } else {
            facts.append("active", this.element.active ? this.element.active : 1)
            facts.append("version", this.element.id ? this.element.version : 1)
            facts.append("typeview", this.element.typeview ? this.element.typeview : 1)
        }

        facts.append('hash', hashController.setHash(facts))

        sender.post(blockID > 0 ? `/home/onboarding/${blockID}` : `/home/onboarding/0`, facts)
            .then(response => {
                if(data.length > 0 && blockID > 0) {
                    this.reload()
                } else {
                    this.post(response.data.id)
                }
            })
            .catch(response => console.log(response))
    }

    post(parentID=0) {
        let facts = new FormData()

        facts.append("active", this.element.active ? this.element.active : 1)

        if(parentID > 0) {
            facts.append("onboardingid", parentID)
        }

        facts.append("name", this.element.name ? this.element.name : "")
        facts.append("namecolor", this.element.namecolor ? this.element.namecolor : "")
        facts.append("text", this.element.text ? this.element.text : "")
        facts.append("textcolor", this.element.textcolor ? this.element.textcolor : "")
        facts.append("photo", this.element.photo ? this.element.photo : "")
        facts.append("button", this.element.button ? this.element.button : 0)
        facts.append("buttontext", this.element.buttontext ? this.element.buttontext : "")
        facts.append("buttontextcolor", this.element.buttontextcolor ? this.element.buttontextcolor : "")
        facts.append("buttoncolor", this.element.buttoncolor ? this.element.buttoncolor : "")
        facts.append("location", this.element.location ? this.element.location : '')
        facts.append('hash', hashController.setHash(facts))

        sender.post(this.element.id && this.element.id > 0 ? `/home/onboardingslide/${this.element.id}` : `/home/onboardingslide/0`, facts)
            .then(response => {
                console.log(response)
                this.reload()
            })
            .catch(response => console.log(response))
    }

    save(e) {
        e.preventDefault()

        if(this.list[0] && this.list[0].id && this.list[0].id > 0) {
            this.post(this.list[0].id)
        } else {
            this.editBlock()
        }
    }

    create() {
        this.select({})
    }

    close() {
        this.element = {}
        this.modal = false
    }

    delete(type, recordID) {
        if(window.confirm(i18n.t("confirm:deleteElement"))) {
            let facts = {
                typestructure: type
            }

            sender.delete(`/product/delete/${recordID}`, {data: "typestructure=" + type + "&hash=" + hashController.setHashDelete(facts)})
                .then(success => {
                    console.log(success)
                    this.reload()
                })
                .catch(error => console.log(error.response))
        }
    }

    reload() {
        this.close()
        this.get()
    }

    select(element = {}) {
        this.element = element
        this.modal = true
    }

    setStatus(recordID, value) {
        console.log(value)

        let facts = new FormData()
        facts.append("active", value)
        facts.append("hash", hashController.setHash(facts))

        let url = `/home/onboardingslide/${recordID}`

        sender.post(url, facts)
            .then(response => this.reload())
    }

    upOrDown(recordID, action) {
        let facts = new FormData()
        facts.append("action", action)
        facts.append("class", "ksonboardingslide")
        facts.append("key", "onboardingid")
        facts.append('hash', hashController.setHash(facts))

        sender.post(`/home/position/${recordID}`, facts)
            .then(response => {
                console.log(response)
                this.reload()
            })
    }
}

export default new __onboarding()