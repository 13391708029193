import {Col, Row, Typography, Upload, message, Button, notification, Input, Breadcrumb} from "antd";
import React, {useState, useEffect} from "react";
import {InboxOutlined, UploadOutlined} from '@ant-design/icons';
import store from "store"
import {useTranslation, withTranslation} from "react-i18next";
import instance from "../../../store/axios";
import config from "../../../config.json";
import loaderController from "../../../modules/template/loader/loader.controller";
import LoaderPage from "../../../modules/template/loader/LoaderPage";
import {observer} from "mobx-react-lite";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

const propsUploadImport = {
        name: 'file',
        accept: ".csv,.xml,.yml",
        action: store.get("project") && store.get("project").baseURL !== ""
            ? store.get("project").baseURL + '/shop/upload'
            : config.config.host + 'shop/upload',
        headers: {
            Authorizations: config.config.apiKey,
            bandl: store.get("user") && store.get("user").bandl ? store.get("user").bandl : ""
        },
        data: {
            type: "import",
            path: store.get("user") && store.get("user").id ? store.get("user").id + '/import' : "",
        },
        fileList: [],
        onChange(info) {
            console.log(info)
            loaderController.onChange(true)
            if(info.file.status === "done") {
                loaderController.onChange(false)
                notification.success({
                    placement: "bottomLeft",
                    message: "Успешно",
                    description: "Импорт успешно завершен",
                });
            } else if(info.file.status === "error") {
                loaderController.onChange(false)
                notification.warning({
                    placement: "bottomLeft",
                    message: "Ошибка",
                    description: "Ошибка загрузки файла",
                });
            }
        }
}

const Import = observer(() => {

    const { t } = useTranslation();

    const [link, setLink] = useState("");

    // const propsUpload = {
    //     name: 'file',
    //     accept: ".csv,.xml,.yml",
    //     action: store.get("project") && store.get("project").baseURL !== ""
    //         ? store.get("project").baseURL + '/shop/upload'
    //         : config.config.host + 'shop/upload',
    //     headers: {
    //         Authorizations: config.config.apiKey,
    //         bandl: store.get("user").bandl
    //     },
    //     data: {
    //         type: "import",
    //         path: store.get("user").id + '/import',
    //     },
    //     fileList: [],
    //     onChange(info) {
    //         console.log(info)
    //         loaderController.onChange(true)
    //         if(info.file.status === "done") {
    //             loaderController.onChange(false)
    //             notification.success({
    //                 placement: "bottomLeft",
    //                 message: t('import:success'),
    //                 description: t('import:addSuccess'),
    //             });
    //         } else if(info.file.status === "error") {
    //             loaderController.onChange(false)
    //             notification.warning({
    //                 placement: "bottomLeft",
    //                 message: t('import:error'),
    //                 description: t('import:errorFile'),
    //             });
    //         }
    //     },
    // };

    useEffect(() => {
        instance.get("/project/project")
            .then(success => {
                console.log(success)
                setLink(success.data.ymlupload)
            });
    }, []);

    const updateData = (value) => {
        console.log(value);
        let facts = new FormData();
        facts.append("link", value)

        instance.post("/shop/import", facts)
            .then(success => {
                notification.success({
                    placement: "bottomLeft",
                    message: t('import:success'),
                    description: t('import:addSuccess'),
                });
            })
            .catch(error => console.log(error));
    }

    const sendLink = (e) => {
        e.preventDefault()
        let facts = new FormData();
        facts.append("link", link)

        instance.post("/shop/import", facts)
            .then(success => {
                notification.success({
                    placement: "bottomLeft",
                    message: t('import:success'),
                    description: t('import:addSuccess'),
                });
            })
            .catch(error => console.log(error));
    };

    return(
        <>
            <form onSubmit={sendLink}>
                <Row justify="space-around" align="middle" className="template-title">
                    <Col span={16}>
                        <Typography.Title>{t('import:import')}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Button type="primary" disabled={link !== "" ? "" : "disabled"} htmlType="submit">{t('delivery:save')}</Button>
                    </Col>
                    <Col span={24} className="template-breadcrumb">
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>{t('import:main')}</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/shop"}>{t('import:shop')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t('import:setting')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row justify="space-around" align="middle" className="template-title">
                    <Col span={24}>
                        <h3>{t('import:file')}</h3>

                        <Upload {...propsUploadImport}>
                            <Button icon={<UploadOutlined />}>{t('import:download')}</Button>
                        </Upload>
                    </Col>
                    <Col span={24}>
                        <br/><br/>
                        <h3>{t('import:link')}</h3>
                        <Input
                            value={link}
                            onChange={e => setLink(e.target.value)}
                            placeholder={t('import:link')}
                            required
                        />
                    </Col>
                </Row>
            </form>
            {loaderController.load && <LoaderPage />}
        </>
    )
})

export default Import;