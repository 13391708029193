import { observer } from "mobx-react-lite"
import __onboarding from "./controllers/__onboarding.controller"
import { useEffect } from "react"
import TemplateTitle from "../template/title/TemplateTitle"
import { Row, Col, Input, InputNumber, Select, Modal, Switch, Button, Table, Space, Popconfirm, message } from "antd"
import ConstructorImageHelper from "../constructor/helpers/ConstructorImageHelper"
import __constructor from "../constructor/controllers/__constructor.controller";
import ConstructorActions from "../constructor/ConstructorActions";
import i from '../../i18n';

const GetConstructorElement = observer(({ item }) => {

    return(
        <>
            {item.type === "switch" &&
                <Col span={24} className="gutter-row">
                    <label htmlFor={item.name} className="all-label">{item.label}</label>
                    <Switch
                        size="small"
                        checked={__onboarding.element && __onboarding.element[item.name] && __onboarding.element[item.name] > 0 }
                        onChange={(e) => item.refs(e ? 1 : 0)}
                    />
                </Col>
            }
            {item.type === "string" &&
                <Col span={24} className="gutter-row">
                    <label htmlFor={item.name} className="all-label">{item.label}</label>
                    <Input
                        value={__onboarding.element && __onboarding.element[item.name] && __onboarding.element[item.name] ? __onboarding.element[item.name] : ""}
                        id={item.name}
                        onChange={(e) => item.refs(e.target.value)}
                        required={item.required ? "required" : ""}
                    />
                </Col>
            }
            {item.type === "image" &&
                <ConstructorImageHelper
                    photo={__onboarding.element.photo ? __onboarding.element.photo : ""}
                    refs={(e) => __onboarding.set("photo", e)}
                    // type={__onboarding.selectable.type}
                    id={__onboarding.element.id ? __onboarding.element.id : 0}
                />
            }
        </>
    )
})

const OnBoarding = observer(() => {

    useEffect(() => {
        __onboarding.get()
    }, [])

    const buttons = [
        { title: i.t('onboarding:addSlide'), type: "button", ref: () => __onboarding.create() }
    ]

    const modalConfig = [
        {
            type: "switch",
            name: "active",
            label: i.t('onboarding:active'),
            refs: (e) => __onboarding.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('onboarding:name'),
            refs: (e) => __onboarding.set("name", e)
        },
        {
            type: "string",
            name: "namecolor",
            label: i.t('onboarding:titleColor'),
            refs: (e) => __onboarding.set("namecolor", e)
        },
        {
            type: "string",
            name: "text",
            label: i.t('onboarding:text'),
            refs: (e) => __onboarding.set("text", e)
        },
        {
            type: "string",
            name: "textcolor",
            label: i.t('onboarding:textColor'),
            refs: (e) => __onboarding.set("textcolor", e)
        },
        {
            type: "image",
            name: "photo",
            label: i.t('onboarding:image'),
            required: true
        },
        {
            type: "dependence",
            name: "button",
            label: i.t('onboarding:button'),
            refs: (e) => __onboarding.set("button", e),
            dependence: true
        },
        {
            type: "string",
            dependences: "button",
            name: "buttoncolor",
            label: i.t('onboarding:buttonColor'),
            refs: (e) => __onboarding.set("buttoncolor", e)
        },
        {
            type: "string",
            dependences: "button",
            name: "buttontext",
            label: i.t('onboarding:buttonText'),
            refs: (e) => __onboarding.set("buttontext", e)
        },
        {
            type: "string",
            dependences: "button",
            name: "buttontextcolor",
            label: i.t('onboarding:buttonTextColor'),
            refs: (e) => __onboarding.set("buttontextcolor", e)
        }
    ]

    const columns = [
        {
            title: i.t('onboarding:image'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => <img src={text} alt="" height={`84px`} />,
        },
        {
            title: i.t('onboarding:name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: i.t('onboarding:buttonText'),
            dataIndex: 'buttontext',
            key: 'buttontext',
        },
        {
            title: i.t('onboarding:actions'),
            key: 'actions',
            align: "right",
            render: record => <ConstructorActions actions={[
                { type: "active", value: record.active, refs: (e) => __onboarding.setStatus(record.id, e) },
                { type: "edit", refs: () => __onboarding.select(record) },
                { type: "moveDown", refs: () => __onboarding.upOrDown(record.id, "moveDown") },
                { type: "moveUp", refs: () => __onboarding.upOrDown(record.id, "moveUp") },
                { type: "delete", refs: () => __onboarding.delete("ksonboardingslide", record.id) }
            ]}/>
        }
    ]

    return(
        <>
            <TemplateTitle
                title="OnBoarding"
                breadcrumb={__onboarding.breadcrumb}
                buttons={buttons}
            />

            {Array.isArray(__onboarding.list) && __onboarding.list.length > 0 && __onboarding.list.map(item => (
                <Row className="onboarding">
                    <Col span={24}>
                        <Row gutter={[10, 10]} className="filter">
                            <Col span={3}>
                                <label htmlFor={item.name} className="all-label">{i.t('onboarding:active')}</label>
                                <Switch
                                    size="small"
                                    checked={item.active && item.active > 0 }
                                    onChange={(e) => __onboarding.setBlock("active", e ? 1 : 0, item.id)}
                                />
                            </Col>
                            <Col span={8}>
                                <label htmlFor="" className="all-label">{i.t('onboarding:type')}</label>
                                <Select value={item.typeview} style={{ width: `100%` }} onChange={(e) => __onboarding.setBlock("typeview", e, item.id)}>
                                    <Select.Option value={1}>{i.t('onboarding:items.one')}</Select.Option>
                                    <Select.Option value={2}>{i.t('onboarding:items.two')}</Select.Option>
                                    <Select.Option value={3}>{
                                        i.t('onboarding:items.three')
                                    }</Select.Option>
                                    <Select.Option value={4}>{i.t('onboarding:items.four')}</Select.Option>
                                </Select>
                            </Col>
                            <Col span={8}>
                                <label htmlFor="" className="all-label">{i.t('onboarding:location')}</label>
                                <Select
                                    value={__onboarding.filter.location}
                                    style={{ width: `100%` }}
                                    onChange={(e) => {
                                        __onboarding.filter.location = e
                                        __onboarding.get()
                                    }}
                                >
                                    <Select.Option value="">{i.t('onboarding:locations.one')}</Select.Option>
                                    <Select.Option value="user">{i.t('onboarding:locations.two')}</Select.Option>
                                    <Select.Option value="map">{i.t('onboarding:locations.three')}</Select.Option>
                                    <Select.Option value="list">{i.t('onboarding:locations.four')}</Select.Option>
                                    <Select.Option value="profile">{i.t('onboarding:locations.five')}</Select.Option>
                                    <Select.Option value="transcript">{i.t('onboarding:locations.six')}</Select.Option>
                                </Select>
                            </Col>
                            <Col span={12}>
                                <label className="all-label">{i.t('onboarding:actions')}</label>
                                <Space>
                                    <Popconfirm
                                        title={i.t('onboarding:confirm')}
                                        onConfirm={() => __onboarding.setBlock("version", item.version + 1, item.id)}
                                        okText={i.t('onboarding:yes')}
                                        cancelText={i.t('onboarding:no')}
                                    >
                                        <Button
                                            type="primary" shape="round"
                                        >
                                            {i.t('onboarding:update')}
                                        </Button>
                                    </Popconfirm>

                                    <Button
                                        danger type="primary" shape="round"
                                        onClick={() => __onboarding.delete("ksonboarding", item.id)}
                                    >
                                        {i.t('onboarding:remove')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                        {item.slide && Array.isArray(item.slide) &&
                            <>
                                <p className="all-label">{i.t('onboarding:slides')}:</p>
                                <Table columns={columns} dataSource={item.slide[0]} pagination={false} />
                            </>
                        }
                    </Col>
                </Row>
            ))}

            <Modal
                title={i.t('onboarding:edit')}
                centered
                visible={__onboarding.modal}
                footer={null}
                onCancel={() => __onboarding.close()}
                width={1000}
                maskClosable={false}
            >
                <form onSubmit={(e) => __onboarding.save(e)}>
                    {modalConfig.map(item => (
                        <>
                            {item.type !== "dependence" && !item.dependences && <GetConstructorElement item={item}/>}
                            {item.type === "dependence" &&
                                <>
                                    <Col span={24} className="gutter-row">
                                        <label htmlFor={item.name} className="all-label">{item.label}</label>
                                        <Switch
                                            size="small"
                                            checked={__onboarding.element && __onboarding.element[item.name] && __onboarding.element[item.name] > 0 }
                                            onChange={(e) => item.refs(e ? 1 : 0)}
                                        />
                                    </Col>
                                    {__onboarding.element[item.name] > 0
                                        && modalConfig.filter(el => el.dependences && el.dependences === item.name).map(el => <GetConstructorElement item={el} />)}
                                </>
                            }
                        </>
                    ))}

                    <Col span={24} className="gutter-row">
                        <label htmlFor="" className="all-label">{i.t('onboarding:location')}</label>
                        <Select
                            value={__onboarding.element.location}
                            style={{ width: `100%` }}
                            onChange={(e) => __onboarding.set("location", e)}
                        >
                            <Select.Option value="">{i.t('onboarding:locations.one')}</Select.Option>
                            <Select.Option value="user">{i.t('onboarding:locations.two')}</Select.Option>
                            <Select.Option value="map">{i.t('onboarding:locations.three')}</Select.Option>
                            <Select.Option value="list">{i.t('onboarding:locations.four')}</Select.Option>
                            <Select.Option value="profile">{i.t('onboarding:locations.five')}</Select.Option>
                            <Select.Option value="transcript">{i.t('onboarding:locations.six')}</Select.Option>
                        </Select>
                    </Col>

                    <Button htmlType="submit" type="primary">{i.t('onboarding:save')}</Button>
                </form>
            </Modal>
        </>
    )
})

export default OnBoarding