import {makeAutoObservable} from "mobx";
import instance from "../../../store/axios";
import store from "store";
import loaderController from "../../template/loader/loader.controller";

class registrationController {

    constructor() {
        makeAutoObservable(this)
    }

    error = ""

    ref = ""

    data = {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        email: '',
        site: '',
        promo: '',
        company: '',
        inn: '',
        password: '',
        confirmpassword: '',
        error_text: null,
        type: false,
        status: false,
        checked: ""
    }

    onSubmit(e) {
        e.preventDefault()

        loaderController.onChange(true)

        let facts = new FormData();
        facts.append('type', this.data.type ? "1" : "0");
        facts.append('name', this.data.name);
        facts.append('surname', this.data.surname);
        facts.append('patronymic', this.data.patronymic);
        facts.append('phone', this.data.phone);
        facts.append('email', this.data.email);
        facts.append('site', this.data.site);
        facts.append('promo', this.data.promo);
        facts.append('company', this.data.company);
        facts.append('inn', this.data.inn);
        facts.append('password', this.data.password);
        facts.append('confirmpassword', this.data.confirmpassword);
        facts.append('ref', this.ref);
        facts.append('referrer', store.get("referrer") ? store.get("referrer") : "");
        facts.append('utm', store.get("utm") ? store.get("utm") : "");

        instance.post('/user/reg/', facts)
            .then(response => {
                store.set('user', response.data);
                document.location.href="/code";
            })
            .catch(error => {
                loaderController.onChange(false)
                this.error = error.response.data
            })
    }

    setRef(ref) {
        this.ref = ref
    }

    onChangeData(key, value) {
        this.data[key] = value
    }

}

export default new registrationController()