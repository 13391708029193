import {makeAutoObservable} from 'mobx'
import sender from '../../template/controllers/sender';
import _editor from '../../editor/_editor';
import hashController from '../../template/controllers/hash.controller';

class bannersController {

    list = []
    element = {
        id: 0,
        productid: 0,
        photo: {},
        datestart: null,
        dateend: null,
    }

    limit = 10
    offset = 0
    count = 0
    page = 1

    productId = 0

    visible = false

    constructor() {
        makeAutoObservable(this)
    }

    setLimit(limit, list = false) {
        this.limit = limit
        this.offset = 0
        if(!list) this.getList(this.productId)
        else this.getBannersByProductID(this.productId)
    }

    getBannersByProductID(productId) {
        this.productId = productId
        sender(`/post/banerpost?filter[productid]=${productId}`)
            .then(result => {
                console.log(result)
                this.list = result.data.items
                this.count = result.data.pagination[1]
            })
            .catch()
        this.visible = true
    }

    getList() {
        sender(`/post/banerpost?limit=${this.limit}&offset=${this.offset}`)
            .then(result => {
                console.log(result)
                this.list = result.data.items
                this.count = result.data.pagination[1]
            })
            .catch()
    }

    closeList() {
        this.visible = false
        this.list = []
        this.count = 0
        this.offset = 0
    }

    getBanner(id) {
        if(id > 0) {
            sender(`/post/banerpost/${id}`)
                .then(result => {
                    console.log(result)
                    this.element = result.data
                    _editor.setBlocks(result.data.text)
                })
                .catch()
        }
    }

    onSubmit(e) {
        e.preventDefault()

        const data = new FormData()
        data.append('productid', this.element.productid)
        data.append('name', this.element.name)
        data.append('text', JSON.stringify(_editor.blocks))
        data.append('photo', this.element.photo ? JSON.stringify(this.element.photo) : null)
        data.append('datestart', this.element.datestart)
        data.append('dateend', this.element.dateend)
        data.append("hash", hashController.setHash(data))

        sender.post(`/post/banerpost/${this.element.id}`, data)
            .then(success => {
                console.log(success)
                if(this.element.id && this.element.id > 0)
                    this.getBanner(this.element.id)
                else
                    document.location.href="/banners"
            })
            .catch(error => console.log(error))
    }

    delete(id) {
        if(window.confirm(`Вы уверены, что хотите удалить элемент?`)) {
            let facts = {
                typestructure: "ksBanerPost"
            }

            sender.delete(`/product/delete/${id}`, { data: "typestructure=ksBanerPost&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.getList()
                })
                .catch(response => {
                    console.log("Ошибка удаления")
                    console.log(response)
                })
        }
    }

}

export default new bannersController()