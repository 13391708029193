import {makeAutoObservable} from "mobx";

class loaderController {

    constructor() {
        makeAutoObservable(this)
    }

    load = false

    onChange(value) {
        this.load = value
    }
}

export default new loaderController()