import {makeAutoObservable} from "mobx";
import instance from "../../../store/axios";
import {notification} from "antd";
import loaderController from "../../template/loader/loader.controller";
import deliveryController from "../delivery/controllers/interval.controller"

class orderController {
    constructor() {
        makeAutoObservable(this)
    }

    order = false
    orders = []
    offset = 1
    pagination = 0

    statuses = []
    status = {}

    getOrderByID(orderID) {

        deliveryController.getIntervalForOrder()

        instance.get('/shop/order/' + orderID + '/')
            .then(success => {
                console.log(success)
                let priceProducts = 0;
                success.data.data.products.map(item => {
                    priceProducts = priceProducts + (item.price * item.count)
                })
                success.data.data.priceProducts = priceProducts
                let date = new Date(success.data.data.created_at * 1000)
                success.data.data.created_at = date.getDate()+
                    "."+(date.getMonth()+1)+
                    "."+date.getFullYear()+
                    " "+date.getHours()+
                    ":"+date.getMinutes()+
                    ":"+date.getSeconds();

                let dateDelivery = 0
                let dateDate = ""
                let dateTime = ""

                if(success.data.data.datedelivery && success.data.data.datedelivery > 0) {
                    dateDelivery = new Date(success.data.data.datedelivery * 1000)

                    dateDate = dateDelivery.getDate() + "." + (dateDelivery.getMonth()+1) +  "." + dateDelivery.getFullYear()
                    console.log(dateDate)
                    console.log(dateDelivery.getUTCHours() + ":" + dateDelivery.getUTCMinutes())
                    let resp = deliveryController.intervalOrder
                    let allSeconds = resp.timeenddelivery - resp.timestartdelivery
                    let countIntervals = allSeconds / resp.timeinterval

                    let intervals = []
                    let arrr = resp.timestartdelivery - resp.timeinterval

                    for(let i=0;i < countIntervals; i++) {
                        arrr = arrr + resp.timeinterval
                        if(arrr > resp.timeenddelivery) {
                            let old = arrr - resp.timeenddelivery
                            arrr = arrr - old
                        }
                        let result = deliveryController.convertFromSecond(arrr)
                        result = result.split(":")
                        intervals.push([ Number(result[0]), Number(result[1]) ])
                    }

                    console.log(intervals)


                    let itemTrue = "Неопределен"
                    intervals.map(item => {
                        if(dateDelivery.getUTCHours() >= item[0] && dateDelivery.getUTCMinutes() >= item[1]) {
                            itemTrue = item
                        }
                    })

                    if(itemTrue !== "Неопределен") {
                        let itemTrueSeconds = deliveryController.convertToSeconds(itemTrue[0] + ":" + itemTrue[1])

                        itemTrueSeconds = itemTrueSeconds + resp.timeinterval

                        console.log(itemTrueSeconds)

                        if(itemTrueSeconds > resp.timeenddelivery) {
                            let old = itemTrueSeconds - resp.timeenddelivery
                            itemTrueSeconds = itemTrueSeconds - old
                        }


                        if(itemTrueSeconds !== "NaN") {
                            itemTrueSeconds = deliveryController.convertFromSecond(itemTrueSeconds)

                            dateTime = itemTrue[0] + ":" + itemTrue[1] + " - " + itemTrueSeconds
                        } else {
                            dateTime = false
                        }
                    } else {
                        dateTime = false
                    }



                }

                success.data.data.customDateDelivery = {
                    date: dateDate,
                    time: dateTime
                }

                success.data.data.countAll = 0
                success.data.data.products.map(item => success.data.data.countAll = success.data.data.countAll + item.count)

                this.order = success.data.data
            });
    }

    getOrders() {
        instance.get('/shop/orders/?page=' + this.offset)
            .then(response => {
                console.log(response.data.data);
                this.orders = response.data.data
                this.pagination = response.data.pagination
                loaderController.onChange(false)
            })
    }

    setOrder(status) {
        console.log(status)
        this.order.status = status
    }

    setStatusByOrderID() {
        let facts = new FormData();
        facts.append('status', this.order.status);

        instance.post('/shop/order/' + this.order.id + '/', facts)
            .then(response => {
                console.log(response)
            })
    }

    getStatuses() {
        instance.get('/shop/statusorder/')
            .then(response => {
                console.log(response);
                this.statuses = response.data.data[0]
            })
    }

    selectStatus(statusID) {
        this.status = this.statuses.find(el => el.id === statusID);
    }

    onChangeStatus(key, value) {
        console.log(key)
        console.log(value)
        this.status[key] = value
    }

    clearStatus() {
        this.status = {}
    }

    setStatus(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append("name", this.status.name)
        facts.append("colorhex", this.status.colorhex)
        facts.append("icon", this.status.icon)

        let url = "/shop/statusorder/";
        if(this.status.id && this.status.id > 0) {
            url = url + this.status.id
        }

        instance.post(url, facts)
            .then(success => {
                console.log(success)
                this.clearStatus()
                this.getStatuses()
            })
            .catch(error => {
                console.log(error)
            })
    }

    setOffset(e) {
        this.offset = e
        this.getOrders()
    }

    statusCreate() {
        this.clearStatus()
        this.status = {
            id: 0,
            name: "",
            colorhex: "7264D6"
        }
        this.statuses.unshift(this.status)
    }

    deleteStatus(statusID) {
        instance.delete("/shop/statusorder/" + statusID)
            .then(success => {
                console.log(success)
                this.clearStatus()
                this.getStatuses()
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export default new orderController()