import { observer } from "mobx-react-lite";
import loaderController from "../loader/loader.controller";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const ShopSkeletonList = observer(() => {
    return(
        <div className="template_skeleton">
            <div className="for_svg">
                <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
        </div>
    )
})

const TemplateSkeleton = observer(({ type="list" }) => {

    return loaderController.load
        ? type === "list"
            ? <ShopSkeletonList />
            : <></>
        : <></>
})

export default TemplateSkeleton