import {Dropdown, Menu} from "antd";
import "./header.less"
import langController from "./controllers/lang.controller"
import {observer} from "mobx-react-lite";

const Lang = observer(() => {

    const menu = (
        <Menu>
            <Menu.Item key="Ru" onClick={() => langController.onChangeLang("Ru")}>
                Ru
            </Menu.Item>
            <Menu.Item key="En" onClick={() => langController.onChangeLang("En")}>
                En
            </Menu.Item>
            <Menu.Item key="Uk" onClick={() => langController.onChangeLang("Uk")}>
                Uk
            </Menu.Item>
        </Menu>
    );

    return(
        <div className="lang">
            <Dropdown overlay={menu}>
                <a className="" onClick={e => e.preventDefault()}>
                    {langController.language}
                </a>
            </Dropdown>
        </div>
    )
})

export default Lang