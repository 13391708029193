import {useTranslation, withTranslation} from "react-i18next";
import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Space,
    Typography,
    Tabs,
    Switch,
    notification,
    Input,
    Upload,
    Select,
    Popover,
    Modal,
    DatePicker, Tooltip, Radio
} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import store from "store";
import {InboxOutlined, LinkOutlined, DeleteOutlined, FileImageOutlined, UploadOutlined, FontSizeOutlined, PictureOutlined} from "@ant-design/icons";
import {set} from "mobx";
import moment from 'moment';
import instance from "../../store/axios";
import deleteFile from "../../store/deleteFile.controller";
import getLoader from "../../store/uploadFiles.controller";
import {useParams} from "react-router-dom";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

function NewsItem() {
    let { newsID } = useParams();
    const { t } = useTranslation();
    const [update, setUpdate] = useState(0);
    const [push, setPush] = useState(0);
    const [data, setData] = useState({
        active: 1,
        anotation: "",
        text: "",
        type: 0,
        name: "",
        sendpush: 0,
        image: "",
        datefrom: 0,
        dateto: 0,
        typeview: ""
    });

    const updateData = (value) => {
        setData({ ...data, image: value })
    }

    const updateDataEditor = (value) => {
        setImage({ ...image, url: value });
    }

    async function deletePicture(url) {
        await deleteFile("news", url, data.id)
        setData({ ...data, image: "" })
    }

    async function deletePictureEditor(url) {
        await deleteFile("file", url)
        setData({ ...data, image: "" })
    }

    const [image, setImage] = useState({
        display: false,
        url: {},
        id: 0
    });

    const [link, setLink] = useState({
        display: false,
        name: "",
        url: "",
        id: 0
    });

    const [block, setBlock] = useState({
        type: "",
        value: ""
    });

    const [blocks, setBlocks] = useState([]);

    const [types, setTypes] = useState([]);

    const onChangeViewType = (e) => {
        setData({ ...data, typeview: e.target.value })
    }

    useEffect(() => {
        instance.get("/info/newstype")
            .then(success => {
                console.log(success);
                setTypes(success.data.data[0]);
            })
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        if(newsID && newsID !== "new") {
            instance.get("/info/news/" + newsID)
                .then(success => {
                    console.log(success);

                    let from = 0;
                    if(success.data.data.datefrom > 0) {
                        let dateFrom = new Date(success.data.data.datefrom * 1000);
                        from = dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate();
                    }

                    let to = 0;
                    if(success.data.data.dateto > 0) {
                        let dateFrom = new Date(success.data.data.dateto * 1000);
                        to = dateFrom.getFullYear() + "-" + (dateFrom.getMonth() + 1) + "-" + dateFrom.getDate();
                    }

                    setData({
                        ...data,
                        active: success.data.data.active,
                        anotation: success.data.data.anotation,
                        type: Number(success.data.data.type),
                        name: success.data.data.name,
                        sendpush: success.data.data.sendpush,
                        image: success.data.data.image !== "" ? JSON.parse(success.data.data.image) : "",
                        datefrom: from,
                        dateto: to,
                        typeview: success.data.data.typeview

                    });
                    setBlocks(JSON.parse(success.data.data.text));
                    setPush(success.data.data.sendpush);
                });
        }
    }, [update]);

    const switchChecked = (checked) => {
        if(checked) {
            setData({ ...data, active: 1 });
        } else {
            setData({ ...data, active: 0 });
        }
    };

    const switchCheckedPush = (checked) => {
        if(checked) {
            setData({ ...data, sendpush: 1 });
        } else {
            setData({ ...data, sendpush: 0 });
        }
    };

    const sendForm = () => {
        let facts = new FormData();
        facts.append('text', JSON.stringify(blocks));
        facts.append('anotation', data.anotation);
        facts.append('name', data.name);
        facts.append('active', data.active);
        facts.append('type', data.type);
        facts.append('sendpush', data.sendpush);
        facts.append('typeview', data.typeview);

        if(data.image !== "") {
            facts.append('image', JSON.stringify(data.image));
        } else {
            facts.append('image', "");
        }

        let dateFrom = 0;
        if(data.datefrom !== 0) {
            dateFrom = formatDate(data.datefrom);
        }
        let dateTo = 0;
        if(data.dateto !== 0) {
            dateTo = formatDate(data.dateto);
        }

        console.log(dateTo);

        facts.append('datefrom', dateFrom);
        facts.append('dateto', dateTo);

        let url = "/info/news/0";
        if(newsID && newsID !== "new") {
            url = "/info/news/" + newsID;
        }

        instance.post(url, facts).then(response => {
            console.log(response);
            if(response.data.code === 201){
                document.location.href="/news";
            }else if(response.data.code === 200){
                setUpdate(update + 1);
                notification.success({
                    placement: "bottomLeft",
                    message: t('newsItem:success'),
                    description: t('newsItem:editSuccess'),
                });
            }
        })
    }

    const propsUpload = {
        name: 'file',
        action: store.get("project") && store.get("project").baseURL !== ""
            ? store.get("project").baseURL + '/shop/upload/'
            : HOST + '/shop/upload/',
        headers: {
            Authorizations: APIKEY,
            bandl: store.get("user").bandl
        },
        data: {
            path: store.get("user").id + '/news',
        },
        onChange(info) {
            if(info.file.status === "done") {
                console.log(info.file);
                let facts = {
                    width: info.file.response.data.width,
                    height: info.file.response.data.height,
                    path: info.file.response.data.url,
                };
                setData({ ...data, image: facts });

            } else if(info.file.status === "error") {
                notification.warning({
                    placement: "bottomLeft",
                    message: t('newsItem:error'),
                    description: t('newsItem:errorFile'),
                });
            }
        },
    };
    
    const selectBlock = (id) => {
        let element = blocks.find(item => item.id === id);
        console.log(element);
        setBlock({
            id: id,
            type: element.type,
            value: element.value
        });
    };

    const blurInput = () => {
        let data = [];
        let i = 1;
        if(block.id && block.id >= 0) {
            blocks.map(item => {
                if(block.id === i) {
                    data = [...data, {
                        id: i,
                        type: block.type,
                        value: block.value
                    }];
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
        } else {
            blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: block.type,
                value: block.value
            }];
        }
        setBlocks(data);
        setBlock({
            type: "",
            value: ""
        });
    };

    const newBlock = (type, id = 0) => {
        console.log(id);
        let data = [];
        let i = 1;
        let select = 0;
        if(block.id && block.id >= 0) {
            blocks.map(item => {
                if(block.id === i) {
                    data = [...data, {
                        id: i,
                        type: block.type,
                        value: block.value
                    }];
                    if(id > 0) {
                        i = i + 1;
                        data = [...data, {
                            id: i,
                            type: "text",
                            value: ""
                        }];
                        select = i;
                        setBlock({
                            id: i,
                            type: "text",
                            value: ""
                        })
                    }
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
        } else {
            blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            if(block.type !== "") {
                data = [...data, {
                    id: i,
                    type: block.type,
                    value: block.value
                }];
            }
        }
        console.log(data);
        setBlocks(data);


        if(id === 0) {
            if(type === "text") {
                let count = 1;
                if(i > 0) {
                    count = i + 1
                }
                setBlock({
                    type: "text",
                    value: ""
                });
            } else if(type === "image") {
                setBlock({
                    type: "image",
                    value: ""
                });
            } else if(type === "link") {
                setBlock({
                    type: "link",
                    value: ""
                });
            }
        } else {
            setBlock({
                id: select,
                type: "text",
                value: ""
            });
        }
    };

    const onKeyUpInput = (e, id = 0) => {
        if(e.code === "Enter") {
            // setBlock({
            //     type: "",
            //     value: ""
            // });
            //newBlock("text", id);
        }
    };


    const addInput = (id = 0, type="text") => {
        console.log("Добавить инпут " + type);
        let i = 1;
        let data = [];
        let selected = 0;
        blocks.map(item => {
            if(id === i) {
                data = [...data, {
                    id: i,
                    type: item.type,
                    value: item.value
                }];
                i = i + 1;



                if(type === "link") {
                    data = [...data, {
                        id: i,
                        type: type,
                        name: "",
                        value: ""
                    }];
                    selected = i;
                } else {
                    data = [...data, {
                        id: i,
                        type: type,
                        value: ""
                    }];
                }
                setBlock({
                    id: i,
                    type: type,
                    value: ""
                });
                if(selected > 0) {
                    console.log("Выбираем инпут " + i);
                    setLink({
                        id: i,
                        display: true,
                        name: "",
                        url: "",
                    });
                }

            } else {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
            }
            i = i + 1;
        });
        console.log(data);
        setBlocks(data);
    };

    const deleteInput = (id = 0) => {
        let i = 1;
        let data = [];
        blocks.map(item => {
            if(id !== item.id) {
                data = [...data, {
                    id: i,
                    type: item.type,
                    value: item.value
                }];
            } else {
                if(item.type === "image") {
                    deleteFile("file", item.value.path)
                }
            }
            i = i + 1;
        });
        console.log(data);
        setBlocks(data);
    };

    const newImage = (id = 0) => {
        if(id > 0) {
            setImage({ ...image, display: true, id: id });
        } else {
            setImage({ ...image, display: true });
        }
    };

    const newLink = (id = 0) => {
        if(id > 0) {
            setLink({ ...link, display: true, id: id });
        } else {
            setLink({ ...link, display: true });
        }
    };

    const saveImage = () => {
        console.log(image);
        if(image.id > 0) {
            let i = 1;
            let data = [];
            blocks.map(item => {
                if(image.id === i) {
                    data = [...data, {
                        id: i,
                        type: item.type,
                        value: item.value
                    }];
                    i = i + 1;
                    data = [...data, {
                        id: i,
                        type: "image",
                        value: image.url
                    }];
                } else {
                    let obj
                    if(item.type === "link") {
                        obj = {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }
                    } else {
                        obj = {
                            id: i,
                            type: item.type,
                            value: item.value
                        }
                    }
                    data = [...data, obj];
                }
                i = i + 1;
            });
            setBlocks(data);
            closeImage();
        } else {
            let i = 1;
            let data = [];
            blocks.map(item => {
                let obj
                if(item.type === "link") {
                    obj = {
                        id: i,
                        type: item.type,
                        name: item.name,
                        value: item.value
                    }
                } else {
                    obj = {
                        id: i,
                        type: item.type,
                        value: item.value
                    }
                }
                data = [...data, obj];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: "image",
                value: image.url
            }];
            setBlocks(data);
            closeImage();
        }
    };

    const closeImage = () => {
        setImage({
            display: false,
            url: {},
            id: 0
        });
    };

    const saveLink = () => {
        if(link.id > 0) {
            let i = 1;
            let data = [];
            blocks.map(item => {
                if(link.id === i) {
                    data = [...data, {
                        id: i,
                        type: "link",
                        name: link.name,
                        value: link.url
                    }];
                } else {
                    if(item.type === "link") {
                        data = [...data, {
                            id: i,
                            type: item.type,
                            name: item.name,
                            value: item.value
                        }];
                    } else {
                        data = [...data, {
                            id: i,
                            type: item.type,
                            value: item.value
                        }];
                    }

                }
                i = i + 1;
            });
            console.log(data);
            setBlocks(data);
            closeLink();
        } else {
            let i = 1;
            let data = [];
            blocks.map(item => {
                data = [...data, {
                    id: i,
                    type: item.type,
                    name: item.name,
                    value: item.value
                }];
                i = i + 1;
            });
            data = [...data, {
                id: i,
                type: "link",
                name: link.name,
                value: link.url
            }];
            setBlocks(data);
            closeLink();
        }
    };

    const closeLink = () => {
        setLink({
            display: false,
            name: "",
            url: "",
            id: 0
        });
    };

    const formatDate = (date) => {
        let year, month, day;
        [year, month, day]  = date.split("-");
        return Math.round(new Date(year, month-1, day).getTime()/1000);
    };

    const dateSelectFrom = (date, dateString) => {
        setData({
            ...data,
            datefrom: dateString !== "" ? dateString : 0
        });
    };

    const dateSelectTo = (date, dateString) => {
        console.log(dateString);
        setData({
            ...data,
            dateto: dateString !== "" ? dateString : 0
        });
    };

    const selectLink = (id) => {
        console.log("selectLink");
        setLink({
            id: id,
            display: true,
            name: blocks.find(el => el.id === id).name,
            url: blocks.find(el => el.id === id).value,
        });
    };

    const dateFormat = 'YYYY-MM-DD';

    return(
        <>
                <Row justify="space-around" align="middle" className="template-title">
                    <Col span={16}>
                        <Typography.Title>{t('newsItem:edit')}</Typography.Title>
                    </Col>
                    <Col span={8} align="right">
                        <Space>
                            <Button type="primary" onClick={sendForm}>{t('newsItem:save')}</Button>
                            <Button type="dashed" href={"/news"}>{t('newsItem:list')}</Button>
                        </Space>
                    </Col>
                    <Col span={24} className="template-breadcrumb">
                        <Breadcrumb>
                            <Breadcrumb.Item href={"/"}>{t('newsItem:main')}</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/news"}>{t('newsItem:news')}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t('newsItem:edit')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab={t('newsItem:mainTab')} key="main">
                                <Row justify="space-around">
                                    <Col span={24}>
                                        <Switch
                                            onChange={switchChecked}
                                            checked={data.active > 0 ? 'checked' : ''}
                                        />
                                        <label className="label-inline" htmlFor="active">
                                            {t('newsItem:view')}
                                        </label>
                                    </Col>
                                    <Col span={24}>
                                        <label className="all-label">Вид отображения в списке</label>
                                        <Radio.Group onChange={onChangeViewType} value={data.typeview === "" ? "default" : data.typeview}>
                                            <Radio value="default">Текст под изображением</Radio>
                                            <Radio value="full">Текст поверх изображения</Radio>
                                        </Radio.Group>
                                        <br/><br/>
                                    </Col>
                                    <Col span={24}>
                                        {push === 0 &&
                                        <>
                                            <Switch
                                                onChange={switchCheckedPush}
                                                checked={data.sendpush > 0 ? 'checked' : ''}
                                            />
                                            <label className="label-inline" htmlFor="active">
                                                {t('newsItem:push')}
                                            </label>
                                        </>
                                        }
                                        {push > 0 &&
                                        <p>{t('newsItem:notification')}</p>
                                        }
                                    </Col>
                                    <Col span={24}>
                                        <label htmlFor="input1" className="all-label">{t('newsItem:name')}</label>
                                        <Input
                                            name="name"
                                            value={data.name}
                                            onChange={e => setData({ ...data, name: e.target.value }) }
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <label htmlFor="input1" className="all-label">{t('newsItem:type')}</label>
                                        {types.length > 0 &&
                                        <Select defaultValue={types[0].id} value={data.type > 0 ? data.type : null} style={{ width: 100 + "%" }} onChange={(e) => setData({ ...data, type: e })}>
                                            {types.map(item => (
                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                        }
                                    </Col>
                                    <Col span={24}>
                                        <label htmlFor="input1" className="all-label">{t('newsItem:date')}</label>
                                        <Space>
                                            <DatePicker
                                                format={"YYYY-MM-DD"}
                                                onChange={dateSelectFrom}
                                                placeholder={t('newsItem:dateStart')}
                                                value={data.datefrom !== 0 ? moment(data.datefrom, dateFormat) : ""}
                                            />
                                            <DatePicker
                                                format={"YYYY-MM-DD"}
                                                onChange={dateSelectTo}
                                                placeholder={t('newsItem:dateEnd')}
                                                value={data.dateto !== 0 ? moment(data.dateto, dateFormat) : ""}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('newsItem:previewTab')} key="preview">
                                <Row>
                                    <Col span={24}>
                                        {data.image !== "" && data.image !== null && data.image.path &&
                                            <div className="product-item_picture">
                                                <img src={data.image.path} width={200} alt=""/>
                                                {newsID > 0 &&
                                                    <Tooltip title={t('newsItem:delete')}>
                                                        <Button
                                                            danger
                                                            type="dashed"
                                                            className="picture-delete"
                                                            shape="circle"
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => deletePicture(data.image.path)}
                                                        />
                                                    </Tooltip>
                                                }
                                            </div>

                                        }
                                        <label className="all-label">{data.image ? t('category:replacement') : t('category:loading')}</label>
                                        <Upload.Dragger
                                            multiple={false}
                                            fileList={[]}
                                            customRequest={e => getLoader(e, "images", "file", "news", updateData)}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">{t('category:drop')}</p>
                                        </Upload.Dragger>
                                    </Col>
                                    <Col span={24}>
                                        <div className="form-group">
                                            <label className="all-label">{t('newsItem:previewText')}</label>
                                            <Input.TextArea
                                                rows={4}
                                                value={data.anotation}
                                                onChange={e => setData({ ...data, anotation: e.target.value }) }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('newsItem:detailTab')} key="text">
                                <Row>
                                    <Col span={24}>
                                        {blocks.length > 0 && blocks.map(item => (
                                            <>
                                                {block.id && block.id === item.id &&
                                                    <div className="template-news-detail-edited">
                                                        {item.type === "text" &&
                                                            <textarea
                                                                rows={8}
                                                                value={block.value}
                                                                onChange={(e) => setBlock({ ...block, value: e.target.value })}
                                                                onBlur={blurInput}
                                                                onKeyUp={(e) => onKeyUpInput(e, block.id)}
                                                                style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                                                                autoFocus
                                                            />
                                                        }
                                                    </div>
                                                }
                                                {block.id !== item.id &&
                                                    <div className="template-news-buttons">
                                                        {item.type === "text" &&
                                                            <div onClick={() => selectBlock(item.id)}
                                                                 className="template-news-detail-old">
                                                                {item.value}
                                                            </div>
                                                        }
                                                        {item.type === "link" &&
                                                        <>
                                                            <div onClick={() => selectLink(item.id)}
                                                                 className="template-news-detail-old">
                                                                <a href={item.value} target="_blank">{item.name !== "" ? item.name : item.value}</a>
                                                            </div>
                                                        </>
                                                        }
                                                        {item.type === "image" &&
                                                            <div>
                                                                <img src={item.value.path} alt="" className="image-item" width={100 + "%"} />
                                                            </div>
                                                        }
                                                        <Button
                                                            size="small"
                                                            type="dashed"
                                                            shape="circle"
                                                            icon={<DeleteOutlined />}
                                                            danger
                                                            className="delete"
                                                            onClick={() => deleteInput(item.id)}
                                                        />
                                                        <div>
                                                            <Space>
                                                                <Button
                                                                    size="small"
                                                                    type="dashed"
                                                                    shape="circle"
                                                                    icon={<FileImageOutlined />}
                                                                    className="image"
                                                                    onClick={() => newImage(item.id)}
                                                                />
                                                                <Button
                                                                    size="small"
                                                                    type="dashed"
                                                                    shape="circle"
                                                                    icon={<FontSizeOutlined />}
                                                                    className={"plus"}
                                                                    onClick={() => addInput(item.id)}
                                                                />
                                                                <Button
                                                                    size="small"
                                                                    type="dashed"
                                                                    shape="circle"
                                                                    icon={<LinkOutlined />}
                                                                    className={"plus"}
                                                                    onClick={() => addInput(item.id, "link")}
                                                                />
                                                            </Space>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        ))}
                                        {block.type === "text" && !block.id &&
                                            <div className="template-news-detail-edited">
                                                <textarea
                                                    rows={8}
                                                    value={block.value}
                                                    onChange={(e) => setBlock({ ...block, value: e.target.value })}
                                                    onKeyUp={onKeyUpInput}
                                                    onBlur={blurInput}
                                                    style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        }
                                        {block.type === "link" && !block.id &&
                                        <div className="template-news-detail-edited">
                                            <input
                                                type="text"
                                                placeholder="https://"
                                                value={block.value}
                                                onChange={(e) => setBlock({ ...block, value: e.target.value })}
                                                onKeyUp={onKeyUpInput}
                                                onBlur={blurInput}
                                                style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                                                autoFocus={true}
                                            />
                                        </div>
                                        }
                                    </Col>
                                    <Col span={24}>
                                        <Space>
                                            <Button
                                                type="primary"
                                                icon={<FontSizeOutlined />}
                                                onClick={() => newBlock("text")}
                                            >
                                                {t('newsItem:addText')}
                                            </Button>
                                            <Button
                                                type="primary"
                                                icon={<PictureOutlined />}
                                                onClick={newImage}
                                            >
                                                {t('newsItem:addImage')}
                                            </Button>
                                            <Button
                                                type="primary"
                                                icon={<LinkOutlined />}
                                                onClick={newLink}
                                            >
                                                {t('newsItem:addLink')}
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            <Modal
                title={t('newsItem:image')}
                visible={image.display}
                onOk={saveImage}
                onCancel={closeImage}
                centered={true}
            >
                <Upload
                    multiple={false}
                    fileList={[]}
                    customRequest={e => getLoader(e, "images", "file", "news", updateDataEditor)}
                    listType="picture"
                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>{t('import:download')}</Button>
                </Upload>
            </Modal>
            <Modal
                title={t('newsItem:editLink')}
                visible={link.display}
                onOk={saveLink}
                onCancel={closeLink}
                centered={true}
            >
                <Input
                    onChange={(e) => setLink({ ...link, name: e.target.value })}
                    placeholder="Название ссылки"
                    value={link.name}
                />
                <Input
                    onChange={(e) => setLink({ ...link, url: e.target.value })}
                    placeholder="Ссылка"
                    value={link.url}
                />
            </Modal>
        </>
    )
}

export default withTranslation()(NewsItem);