import { makeAutoObservable } from "mobx";
import hashController from "../../../template/controllers/hash.controller"
import sender from "../../../template/controllers/sender";
import translite from "../../../template/controllers/translite.controller"
import loaderController from "../../../template/loader/loader.controller";

class propertiesController {

    constructor() {
        makeAutoObservable(this)
    }

    baseURL = "/product/filtercategory"

    property = {}
    propertyVariations = []
    properties = []

    propStore = []
    propDelete = []

    product = []

    getProperties(id = 0) {
        if(id > 0) {

            sender.get(this.baseURL + "/" + id)
                .then(success => {
                    console.log(this.baseURL)
                    console.log(success.data)
                    this.property = success.data
                    this.getVariations()
                })
                .catch(error => console.log(error.response))
        } else {
            sender.get(this.baseURL)
                .then(success => {
                    console.log(success.data)
                    this.properties = success.data
                })
                .catch(error => {
                    console.log("error")
                    console.log(error)
                })
        }
    }

    getAllProperties() {
        sender.get("/product/filtercategory")
            .then(success => {
                console.log(success.data)
                this.properties = success.data
            })
            .catch(error => {
                console.log("error")
                console.log(error)
            })
    }

    setProperty(name, value) {
        this.property[name] = value
        if(name === "name") {
            this.property.namevalue = translite.translite(value)
        }
    }

    saveProperty() {
        let facts = new FormData()
        facts.append("name", this.property.name)
        facts.append("namevalue", this.property.namevalue)
        facts.append("type", this.property.type)

        let hash = hashController.setHash(facts)
        facts.append("hash", hash)

        sender.post(this.property.id && this.property.id > 0 ? this.baseURL + "/" + this.property.id : this.baseURL, facts)
            .then(success => {
                if(success.data && success.data.id && success.data.id > 0 && this.propertyVariations.length > 0) {
                    this.property = success.data
                    this.saveVariations()
                } else {
                    document.location.href="/shop/properties/" + success.data.id;
                }

            })
            .catch(error => {
                console.log(error.response)
            })
    }

    delete(propertyID) {
        if(window.confirm("Вы уверены, что хотите удалить фильтр?")) {
            let facts = {
                typestructure: "ksFilterCategory"
            }

            sender.delete("/product/delete/" + propertyID, { data: "typestructure=ksFilterCategory&hash=" + hashController.setHashDelete(facts) })
                .then(success => {
                    console.log(success)
                    this.getProperties()
                })
                .catch(error => console.log(error.response))
        }

    }

    addPropertyValue(propertyID) {
        this.propertyVariations.push({
            id: null,
            filtercategoryid: propertyID,
            label: "",
            value: "",
            ids: Date.now()
        })
    }

    setVariant(type, id, value, pos="") {
        let elem
        if(type === "id") {
            this.propertyVariations[this.propertyVariations.findIndex(el => el.id === id)].value = value
            elem = this.propertyVariations[this.propertyVariations.findIndex(el => el.id === id)]
        } else if (type === "ids") {
            this.propertyVariations[this.propertyVariations.findIndex(el => el.ids === id)].value = value
            elem = this.propertyVariations[this.propertyVariations.findIndex(el => el.ids === id)]
        } else {
            this.propertyVariations[this.propertyVariations.findIndex(el => el[pos] === id)].position = value
            elem = this.propertyVariations[this.propertyVariations.findIndex(el => el[pos] === id)]
        }

        if(pos !== "") {
            if(this.propStore.findIndex(el => el[pos] === id) >= 0) {
                this.propStore[this.propStore.findIndex(el => el[pos] === id)] = elem
            } else {
                this.propStore.push(elem)
            }
        } else {
            if(this.propStore.findIndex(el => el[type] === id) >= 0) {
                this.propStore[this.propStore.findIndex(el => el[type] === id)] = elem
            } else {
                this.propStore.push(elem)
            }
        }
    }

    getVariations() {
        sender.get("/product/filtervalue/" + this.property.id)
            .then(success => {
                this.propertyVariations = success.data
            })
            .catch(error => console.log(error))
    }

    saveVariations() {
        loaderController.onChange(true)
        let facts = new FormData()

        console.log(this.propStore)

        let arr = []
        this.propStore.map(item => {

            if(item.value !== "") {

                arr.push(item)
            }
        })

        // let array = []
        // this.propertyVariations.map(item => {
        //     if(item.value !== "") {
        //         array.push(item)
        //     }
        // })

        // facts.append("json", JSON.stringify(array))

        facts.append("store", JSON.stringify(arr))

        if(this.propDelete.length > 0) {
            facts.append("delete", JSON.stringify(this.propDelete))
        }

        let hash = hashController.setHash(facts)
        facts.append("hash", hash)

        sender.post("/product/filtervalues/", facts)
            .then(success => {
                console.log(success)
                loaderController.onChange(false)
                document.location.href="/shop/properties";
            })
            .catch(error => {
                loaderController.onChange(false)
                console.log(error)
            })
    }

    getPropertiesByCategory(categoryID) {
        sender.get("/product/filterbycategory/" + categoryID)
            .then(success => {
                console.log(success.data)
                this.product = success.data
            })
            .catch(error => console.log(error))
    }

    deleteValue(id) {
        this.propertyVariations = this.propertyVariations.filter(el => el.id !== id)
        this.propDelete.push(id)
    }

}

export default new propertiesController()