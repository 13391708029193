import React from 'react';
import store from "store";
import {
    Switch,
    Route
} from "react-router-dom";

import UsersList from "./pages/users/Users";
import UserDetail from "./pages/users/UserDetail";
import PagesList from "./pages/pages/PagesList";
import PageDetail from "./pages/pages/PageDetail";
import Contacts from "./pages/contacts/Contacts";


import Pay from "./pages/auth/Pay";

import axios from "axios";
import SuccessPay from "./pages/auth/SuccessPay";

import NewsList from "./pages/news/NewsList";
import NewsTypeList from "./pages/news/NewsTypeList";
import NewsItem from "./pages/news/NewsItem";


/**
 * Ниже пошли новые модули
 */

import Authorization from "./modules/user/authorization/Authorization";
import UserForgot from "./modules/user/forgot/UserForgot";
import UserRegistration from "./modules/user/registration/UserRegistration";
import UserCode from "./modules/user/code/UserCode";
import UserForm from "./modules/user/form/UserForm";

import UserSetting from "./modules/user/setting/UserSetting";

import Dashboard from "./modules/user/dashboard/Dashboard";

import ShopRoutes from "./modules/shop/shop.routes";
import UserDemo from "./modules/user/demo/UserDemo";
import PostsList from "./modules/shop/posts/modules/PostsList";
import Posts from "./modules/shop/posts/modules/Post";
import EditPost from "./modules/shop/product/modules/product/EditPost";
import OnBoarding from './modules/onboarding/OnBoarding';
import {Complaints, ComplaintsTypes} from './modules/complaints/Complaints';
import {Banner, Banners} from './modules/shop/banners/Banners';
import {PushNotification} from './modules/push/Push';


const nowPath = window.location.pathname;
const url = nowPath.split("/");

if(store.get("user") && store.get("user").token !== "") {
    if(url[1] === "logout") {
        store.clearAll();
        document.location.href = "/signin";
    }
    if(store.get("user").active && Number(store.get("user").active) > 0) {
        if(Number(store.get("user").active) === 3) {
            setInterval(function() {
                axios.get('/users/admin/' + store.get("user").id + '/')
                    .then(response => {
                        if(response.data.code === 200 && response.data.data.bandl !== null){
                            store.set('user', response.data.data);
                            document.location.href = "/";
                        }
                    });
            }, 10000);

            if(url[1] !== "demo") {
                document.location.href = "/demo";
            }
        } else {
            if(url[1] !== 'form' && store.get("user").bandl === null) {
                document.location.href = "/form";
            } else if(url[1] === 'signin' || url[1] === 'signup') {
                document.location.href="/";
            }
        }
    } else if(store.get("user").id && Number(store.get("user").active) === 0 && url[1] !== 'code') {
        document.location.href = "/code";
    }
} else {
    if(url[1] !== 'signin' && url[1] !== 'signup' && url[1] !== 'forgot'){
        document.location.href = "/signin";
    }
}

const isLogin = () => {
    return true
}

const Routes = (
        <Switch>
            <Route exact path="/users" children={<UsersList />} />
            <Route exact path="/users/:userID" children={<UserDetail />} />

            <Route exact path="/pages"><PagesList /></Route>
            <Route exact path="/pages/:pageID" children={<PageDetail />} />

            {/*<Route exact path="/success"><SuccessPay /></Route>*/}
            <Route exact path="/demo"><UserDemo /></Route>
            <Route exact path="/form"><UserForm /></Route>
            <Route exact path="/code"><UserCode /></Route>
            <Route exact path="/signup"><UserRegistration /></Route>
            <Route exact path="/forgot"><UserForgot /></Route>
            <Route exact path="/signin"><Authorization /></Route>
            <Route exact path="/"><Dashboard /></Route>
            <Route exact path="/push"><PushNotification /></Route>

            <Route exact path="/contacts"><Contacts /></Route>

            <Route exact path="/news" children={<NewsList />} />
            <Route exact path="/news/type" children={<NewsTypeList />} />
            <Route exact path="/news/:newsID" children={<NewsItem />} />

            <Route exact path="/user" children={<UserSetting />} />

            <Route exact path="/posts" children={<PostsList />} />
            <Route exact path="/posts/create/:productID" children={<EditPost />} />
            <Route exact path="/posts/:postID" children={<EditPost />} />

            <Route exact path="/banners" children={<Banners />} />
            <Route exact path="/banners/create/:productID" children={<Banner />} />
            <Route exact path="/banners/edit/:bannerID" children={<Banner />} />

            <Route exact path="/onboarding" children={<OnBoarding />} />

            <Route exact path="/complaints" children={<Complaints />} />
            <Route exact path="/complaints_types" children={<ComplaintsTypes />} />


            {/*<Route exact path="/support/chat" children={<SupportChat />} />*/}
            {/*<Route exact path="/shop/properties" children={<ShopProperties />} />*/}
            {/*<Route exact path="/shop/properties/:propertyID" children={<ShopProperty />} />*/}

            <ShopRoutes isEntered={() => isLogin()} />
        </Switch>
)

export default Routes;