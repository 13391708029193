import {Route, Switch} from "react-router-dom";

import ShopProduct from "./product/modules/product/ShopProduct";
import SubProduct from "../../pages/shop/catalog/product/Sub";
import Categories from "../../pages/shop/catalog/category/Categories";
import React from "react";
import ShopProductStatuses from "./product/modules/statuses/ShopProductStatuses";
import SearchProducts from "../../pages/shop/catalog/Search";
import OrderOrders from "./order/orders/OrderOrders";
import OrderStatuses from "./order/status/OrderStatuses";
import OrderOrder from "./order/order/OrderOrder";
import Points from "../../pages/shop/points/Points";
import Point from "../../pages/shop/points/Point";
import Delivery from "../../pages/shop/delivery/Delivery";
import Import from "../../pages/shop/import/Import";
// import ShopCategories from "./catalog/category/ShopCategories";
import ShopProperties from "./properties/ShopProperties";
import ShopProperty from "./properties/modules/ShopProperty";
import ShopCategoryEdited from "./catalog/category/ShopCategoryEdited";
import ShopPromo from "./promo/modules/ShopPromo";
import ShopPromoList from "./promo/modules/ShopPromoList";
import ShopPromoStatistics from "./promo/modules/ShopPromoStatistics";

const shopRoutes = [
    { key: "product", path: "/shop/catalog/product/:productID", exact: true, component: <ShopProduct /> },
    { key: "productcreate", path: "/shop/catalog/product/create/:createID", exact: true, component: <ShopProduct /> },
    { key: "productsub", path: "/shop/catalog/product/:productID/sub/:subID", exact: true, component: <SubProduct /> },

    { key: "shop", path: "/shop", exact: true, component: <Categories /> },
    { key: "catalog", path: "/shop/catalog", exact: true, component: <Categories /> },
    { key: "category", path: "/shop/catalog/category", exact: true, component: <Categories /> },
    { key: "categoryID", path: "/shop/catalog/category/:categoryID", exact: true, component: <Categories /> },
    { key: "edit", path: "/shop/catalog/category/edit/:categoryID", exact: true, component: <ShopCategoryEdited /> },
    { key: "create", path: "/shop/catalog/category/create/:createID", exact: true, component: <ShopCategoryEdited /> },

    { key: "status", path: "/shop/status", exact: true, component: <ShopProductStatuses /> },
    { key: "search", path: "/shop/search*", exact: true, component: <SearchProducts /> },

    { key: "orders", path: "/shop/orders", exact: true, component: <OrderOrders /> },
    { key: "statuses", path: "/shop/statuses", exact: true, component: <OrderStatuses /> },
    { key: "orderID", path: "/shop/orders/:orderID", component: <OrderOrder /> },

    { key: "points", path: "/shop/points", exact: true, component: <Points /> },
    { key: "pointID", path: "/shop/points/:pointID", exact: true, component: <Point /> },

    { key: "delivery", path: "/shop/delivery", exact: true, component: <Delivery /> },
    { key: "import", path: "/shop/import", exact: true, component: <Import /> },

    { key: "properties", path: "/shop/properties", exact: true, component: <ShopProperties /> },
    { key: "propertyID", path: "/shop/properties/:propertyID", exact: true, component: <ShopProperty /> },

    { key: "coupons", path: "/shop/promo", exact: true, component: <ShopPromoList /> },
    { key: "couponID", path: "/shop/promo/:couponID", exact: true, component: <ShopPromo /> },
    { key: "couponID", path: "/shop/promo/:couponID/statistics", exact: true, component: <ShopPromoStatistics /> }
]

const ShopRoutes = () => shopRoutes.map(item => (
        <>
            { item.exact
                ? <Route key={item.key} exact path={item.path} children={item.component} />
                : <Route key={item.key} path={item.path} children={item.component} />
            }
        </>
    ))

export default ShopRoutes