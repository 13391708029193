import {observer} from "mobx-react-lite";
import HeaderDefault from "../../template/header/HeaderDefault";
import forgotController from "./forgot.controller"
import {Button, Col, Row} from "antd";
import { useTranslation } from 'react-i18next';
import React from "react";

const UserForgot = observer(() => {

    const { t } = useTranslation();

    return(
        <>
            <HeaderDefault />

            <Row justify="space-around" align="middle" className="authorization-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                    <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                        {forgotController.error !== "" &&
                        <p className="message-error">{forgotController.error}</p>
                        }
                        <div className={forgotController.error !== "" ? "authorization-figure-one-error" : "authorization-figure-one"}>

                            <svg width="470" height="398" viewBox="0 0 470 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M79.7667 128.493L261.49 342.988C278.895 365.211 312.64 366.804 334.686 348.745C336.436 347.311 337.641 346.08 337.641 346.08L430.651 252.021C450.616 231.132 451.584 198.804 431.886 177.363C428.096 173.535 427.663 172.9 419.743 167.424L161.272 26.4607C139.232 13.4979 110.962 20.5788 94.8108 41.4462L79.432 60.7702C63.2806 81.6377 64.6312 109.336 79.7667 128.493Z" fill="#D6F1F9" />
                            </svg>
                        </div>
                    </Col>

                    <div className="authorization">
                        <h1>{t('forgot:forgotPassword')}</h1>
                        {forgotController.status !== 3 &&
                            <>
                                {forgotController.status === 1 &&
                                <h5>{t('forgot:codeSend')}</h5>
                                }
                                {forgotController.status === 0 &&
                                <h5>{t('forgot:email')}</h5>
                                }
                                {forgotController.status === 2 &&
                                <h5>{t('forgot:newPassword')}</h5>
                                }

                                <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                                    {forgotController.error !== "" &&
                                    <p>{forgotController.error}</p>
                                    }
                                </Col>

                                <div className="template-form">
                                    <form onSubmit={e => forgotController.onSubmit(e)}>
                                        {forgotController.status < 2 &&
                                        <>
                                            <input
                                                type="email"
                                                placeholder="email@domain.com"
                                                onChange={e => forgotController.onChangeData("login", e.target.value)}
                                                value={forgotController.data.login}
                                                required
                                                autoFocus
                                            />
                                            <input
                                                type="text"
                                                placeholder="Код подтверждения"
                                                onChange={e => forgotController.onChangeData("code", e.target.value)}
                                                disabled={forgotController.status === 1 ? '' : 'disabled'}
                                                value={forgotController.data.code}
                                                required
                                            />
                                        </>
                                        }
                                        {forgotController.status === 2 &&
                                        <>
                                            <input
                                                type="password"
                                                placeholder={t('forgot:password')}
                                                onChange={e => forgotController.onChangeData("password", e.target.value)}
                                                value={forgotController.data.password}
                                                required
                                                autoFocus
                                            />
                                            <input
                                                type="password"
                                                placeholder={t('forgot:confirmPassword')}
                                                onChange={e => forgotController.onChangeData("confirmPassword", e.target.value)}
                                                value={forgotController.data.confirmPassword}
                                                required
                                            />
                                        </>
                                        }

                                        <Row gutter={[10, 10]}>
                                            <Col span={24}>
                                                <Button type="primary" block htmlType="submit">{t('forgot:continue')}</Button>
                                            </Col>
                                            <Col span={24}>
                                                <a href="/signin" className="authorization-forgot">Назад</a>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </>
                        }
                        {forgotController.status === 3 &&
                        <>
                            <h5>{t('forgot:passwordSuccess')}</h5>
                            <a href="/signin" className="authorization-forgot">{t('forgot:signIn')}</a>
                        </>
                        }


                    </div>
                </Col>
            </Row>
        </>
    )
})

export default UserForgot