import { observer } from "mobx-react-lite";
import pc from "./controllers/properties.controller"
import { useTranslation } from "react-i18next";
import TemplateTitle from "../../template/title/TemplateTitle";
import React, {useEffect} from "react";
import ButtonAction from "../../template/buttons/ButtonsActions"
import {Button, Space, Table, Tag, Tooltip} from "antd";
import {SettingOutlined} from "@ant-design/icons";

const ShopProperties = observer(() => {

    const { t } = useTranslation();

    useEffect(() => {
        pc.getAllProperties()
    }, [])

    const breadcrumb = [
        { key: "shop", link: "/shop", value: t('orders:shop') },
        { key: "element", link: null, value: "Характеристики товаров" }
    ]

    const buttons = [
        { title: "Создать", type: "link", link: "/shop/properties/0" }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Наименование",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Тип",
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: t('orders:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <ButtonAction
                            item={{
                                type: "edit",
                                typeButton: "link",
                                placeholder: "Редактировать",
                                url: "/shop/properties/" + record.id
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "delete",
                                typeButton: "button",
                                placeholder: "Удалить",
                                onClick: () => pc.delete(record.id)
                            }}
                        />
                    </Space>
                </>
            ),
        },
    ];


    return(
        <>
            <TemplateTitle
                title="Характеристики товаров"
                breadcrumb={breadcrumb}
                buttons={buttons}
            />
            <Table columns={columns} dataSource={pc.properties} pagination={false} />
        </>
    )
})

export default ShopProperties