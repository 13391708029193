import {observer} from "mobx-react-lite";
import {Breadcrumb, Button, Col, Input, Space, Row, Typography} from "antd";
import React, {useEffect} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import pointsStore from "../../../store/shop/pointsStore";
import { DeleteOutlined } from '@ant-design/icons';
import {useParams} from "react-router-dom";

const Point = observer(() => {
    const { t } = useTranslation();
    let { pointID } = useParams();
    useEffect(() => {
        if(pointID && pointID > 0) {
            pointsStore.setPointByID(pointID)
        }
    }, []);

    const listPhones = () => {

    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Typography.Title>Редактирование филиала</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => pointsStore.savePoint(t('points:success'))}
                        >
                            Сохранить
                        </Button>
                        <Button href={"/shop/points"}>Вернуться к списку</Button>
                    </Space>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('points:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('points:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop/points"}>{t('points:points')}</Breadcrumb.Item>
                        <Breadcrumb.Item>Редактирование филиала</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row gutter={[40, 50]}>
                <Col span={6}>
                    <label className="all-label">{t('points:name')}</label>
                    <Input
                        value={pointsStore.point.name}
                        onChange={(e) => pointsStore.onChange("name", e.target.value)}
                        required
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">{t('points:address')}</label>
                    <Input
                        value={pointsStore.point.address}
                        onChange={(e) => pointsStore.onChange("address", e.target.value)}
                        required
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">{t('points:latitude')}</label>
                    <Input
                        value={pointsStore.point.latitude}
                        onChange={(e) => pointsStore.onChange("latitude", e.target.value)}
                        required
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">{t('points:longitude')}</label>
                    <Input
                        value={pointsStore.point.longitude}
                        onChange={(e) => pointsStore.onChange("longitude", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <Typography.Title level={5}>{t('points:weekdays')}</Typography.Title>
                    <label className="all-label">{t('points:start')}</label>
                    <Input
                        value={pointsStore.point.date1start}
                        onChange={(e) => pointsStore.onChange("date1start", e.target.value)}
                        required
                    />

                    <label className="all-label">{t('points:end')}</label>
                    <Input
                        value={pointsStore.point.date1end}
                        onChange={(e) => pointsStore.onChange("date1end", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <Typography.Title level={5}>{t('points:saturday')}</Typography.Title>
                    <label className="all-label">{t('points:start')}</label>
                    <Input
                        value={pointsStore.point.date2start}
                        onChange={(e) => pointsStore.onChange("date2start", e.target.value)}
                        required
                    />

                    <label className="all-label">{t('points:end')}</label>
                    <Input
                        value={pointsStore.point.date2end}
                        onChange={(e) => pointsStore.onChange("date2end", e.target.value)}
                        required
                    />
                </Col>
                <Col span={8}>
                    <Typography.Title level={5}>{t('points:sunday')}</Typography.Title>
                    <label className="all-label">{t('points:start')}</label>
                    <Input
                        value={pointsStore.point.date3start}
                        onChange={(e) => pointsStore.onChange("date3start", e.target.value)}
                        required
                    />

                    <label className="all-label">{t('points:end')}</label>
                    <Input
                        value={pointsStore.point.date3end}
                        onChange={(e) => pointsStore.onChange("date3end", e.target.value)}
                        required
                    />
                </Col>
                <Col span={24}>
                    <Typography.Title level={5}>Контактные телефоны</Typography.Title>
                    {pointsStore.phonesList.length > 0 && pointsStore.phonesList.map(item =>
                        <div>
                            <Space>
                                <Input
                                    onChange={(e) => pointsStore.onChangePhone("phone", e.target.value, item.id)}
                                    value={item.phone}
                                    onFocus={() => pointsStore.selectPhone(item.id)}
                                    placeholder="Номер телефона"
                                />
                                <Input
                                    onChange={(e) => pointsStore.onChangePhone("description", e.target.value, item.id)}
                                    value={item.description}
                                    onFocus={() => pointsStore.selectPhone(item.id)}
                                    placeholder="Описание (Необязательно)"
                                />
                                <Button danger shape="circle" onClick={() => pointsStore.deletePhone(item.id)} icon={<DeleteOutlined />} />
                            </Space>
                            <br/><br/>
                        </div>
                    )}

                    <div>
                        <Space>
                            <Input
                                onChange={(e) => pointsStore.onChangePhone("phone", e.target.value)}
                                value={pointsStore.pointNewPhone.phone}
                                onFocus={() => pointsStore.selectPhone()}
                                onBlur={() => pointsStore.addPhone()}
                                placeholder="Номер телефона"
                            />
                            <Input
                                onChange={(e) => pointsStore.onChangePhone("description", e.target.value)}
                                value={pointsStore.pointNewPhone.description}
                                onFocus={() => pointsStore.selectPhone()}
                                onBlur={() => pointsStore.addPhone()}
                                placeholder="Описание (Необязательно)"
                            />
                        </Space>
                    </div>
                </Col>
            </Row>
        </>
    )
});

export default withTranslation()(Point);