import HeaderAuth from "../../components/HeaderAuth";
import React from "react";
import {Col, Row} from "antd";
import {withTranslation} from "react-i18next";

function SuccessPay({ t }) {
    return(
        <>
            <HeaderAuth logout={true} />
            <Row justify="space-around" align="middle" className="form">
                <Col span={20} className="center">
                    <h1>{t('successPay:success')}</h1>
                    <h4>{t('successPay:text')}</h4>
                </Col>
            </Row>
        </>
    )
}

export default withTranslation()(SuccessPay);