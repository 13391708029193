import {observer} from "mobx-react-lite";
import {Breadcrumb, Col, Row, Button} from "antd";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import orderController from "../order.controller"
import {ColorPicker} from 'primereact/colorpicker';
import { SettingOutlined } from '@ant-design/icons';

const IconWaiting = ({ color }) => {
    return(
        <svg width="9px" height="8px" viewBox="0 0 9 8">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="waiting" transform="translate(2.000000, 0.000000)" stroke={"#" + color } strokeWidth="2.286">
                    <polyline id="Path" points="0 0.401 0 6 6.941 6" />
                </g>
            </g>
        </svg>
    )
}
const IconWaitingOrder = ({ color }) => {
    return(
        <svg width="16px" height="17px" viewBox="0 0 16 17">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="waiting_order" fill={"#" + color} fillRule="nonzero">
                    <path d="M13.714,0 C14.977,0 16,1.023 16,2.286 L16,16 L12.989,13 L10.059,16.024 L7.476,13 L5.039,16.024 L2.598,13 L0,16 L0,2.286 C0,1.023 1.023,0 2.286,0 L13.714,0 Z M4.691,3.073 C4.235,2.867 3.687,3.03 3.423,3.47 L3.368,3.573 C3.162,4.029 3.325,4.578 3.765,4.842 L7.142,6.872 L5.804,9.1 L5.749,9.203 C5.543,9.659 5.706,10.208 6.146,10.472 C6.619,10.756 7.234,10.603 7.518,10.13 L9.372,7.044 L9.426,6.941 C9.633,6.485 9.469,5.936 9.03,5.672 L4.795,3.128 L4.691,3.073 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconReadyToShip2 = ({ color }) => {
    return(
        <svg width="20px" height="16px" viewBox="0 0 20 16">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ready_to_ship2" fill={"#" + color} fillRule="nonzero">
                    <path d="M14.525,0.5 L14.525,4.131 L17.248,4.131 L19.972,7.762 L19.972,12.302 L18.156,12.302 C18.156,13.804 16.936,15.025 15.433,15.025 C13.93,15.025 12.709,13.804 12.709,12.301 L7.262,12.301 C7.262,13.804 6.042,15.025 4.539,15.025 C3.037,15.025 1.816,13.804 1.816,12.301 L0,12.301 L0,2.316 C0,1.312 0.812,0.5 1.816,0.5 L14.525,0.5 Z M4.539,10.94 C3.786,10.94 3.177,11.548 3.177,12.301 C3.177,13.055 3.786,13.663 4.539,13.663 C5.292,13.663 5.901,13.055 5.901,12.301 C5.901,11.548 5.293,10.94 4.539,10.94 Z M15.433,10.94 C14.679,10.94 14.071,11.548 14.071,12.301 C14.071,13.055 14.679,13.663 15.433,13.663 C16.186,13.663 16.794,13.055 16.794,12.301 C16.794,11.548 16.186,10.94 15.433,10.94 Z M12.177,2.698 C11.747,2.268 11.047,2.268 10.617,2.698 L6.03,7.286 L4.383,5.639 C3.953,5.209 3.253,5.209 2.823,5.639 C2.393,6.069 2.393,6.769 2.823,7.199 L5.25,9.625 C5.68,10.056 6.379,10.056 6.81,9.625 L12.177,4.258 C12.607,3.828 12.607,3.128 12.177,2.698 Z M16.794,5.493 L14.524,5.493 L14.524,7.763 L18.578,7.763 L16.794,5.493 L16.794,5.493 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconReadyToShip1 = ({ color }) => {
    return(
        <svg width="20px" height="16px" viewBox="0 0 20 16">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ready_to_ship1" fill={"#" + color} fillRule="nonzero">
                    <path d="M17.248,4.131 L14.525,4.131 L14.525,0.5 L1.815,0.5 C0.813,0.5 0,1.312 0,2.316 L0,12.3 L1.816,12.3 C1.816,13.803 3.036,15.024 4.539,15.024 C6.041,15.024 7.262,13.803 7.262,12.3 L12.709,12.3 C12.709,13.803 13.93,15.024 15.433,15.024 C16.935,15.024 18.156,13.803 18.156,12.3 L19.972,12.3 L19.972,7.762 L17.248,4.132 L17.248,4.131 Z M4.54,13.663 C3.787,13.663 3.178,13.055 3.178,12.301 C3.178,11.548 3.787,10.94 4.54,10.94 C5.294,10.94 5.902,11.548 5.902,12.301 C5.902,13.055 5.293,13.663 4.54,13.663 Z M15.434,13.663 C14.68,13.663 14.072,13.055 14.072,12.301 C14.072,11.548 14.68,10.94 15.434,10.94 C16.187,10.94 16.795,11.548 16.795,12.301 C16.795,13.055 16.187,13.663 15.434,13.663 Z M14.526,7.763 L14.526,5.493 L16.796,5.493 L18.579,7.763 L14.526,7.763 L14.526,7.763 Z" id="Shape" />
                </g>
            </g>
        </svg>
    )
}
const IconReadyToShip = ({ color }) => {
    return(
        <svg width="20px" height="20px" viewBox="0 0 20 20">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ready_to_ship" fill={"#" + color} fillRule="nonzero">
                    <path d="M10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 C0,4.477 4.477,0 10,0 Z M13.857,8.75 L6.143,8.75 C5.512,8.75 5,9.262 5,9.893 L5,10.107 C5,10.738 5.512,11.25 6.143,11.25 L13.857,11.25 C14.488,11.25 15,10.738 15,10.107 L15,9.893 C15,9.262 14.488,8.75 13.857,8.75 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconBack = ({ color }) => {
    return(
        <svg width="11px" height="8px" viewBox="0 0 11 8">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="back" transform="translate(2.000000, 1.000000)" stroke={"#" + color} strokeWidth="2.286">
                    <polyline id="Path" transform="translate(5.399692, 3.000000) rotate(-180.000000) translate(-5.399692, -3.000000) " points="1.999692 3 4.032692 3 8.799692 3" />
                    <polyline id="Path" points="3.102 0.055 0.102 3.055 3.102 6.055" />
                </g>
            </g>
        </svg>
    )
}
const IconCheck = ({ color }) => {
    return(
        <svg width="10px" height="8px" viewBox="0 0 10 8">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="check" fill={"#" + color} fillRule="nonzero">
                    <path d="M8.494,0.758 C8.838,0.414 9.397,0.414 9.742,0.758 C10.086,1.103 10.086,1.662 9.742,2.006 L4.447,7.3 C4.103,7.645 3.544,7.645 3.2,7.3 L0.258,4.36 C-0.086,4.015 -0.086,3.456 0.258,3.111 C0.603,2.767 1.162,2.767 1.506,3.111 L3.824,5.43 L8.494,0.76 L8.494,0.758 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconCloses = ({ color }) => {
    return(
        <svg width="10px" height="10px" viewBox="0 0 10 10">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="closes" stroke={"#" + color} strokeWidth="2.286">
                    <polyline id="Path" transform="translate(4.961109, 5.168216) rotate(45.000000) translate(-4.961109, -5.168216) " points="-0.483891047 5.16821626 2.77110895 5.16821626 10.406109 5.16821626" />
                    <polyline id="Path" transform="translate(4.960888, 5.168216) rotate(135.000000) translate(-4.960888, -5.168216) " points="-0.439111664 5.16821626 2.78888834 5.16821626 10.3608883 5.16821626" />
                </g>
            </g>
        </svg>
    )
}
const IconCatfishPickup = ({ color }) => {
    return(
        <svg width="20px" height="19px" viewBox="0 0 20 19">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="catfish_pickup" fill={"#" + color} fillRule="nonzero">
                    <path d="M8.75,0.250375 L11.25,0.250375 L11.25,5.250375 L8.75,5.250375 L8.75,0.250375 Z M8.75,5.250375 L11.25,5.250375 L11.25,6.500375 L8.75,6.500375 L8.75,5.250375 Z" />
                    <path d="M1.25,5.309375 L1.25,11.028375 C1.663,11.106375 1.87,11.305375 2.174,11.636375 L4.374,14.022375 C4.379,14.029375 4.384,14.034375 4.391,14.040375 L4.391,13.555375 C4.391,12.533375 5.221,11.702375 6.243,11.702375 L9.322,11.702375 C9.704,11.746375 10.717,11.166375 11.066,11.028375 C12.548,10.338375 14.234,10.366375 15.693,11.102375 L16.581,11.553375 C16.829,10.755375 17.543,10.177375 18.382,10.177375 L18.75,10.177375 L18.75,5.299375 L12.495,5.299375 L12.495,7.109375 C12.495,7.476375 12.197,7.775375 11.829,7.775375 L8.16,7.775375 C7.792,7.775375 7.494,7.476375 7.494,7.109375 L7.494,5.299375 L1.25,5.309375 Z" />
                    <path d="M12.5,6.499375 L18.75,6.499375 L18.75,2.536375 C18.748,1.273375 17.724,0.250375 16.462,0.250375 L12.5,0.250375 L12.5,6.500375 L12.5,6.499375 Z M19.314,11.509375 C19.003,11.509375 18.75,11.807375 18.75,12.174375 L18.75,18.127375 C18.75,18.494375 19.003,18.792375 19.314,18.792375 L19.434,18.792375 C19.744,18.792375 19.998,18.494375 19.998,18.127375 L19.998,12.174375 C19.998,11.807375 19.745,11.509375 19.434,11.509375 L19.314,11.509375 L19.314,11.509375 Z M7.5,0.249375 L3.536,0.249375 C2.273,0.249375 1.25,1.274375 1.25,2.536375 L1.25,6.501375 L7.5,6.501375 L7.5,0.249375 Z M1.195,12.539375 C0.606,11.949375 -0.395,12.719375 0.165,13.403375 L3.165,17.040375 C4.182,18.274375 5.685,18.982375 7.285,18.982375 L10.678,18.982375 C11.938,18.982375 13.198,18.834375 14.423,18.542375 L17.507,17.987375 L17.507,12.937375 L15.092,12.291375 C14.001,11.740375 12.737,11.719375 11.627,12.235375 L10.441,12.787375 C10.093,12.949375 9.706,13.034375 9.322,13.034375 L6.242,13.034375 C5.956,13.034375 5.722,13.268375 5.722,13.554375 L5.722,14.564375 L11.704,14.564375 C12.071,14.564375 12.369,14.862375 12.369,15.230375 C12.369,15.597375 12.071,15.895375 11.704,15.895375 L5.607,15.895375 C4.835,15.905375 3.947,15.543375 3.394,14.925375 L1.194,12.538375 L1.195,12.539375 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconDelivery = ({ color }) => {
    return(
        <svg width="13px" height="20px" viewBox="0 0 13 20">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="delivery" fill={"#" + color} fillRule="nonzero">
                    <path d="M9.87,9.912 C9.842,9.557 9.53,9.262 9.173,9.257 L6.813,9.221 C6.676,9.219 6.537,9.099 6.516,8.963 L5.996,5.658 C5.976,5.538 5.866,5.05 5.256,5.05 L2.321,5.178 C1.935,5.194 1.621,5.382 1.621,5.91 C1.621,5.91 1.608,13.257 1.593,13.523 L1.491,15.34 C1.477,15.583 1.382,15.976 1.284,16.198 L0.096,18.887 C0.018,19.064 0.019,19.26 0.1,19.425 C0.18,19.589 0.335,19.71 0.523,19.757 L0.918,19.855 C0.978,19.87 1.038,19.877 1.101,19.877 C1.421,19.877 1.726,19.692 1.861,19.417 L2.991,17.115 C3.115,16.861 3.271,16.43 3.336,16.155 L3.973,13.501 L5.515,15.859 C5.642,16.053 5.777,16.426 5.803,16.657 L6.101,19.297 C6.143,19.672 6.485,19.978 6.864,19.978 L7.314,19.976 C7.508,19.976 7.684,19.899 7.81,19.759 C7.936,19.619 7.995,19.437 7.976,19.244 L7.664,16.106 C7.636,15.816 7.532,15.376 7.428,15.106 L6.046,11.496 L6.038,10.736 L9.248,10.617 C9.428,10.611 9.591,10.534 9.709,10.401 C9.828,10.269 9.885,10.097 9.871,9.918 L9.871,9.912 L9.87,9.912 Z M3.26,4.352 C3.506,4.352 3.75,4.31 3.985,4.227 C4.533,4.033 4.973,3.637 5.223,3.113 C5.4,2.743 5.467,2.338 5.423,1.94 L6.152,1.857 C6.406,1.829 6.664,1.627 6.752,1.386 L6.996,0.719 C7.048,0.577 7.034,0.432 6.958,0.323 C6.884,0.217 6.758,0.156 6.615,0.156 L6.563,0.16 L4.455,0.36 C4.107,0.13 3.694,0 3.259,0 C3.012,0 2.769,0.042 2.533,0.125 C1.986,0.319 1.546,0.715 1.296,1.239 C1.046,1.764 1.014,2.354 1.208,2.902 C1.516,3.77 2.34,4.352 3.259,4.352 L3.26,4.352 Z" />
                    <path d="M12.471,7.758 L12.261,4.742 C12.237,4.384 11.924,4.093 11.564,4.093 L7.534,4.1 C7.354,4.1 7.187,4.172 7.066,4.302 C6.946,4.432 6.886,4.602 6.899,4.782 L7.109,7.797 C7.133,8.155 7.446,8.447 7.805,8.447 L11.836,8.439 C12.016,8.439 12.183,8.368 12.304,8.238 C12.424,8.108 12.484,7.938 12.471,7.758 L12.471,7.758 Z" />
                </g>
            </g>
        </svg>
    )
}
const IconDone = ({ color }) => {
    return(
        <svg width="18px" height="19px" viewBox="0 0 18 19">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="done_1">
                    <rect id="Rectangle" fill={"#" + color} fillRule="nonzero" x="0.25" y="1.25" width="17.5" height="17.5" rx="2.286" />
                    <path d="M7.071,0.571 L7.071,6.357 C7.071,6.673 7.327,6.929 7.643,6.929 L10.357,6.929 C10.673,6.929 10.929,6.673 10.929,6.357 L10.929,0.571 L7.07,0.571 L7.071,0.571 Z" id="Path" stroke="#FFFFFF" strokeWidth="1.143" fill={"#" + color} fillRule="nonzero" />
                    <polyline id="Path" stroke="#FFFFFF" strokeWidth="2.286" strokeLinecap="round" strokeLinejoin="round" points="3.957 11.416 7.75 14.901 13.976 8.691" />
                </g>
            </g>
        </svg>
    )
}
const IconNew = ({ color }) => {
    return(
        <svg width="18px" height="18px" viewBox="0 0 18 18">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="new" fill={"#" + color} fillRule="nonzero">
                    <polygon id="Path" points="9 14.611 5.652 17.084 5.032 12.968 0.916 12.348 3.389 9 0.916 5.652 5.032 5.032 5.652 0.916 9 3.389 12.348 0.916 12.968 5.032 17.084 5.652 14.611 9 17.084 12.348 12.968 12.968 12.348 17.084" />
                </g>
            </g>
        </svg>
    )
}
const IconPacket = ({ color }) => {
    return(
        <svg width="20px" height="15px" viewBox="0 0 20 15">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="packet" fill={"#" + color} fillRule="nonzero">
                    <path d="M3.405,2.695 L10,4.881 L16.595,2.695 L10,0.509 L3.405,2.695 Z M18.206,3.396 L10.894,5.819 L12.685,8.664 L19.997,6.24 L18.206,3.396 Z M9.414,7.529 L7.817,10.065 L2.128,8.179 L2.128,12.085 L9.414,14.5 L9.414,7.529 Z" />
                    <path d="M12.183,10.065 L10.586,7.529 L10.586,14.5 L17.872,12.085 L17.872,8.179 L12.183,10.065 Z M7.315,8.664 L9.106,5.819 L1.794,3.396 L0.003,6.24 L7.315,8.664 Z" />
                </g>
            </g>
        </svg>
    )
}

const Status = observer(({ status }) => {

    return(
        <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
        >
            <form onSubmit={e => orderController.setStatus(e)}>
                <Row className="order-status">
                    {status.id === orderController.status.id
                        ? <></>
                        : <>
                            <div className="for"><Button onClick={() => orderController.selectStatus(status.id)} shape="circle" type="dashed" icon={<SettingOutlined />} /></div>
                            <div className="icon">
                                {status.icon && status.icon !== "" &&
                                    <div className="tops">
                                        {status.icon === "ic_order_status_waiting" && <IconWaiting color={status.colorhex} />}
                                        {status.icon === "ic_order_status_waiting_order" && <IconWaitingOrder color={status.colorhex} />}
                                        {status.icon === "ic_order_status_ready_to_ship2" && <IconReadyToShip2 color={status.colorhex} />}
                                        {status.icon === "ic_order_status_ready_to_ship1" && <IconReadyToShip1 color={status.colorhex} />}
                                        {status.icon === "ic_order_status_ready_to_ship" && <IconReadyToShip color={status.colorhex} />}
                                        {status.icon === "ic_order_status_back" && <IconBack color={status.colorhex} />}
                                        {status.icon === "ic_order_status_check" && <IconCheck color={status.colorhex} />}
                                        {status.icon === "ic_order_status_closes" && <IconCloses color={status.colorhex} />}
                                        {status.icon === "ic_order_status_pickup" && <IconCatfishPickup color={status.colorhex} />}
                                        {status.icon === "ic_order_status_delivery" && <IconDelivery color={status.colorhex} />}
                                        {status.icon === "ic_order_status_done" && <IconDone color={status.colorhex} />}
                                        {status.icon === "ic_order_status_new" && <IconNew color={status.colorhex} />}
                                        {status.icon === "ic_order_status_packet" && <IconPacket color={status.colorhex} />}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    <Col span={24}>
                        <p className="order_id">#{ status.id }</p>
                    </Col>
                    {status.id === orderController.status.id ?
                        <>
                            <span className="description">Название статуса</span>
                            <input
                                type="text"
                                className="status_input"
                                value={orderController.status.name}
                                onChange={e => orderController.onChangeStatus("name", e.target.value)}
                                placeholder="Наименование статуса"
                                required
                            />

                            <Row>
                                <Col span={24}>
                                    <span className="description">Выберите цвет статуса</span>
                                </Col>
                                <Col>
                                    <input
                                        type="text"
                                        className="status_input"
                                        id={"status_input" + status.id}
                                        value={status.colorhex}
                                        style={{ colorText: status.colorhex }}
                                        onChange={(e) => orderController.onChangeStatus("colorhex", e.target.value)}
                                    />
                                    <ColorPicker
                                        format={"hex"}
                                        value={status.colorhex}
                                        onChange={(e) => orderController.onChangeStatus("colorhex", e.value)}
                                        inputId={"status_input" + status.id}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[15, 15]}>
                                <Col span={24}>
                                    <span className="description">Выберите иконку статуса</span>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_waiting" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_waiting")}
                                    >
                                        <IconWaiting color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_waiting_order" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_waiting_order")}
                                    >
                                        <IconWaitingOrder color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_ready_to_ship2" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_ready_to_ship2")}
                                    >
                                        <IconReadyToShip2 color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_ready_to_ship1" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_ready_to_ship1")}
                                    >
                                        <IconReadyToShip1 color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_ready_to_ship" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_ready_to_ship")}
                                    >
                                        <IconReadyToShip color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_back" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_back")}
                                    >
                                        <IconBack color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_check" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_check")}
                                    >
                                        <IconCheck color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_closes" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_closes")}
                                    >
                                        <IconCloses color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_pickup" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_pickup")}
                                    >
                                        <IconCatfishPickup color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_delivery" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_delivery")}
                                    >
                                        <IconDelivery color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_done" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_done")}
                                    >
                                        <IconDone color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_new" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_new")}
                                    >
                                        <IconNew color={status.colorhex} />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={status.icon === "ic_order_status_packet" ? "icon_bg selected" : "icon_bg"}
                                        onClick={() => orderController.onChangeStatus("icon", "ic_order_status_packet")}
                                    >
                                        <IconPacket color={status.colorhex} />
                                    </div>
                                </Col>
                            </Row>

                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    <Col span={24}><Button htmlType="submit" block type="primary">Сохранить</Button></Col>
                                    <Col span={24}>
                                        {status.id >= 1 && status.id <= 3
                                            ?   <></>
                                            :   <><Button block type="primary" danger onClick={() => orderController.deleteStatus(status.id)}>Удалить</Button></>
                                        }
                                    </Col>
                                </Row>
                            </Col>

                        </>
                        :
                            <>
                                <Col span={24}>

                                    <p className="order_name" style={{ color: "#" + status.colorhex }}>{ status.name }</p>
                                </Col>
                            </>
                    }
                </Row>
            </form>
        </Col>
    )
})

const OrderStatuses = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        orderController.getStatuses()
    }, [])

    return(
        <>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('orders:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('orders:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('orders:orders')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18 }}>
                    <h1>Статусы заказов</h1>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 6 }} className="right">
                    <Button type="primary" onClick={() => orderController.statusCreate()}>Создать статус</Button>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                {orderController.statuses.map(item => <Status status={item} />)}
            </Row>
        </>
    )
})

export default OrderStatuses