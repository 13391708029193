import { observer } from "mobx-react-lite";
import _post from "../controllers/_post";
import {Button, Drawer, Image, Space, Table, Tooltip} from "antd";
import React from "react";
import {newDateTimeHelper} from "../../../template/helpers/templateHelpers";
import {CheckOutlined, CloseOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons";

const PostsByProduct = observer(() => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: (_this) => _this !== "" && _this !== null && _this.path ? <Image
                width={'100px'}
                src={_this.path}
            /> : ""
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Автор',
            dataIndex: 'username',
            key: 'username',
            render: (_this, item) => (
                <a href={item.userid} target="_blank">{_this}</a>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'date',
            key: 'date',
            render: (_this) => newDateTimeHelper(_this)
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Просмотр">
                        <Button type="dashed" shape="circle" href={`/posts/${record.id}`} icon={<SettingOutlined />} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <Drawer title="Список постов" placement="right" visible={_post.visibleList} onClose={() => _post.closeList()} width={50 + "%"}>
            <Button href={`/posts/create/${_post.productID}`} type="primary">Создать пост</Button>
            <Table
                columns={columns}
                dataSource={_post.list}
                pagination={false}
            />

            {_post.endList ? <Button onClick={() => _post.setOffset("post")}>Загрузить еще</Button> : "Показаны все записи"}
        </Drawer>
    )
})

export default PostsByProduct