import {observer} from "mobx-react-lite";
import _promo from "../controllers/promo.controller";
import TemplateTitle from "../../../template/title/TemplateTitle";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Col, DatePicker, Row, Space, Table} from "antd";
import moment from "moment";
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ButtonAction from "../../../template/buttons/ButtonsActions";

const ShopPromoStatistics = observer(() => {

    let { couponID } = useParams();

    useEffect(() => {
        _promo.getCoupon(couponID)
        _promo.getCouponStatistics(couponID)
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Применение",
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: "Дата и время применения",
            dataIndex: 'date',
            key: 'date',
            render: item => newDateTimeHelper(item)
        },
        {
            title: "",
            dataIndex: 'orderid',
            key: 'orderid',
            render: item => <>{item > 0 && <a href={`/shop/orders/${item}`}>Заказ</a>}</>
        },
        {
            title: "",
            dataIndex: 'userid',
            key: 'userid',
            render: item => <>{item > 0 && <a href={`/users/${item}`}>Пользователь</a>}</>
        }
    ];

    return(
        <>
            <TemplateTitle
                title="Статистика промокода"
                breadcrumb={_promo.breadcrumb}
                buttons={[
                    { type: "link", title: "Вернуться к списку", link: "/shop/promo" }
                ]}
            />

            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <label className="all-label">Начало периода</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.interval.from > 0 ? moment(new Date(_promo.interval.from), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChangeInterval("from", timeString)}
                    />
                </Col>
                <Col span={6}>
                    <label className="all-label">Окончание периода</label>
                    <DatePicker
                        showTime
                        style={{ width: 100 + "%" }}
                        format="YYYY-MM-DD HH:mm"
                        minuteStep={10}
                        value={_promo.interval.to > 0 ? moment(new Date(_promo.interval.to), "HH:mm") : ""}
                        onChange={(time, timeString) => _promo.onChangeInterval("to", timeString)}
                    />
                </Col>
                <Col span={24}>
                    Выберите интервал для получения статистики использования промокода
                </Col>
                <Col span={24} style={{ height: 300 + "px" }}>
                    <Table columns={columns} dataSource={_promo.statistics} pagination={false} />
                </Col>
            </Row>
        </>
    )
})

export default ShopPromoStatistics