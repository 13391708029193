import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/* Русский язык */

import ruHeader     from "./locales/ru/header.json";
import ruSignIn     from "./locales/ru/signin.json";
import ruForgot     from "./locales/ru/forgot.json";
import ruRegister   from "./locales/ru/register.json";
import ruCode       from "./locales/ru/code.json";
import ruForm       from "./locales/ru/form.json";
import ruPay        from "./locales/ru/pay.json";
import ruSuccessPay from "./locales/ru/successPay.json";
import ruHome       from "./locales/ru/home.json";
import ruUsers      from "./locales/ru/users.json";
import ruUser       from "./locales/ru/user.json";
import ruPages      from "./locales/ru/pages.json";
import ruPage       from "./locales/ru/page.json";
import ruNews       from "./locales/ru/news.json";
import ruNewsItem   from "./locales/ru/newsItem.json";
import ruContacts   from "./locales/ru/contacts.json";
import ruStatus     from "./locales/ru/status.json";
import ruProps      from "./locales/ru/props.json";
import ruPoints     from "./locales/ru/points.json";
import ruOrders     from "./locales/ru/orders.json";
import ruOrder      from "./locales/ru/order.json";
import ruImport     from "./locales/ru/import.json";
import ruDelivery   from "./locales/ru/delivery.json";
import ruSub        from "./locales/ru/sub.json";
import ruProduct    from "./locales/ru/product.json";
import ruCategories from "./locales/ru/categories.json";
import ruCategory   from "./locales/ru/category.json";
import ruNewsType   from "./locales/ru/newsType.json";
import ruSearch   from "./locales/ru/search.json";
import ruOnboarding from "./modules/onboarding/translations/onboarding.ru.json"

/* Английский язык */

import enHeader     from "./locales/en/header.json";
import enSignIn     from "./locales/en/signin.json";
import enForgot     from "./locales/en/forgot.json";
import enRegister   from "./locales/en/register.json";
import enCode       from "./locales/en/code.json";
import enForm       from "./locales/en/form.json";
import enPay        from "./locales/en/pay.json";
import enSuccessPay from "./locales/en/successPay.json";
import enHome       from "./locales/en/home.json";
import enUsers      from "./locales/en/users.json";
import enUser       from "./locales/en/user.json";
import enPages      from "./locales/en/pages.json";
import enPage       from "./locales/en/page.json";
import enNews       from "./locales/en/news.json";
import enNewsItem   from "./locales/en/newsItem.json";
import enContacts   from "./locales/en/contacts.json";
import enStatus     from "./locales/en/status.json";
import enProps      from "./locales/en/props.json";
import enPoints     from "./locales/en/points.json";
import enOrders     from "./locales/en/orders.json";
import enOrder      from "./locales/en/order.json";
import enImport     from "./locales/en/import.json";
import enDelivery   from "./locales/en/delivery.json";
import enSub        from "./locales/en/sub.json";
import enProduct    from "./locales/en/product.json";
import enCategories from "./locales/en/categories.json";
import enCategory   from "./locales/en/category.json";
import enSearch   from "./locales/en/search.json";

/* Украинский язык */

import ukHeader     from "./locales/uk/header.json";
import ukSignIn     from "./locales/uk/signin.json";
import ukForgot     from "./locales/uk/forgot.json";
import ukRegister   from "./locales/uk/register.json";
import ukCode       from "./locales/uk/code.json";
import ukForm       from "./locales/uk/form.json";
import ukPay        from "./locales/uk/pay.json";
import ukSuccessPay from "./locales/uk/successPay.json";
import ukHome       from "./locales/uk/home.json";
import ukUsers      from "./locales/uk/users.json";
import ukUser       from "./locales/uk/user.json";
import ukPages      from "./locales/uk/pages.json";
import ukPage       from "./locales/uk/page.json";
import ukNews       from "./locales/uk/news.json";
import ukNewsItem   from "./locales/uk/newsItem.json";
import ukContacts   from "./locales/uk/contacts.json";
import ukStatus     from "./locales/uk/status.json";
import ukProps      from "./locales/uk/props.json";
import ukPoints     from "./locales/uk/points.json";
import ukOrders     from "./locales/uk/orders.json";
import ukOrder      from "./locales/uk/order.json";
import ukImport     from "./locales/uk/import.json";
import ukDelivery   from "./locales/uk/delivery.json";
import ukSub        from "./locales/uk/sub.json";
import ukProduct    from "./locales/uk/product.json";
import ukCategories from "./locales/uk/categories.json";
import ukCategory   from "./locales/uk/category.json";
import ukSearch   from "./locales/uk/search.json";
import store from "store";

const resources = {
    Ru: {
        signIn:     ruSignIn,
        forgot:     ruForgot,
        header:     ruHeader,
        register:   ruRegister,
        code:       ruCode,
        form:       ruForm,
        pay:        ruPay,
        successPay: ruSuccessPay,
        home:       ruHome,
        users:      ruUsers,
        user:       ruUser,
        pages:      ruPages,
        page:       ruPage,
        news:       ruNews,
        newsItem:   ruNewsItem,
        contacts:   ruContacts,
        status:     ruStatus,
        props:      ruProps,
        points:     ruPoints,
        orders:     ruOrders,
        order:      ruOrder,
        import:     ruImport,
        delivery:   ruDelivery,
        sub:        ruSub,
        product:    ruProduct,
        categories: ruCategories,
        category:   ruCategory,
        newsType:   ruNewsType,
        search:   ruSearch,
        onboarding: ruOnboarding
    },
    En: {
        signIn:     enSignIn,
        forgot:     enForgot,
        header:     enHeader,
        register:   enRegister,
        code:       enCode,
        form:       enForm,
        pay:        enPay,
        successPay: enSuccessPay,
        home:       enHome,
        users:      enUsers,
        user:       enUser,
        pages:      enPages,
        page:       enPage,
        news:       enNews,
        newsItem:   enNewsItem,
        contacts:   enContacts,
        status:     enStatus,
        props:      enProps,
        points:     enPoints,
        orders:     enOrders,
        order:      enOrder,
        import:     enImport,
        delivery:   enDelivery,
        sub:        enSub,
        product:    enProduct,
        categories: enCategories,
        category:   enCategory,
        search:   enSearch
    },
    Uk: {
        signIn:     ukSignIn,
        forgot:     ukForgot,
        header:     ukHeader,
        register:   ukRegister,
        code:       ukCode,
        form:       ukForm,
        pay:        ukPay,
        successPay: ukSuccessPay,
        home:       ukHome,
        users:      ukUsers,
        user:       ukUser,
        pages:      ukPages,
        page:       ukPage,
        news:       ukNews,
        newsItem:   ukNewsItem,
        contacts:   ukContacts,
        status:     ukStatus,
        props:      ukProps,
        points:     ukPoints,
        orders:     ukOrders,
        order:      ukOrder,
        import:     ukImport,
        delivery:   ukDelivery,
        sub:        ukSub,
        product:    ukProduct,
        categories: ukCategories,
        category:   ukCategory,
        search:   ukSearch
    }
};

let lang = "Ru"

if(store.get("lang") && store.get("lang")) {
    lang = store.get("lang")
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;