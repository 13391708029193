import HeaderAuth from "../../components/HeaderAuth";
import React, {useEffect, useState} from "react";
import {Col, Row, Card, Space, Modal, Result, Button} from "antd";
import store from "store"
import axios from "axios";
import { withTranslation } from 'react-i18next';
import instance from "../../store/axios";

function Pay({ t }) {

    const [rate, setRate] = useState(0);

    const [type, setType] = useState(0);

    const [link, setLink] = useState("");

    const [success, setSuccess] = useState(false);

    useEffect(() => {

        if(type === 1) {
            setSuccess(true);
        } else if(type === 2) {
            let facts = new FormData();
            facts.append('userID', store.get('user').id);
            facts.append('tarif', rate);

            instance.post('/user/link/', facts).then(response => {
                console.log(response);
                setLink(response.data.data);
                if(response.data.data !== "") {
                    document.location.href = response.data.data;
                }
            })
        }
    }, [rate, type]);

    const GetLinkForPay = () => {
        return(
            <>
                <a href={link} className="p-form_link">{t('pay:continue')}</a>
            </>
        )
    }

    const ScorePay = () => {
        setType(1);
        let facts = new FormData();
        facts.append('userID', store.get("user").id);
        facts.append('tarif', rate);

        instance.post('/user/score/', facts).then(response => {
            console.log(response);
        })
    };

    return(
        <>
            <HeaderAuth logout={true} />
            <Row justify="space-around" align="middle" className="form">
                <Col span={20} className="center">
                    <h1>{t('pay:success')}</h1>
                    <h4>
                        {t('pay:save')}<br />
                        {t('pay:edit')}
                        <br /><br />
                        {t('pay:payed')}
                    </h4>
                    <br/>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title={t('pay:sixMonth')} bordered={true}>
                                7 000 ₽/{t('pay:month')}
                                <div className="buttons all">
                                    <button className="button" onClick={() => setRate(2)}>{t('pay:select')}</button>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={t('pay:year')} bordered={true}>
                                5 000 ₽/{t('pay:month')}
                                <div className="buttons all">
                                    <button className="button" onClick={() => setRate(1)}>{t('pay:select')}</button>
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={t('pay:oneMonth')} bordered={true}>
                                10 000 ₽/{t('pay:month')}
                                <div className="buttons all">
                                    <button className="button" onClick={() => setRate(3)}>{t('pay:select')}</button>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    {rate > 0 &&
                        <>
                            {t('pay:toPay')}
                            {rate === 1 && " 60 000 "}
                            {rate === 2 && " 42 000 "}
                            {rate === 3 && " 10 000 "}
                            ₽

                            <div>
                                <div className="buttons all">
                                    <Space>
                                        <button className="button" onClick={() => setType(2)}>{t('pay:cart')}</button>
                                        <button className="button" onClick={ScorePay}>{t('pay:score')}</button>
                                    </Space>
                                </div>
                            </div>
                        </>
                    }

                    {type === 2 && link !== "" &&
                    <>
                        <GetLinkForPay />
                    </>
                    }


                    <Modal visible={success} centered footer={null} onCancel={() => setSuccess(false)}>
                        <Result
                            status="success"
                            title={t('pay:received')}
                            subTitle={t('pay:scoreEmail') + store.get("user").login}
                            extra={[
                                <div className="buttons all">
                                    <Button type="primary" onClick={() => setSuccess(false)}>{t('pay:good')}</Button>
                                </div>,
                            ]}
                        />
                    </Modal>
                </Col>
            </Row>
        </>
    )
}

export default withTranslation()(Pay);