import { makeAutoObservable } from "mobx"
import loaderController from "../../../template/loader/loader.controller"
import instance from "../../../../store/axios"
import store from "store"
import sha1 from "sha1"

class userSettingController {
    constructor() {
        makeAutoObservable(this)
    }

    passwords = {
        oldPassword: "",
        password: "",
        confirmPassword: "",
        error: ""
    }

    data = {
        firstname: store.get("user") ? store.get("user").firstname : "",
        lastname: store.get("user") ? store.get("user").lastname : "",
        surname: store.get("user") ? store.get("user").surname : ""
    }

    onChangePassword(key, value) {
        this.passwords[key] = value
    }

    onChangeData(key, value) {
        this.data[key] = value
    }

    onPassword() {
        let facts = new FormData();
        facts.append("type", "password")
        facts.append("oldPassword", this.passwords.oldPassword)
        facts.append("password", this.passwords.password)
        facts.append("confirmPassword", this.passwords.confirmPassword)

        console.log(sha1(this.passwords.oldPassword))

        instance.post("/user/setting", facts)
            .then(success => {
                console.log(success)
                loaderController.onChange(false)
            })
            .catch(error => {
                console.log(error)
                this.passwords.error = error.response.data
                loaderController.onChange(false)
            })
    }

    onData(e) {
        e.preventDefault()
        loaderController.onChange(true)

        let facts = new FormData();
        facts.append("type", "user")
        facts.append("firstname", this.data.firstname)
        facts.append("lastname", this.data.lastname)
        facts.append("surname", this.data.surname)

        instance.post("/user/setting", facts)
            .then(success => {
                console.log(success)
                store.set("user", success.data)
                loaderController.onChange(false)
            })
            .catch(error => {
                loaderController.onChange(false)
            })
    }

    checkPasswords(e) {
        e.preventDefault()
        loaderController.onChange(true)

        if(this.passwords.password !== this.passwords.confirmPassword) {
            this.passwords.error = "Пароли не совпадают"
            loaderController.onChange(false)
        } else {
            this.onPassword()
        }
    }
}

export default new userSettingController()