import { observer } from "mobx-react-lite";
import {Button, Col, Input, Modal, Row, Space, Upload} from "antd";
import {
    DeleteOutlined,
    FileImageOutlined,
    FontSizeOutlined,
    LinkOutlined,
    PictureOutlined,
    UploadOutlined
} from "@ant-design/icons";
import _editor from "./_editor";
import getLoader from "../../store/uploadFiles.controller";
import React from "react";

const Editor = observer(() => {

    const updateDataEditor = (value) => {
        if(value.path) {
            console.log(value.path)
            _editor.setImage(value.path)
        }

    }

    return(
        <>
            <Row>
                <Col span={24}>
                    {_editor.blocks.length > 0 && _editor.blocks.map(item => (
                        <>
                            {_editor.block.id && _editor.block.id === item.id &&
                            <div className="template-news-detail-edited">
                                {item.type === "text" &&
                                <textarea
                                    rows={8}
                                    value={_editor.block.value}
                                    onChange={e => _editor.setBlock("value", e.target.value)}
                                    onBlur={() => _editor.blurInput()}
                                    onKeyUp={(e) => _editor.onKeyUpInput(e, _editor.block.id)}
                                    style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                                    autoFocus
                                />
                                }
                            </div>
                            }
                            {_editor.block.id !== item.id &&
                            <div className="template-news-buttons">
                                {item.type === "text" &&
                                <div onClick={() => _editor.selectBlock(item.id)}
                                     className="template-news-detail-old">
                                    {item.value}
                                </div>
                                }
                                {item.type === "link" &&
                                <>
                                    <div onClick={() => _editor.selectLink(item.id)}
                                         className="template-news-detail-old">
                                        <a href={item.value} target="_blank">{item.name !== "" ? item.name : item.value}</a>
                                    </div>
                                </>
                                }
                                {item.type === "image" &&
                                <div>
                                    <img src={item.value} alt="" className="image-item" width={100 + "%"} />
                                </div>
                                }
                                <Button
                                    size="small"
                                    type="dashed"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    danger
                                    className="delete"
                                    onClick={() => _editor.deleteInput(item.id)}
                                />
                                <div>
                                    <Space>
                                        <Button
                                            size="small"
                                            type="dashed"
                                            shape="circle"
                                            icon={<FileImageOutlined />}
                                            className="image"
                                            onClick={() => _editor.newImage(item.id)}
                                        />
                                        <Button
                                            size="small"
                                            type="dashed"
                                            shape="circle"
                                            icon={<FontSizeOutlined />}
                                            className={"plus"}
                                            onClick={() => _editor.addInput(item.id)}
                                        />
                                        <Button
                                            size="small"
                                            type="dashed"
                                            shape="circle"
                                            icon={<LinkOutlined />}
                                            className={"plus"}
                                            onClick={() => _editor.addInput(item.id, "link")}
                                        />
                                    </Space>
                                </div>
                            </div>
                            }
                        </>
                    ))}
                    {_editor.block.type === "text" && !_editor.block.id &&
                        <div className="template-news-detail-edited">
                            <textarea
                                rows={8}
                                value={_editor.block.value}
                                onChange={e => _editor.setBlock("value", e.target.value)}
                                onKeyUp={(e) => _editor.onKeyUpInput(e)}
                                onBlur={() => _editor.blurInput()}
                                style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                                autoFocus={true}
                            />
                        </div>
                    }
                    {_editor.block.type === "link" && !_editor.block.id &&
                    <div className="template-news-detail-edited">
                        <input
                            type="text"
                            placeholder="https://"
                            value={_editor.block.value}
                            onChange={e => _editor.setBlock("value", e.target.value)}
                            onKeyUp={(e) => _editor.onKeyUpInput(e)}
                            onBlur={() => _editor.blurInput()}
                            style={{ width: 100 + "%", maxWidth: 800 + "px" }}
                            autoFocus={true}
                        />
                    </div>
                    }
                </Col>
                <Col span={24}>
                    <Space>
                        <Button
                            type="primary"
                            icon={<FontSizeOutlined />}
                            onClick={() => _editor.newBlock("text")}
                        >
                            Добавить текст
                        </Button>
                        <Button
                            type="primary"
                            icon={<PictureOutlined />}
                            onClick={() => _editor.newImage()}
                        >
                            Добавить изображение
                        </Button>
                        <Button
                            type="primary"
                            icon={<LinkOutlined />}
                            onClick={() => _editor.newLink()}
                        >
                            Добавить ссылку
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Modal
                title="Изображение"
                visible={_editor.image.display}
                onOk={() => _editor.saveImage()}
                onCancel={() => _editor.closeImage()}
                centered={true}
            >
                <Upload
                    multiple={false}
                    fileList={[]}
                    customRequest={e => getLoader(e, "images", "file", "news", updateDataEditor)}
                    listType="picture"
                    maxCount={1}
                >
                    <Button icon={<UploadOutlined />}>Загрузить</Button>
                </Upload>
            </Modal>
            <Modal
                title="Редактирование ссылки"
                visible={_editor.link.display}
                onOk={() => _editor.saveLink()}
                onCancel={() => _editor.closeLink()}
                centered={true}
            >
                <Input
                    onChange={(e) => _editor.setLink("name", e.target.value)}
                    placeholder="Название ссылки"
                    value={_editor.link.name}
                />
                <Input
                    onChange={(e) => _editor.setLink("url", e.target.value)}
                    placeholder="Ссылка"
                    value={_editor.link.url}
                />
            </Modal>
        </>
    )
})

export default Editor