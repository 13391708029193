import axios from "axios";
import store from "store";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

const instance = axios.create({
    baseURL: store.get("project") && store.get("project").baseURL !== "" ? store.get("project").baseURL : HOST,
    headers: {
        "Authorizations": APIKEY,
        "bandl": store.get("project") && store.get("project").bandl !== "" ? store.get("project").bandl : "",
        "User-Token": store.get("user") && store.get("user").token ? store.get("user").token : "",
        "Language": store.get("lang") ? store.get("lang") : "Ru",
        "Type-Device": store.get("project") && store.get("project").bandl !== "" ? "react_" + store.get("project").bandl : "react"
    }
});

export default instance;