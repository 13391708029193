import {makeAutoObservable} from "mobx";

class headerController {
    constructor() {
        makeAutoObservable(this)
    }

    searchValue = ""

    menuTop = "dashboard"
    menuLevel = ""

    collapsed = false

    setMenu(type, value) {
        if(value !== undefined) {
            console.log("setMenu " + type + " " + value)
            this.collapsed = false
            this[type] = value
        }
    }

    onChangeCollapsed() {
        this.collapsed = this.collapsed ? false : true
    }

    onChangeSearchValue(value) {
        this.searchValue = value
    }

    getToSearch(e) {
        e.preventDefault()
        document.location.href = "/shop/search?name=" + this.searchValue
    }


}

export default new headerController()