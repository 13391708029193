import {makeAutoObservable} from "mobx";
import store from "store"
import i18n from "i18next";

class langController {

    constructor() {
        makeAutoObservable(this)
    }

    language = store.get("lang") && store.get("lang") ? store.get("lang") : "Ru"

    onChangeLang(language) {
        this.language = language
        i18n.changeLanguage(language);
        store.set("lang", language)
    }

}

export default new langController()