

import React, { useState, useEffect } from 'react'
import axios from "axios";
import {Button, Table, Col, Row, Typography, Tooltip, Breadcrumb, Space, Input, Modal, notification, Tag} from 'antd';
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../store/axios";

function NewsTypeList({ t }) {

    const [types, setTypes] = useState([]);
    const [type, setType] = useState({
        display: false,
        id: 0,
        name: "",
        colorhex: ""
    });
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        instance.get("/info/newstype")
            .then(success => {
                console.log(success);
                setTypes(success.data.data[0]);
            })
            .catch(error => console.log(error));
    }, [update]);

    const deleteNews = (typeID) => {
        if(window.confirm(t('newsType:confirm'))) {
            instance.delete("/info/newstype/" + typeID)
                .then(success => {
                    console.log(success);
                    setUpdate(update + 1);
                })
                .catch(error => console.log(error));
        }
    };

    const columns = [
        {
            title: t('newsType:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('newsType:tag'),
            dataIndex: 'colorhex',
            key: "colorhex",
            render: (text, record) => (
                <Tag color={"#" + text}>{record.name}</Tag>
            )
        },
        {
            title: t('newsType:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title={t('newsType:edit')}>
                        <Button type="dashed" shape="circle" icon={<SettingOutlined />} onClick={() => openModal(record.id)} />
                    </Tooltip>
                    <Tooltip title={t('newsType:delete')}>
                        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} danger onClick={() => deleteNews(record.id)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const sendForm = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('name', type.name);
        data.append('colorhex', type.colorhex);

        instance.post('/info/newstype/' + type.id + '/', data)
            .then(response => {
                console.log(response)
                if(response.data.code === 200){
                    notification.success({
                        placement: "bottomLeft",
                        message: t('status:success'),
                        description: t('newsType:editSuccess'),
                    });
                }else if(response.data.code === 201){
                    notification.success({
                        placement: "bottomLeft",
                        message: t('status:success'),
                        description: t('newsType:createSuccess'),
                    });
                }
                closeModal();
        })
    };

    const closeModal = () => {
        setType({
            display: false,
            id: 0,
            name: '',
            colorhex: ''
        });
        setUpdate(update + 1);
    };

    const openModal = (id = 0) => {
        if(id > 0) {
            instance.get('/info/newstype/' + id + '/')
                .then(response => {
                    setType({
                        display: true,
                        id: id,
                        name: response.data.data.name,
                        colorhex: response.data.data.colorhex
                    });
                })
        } else {
            setType({
                ...type,
                display: true
            });
        }
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Typography.Title>{t('newsType:title')}</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" onClick={openModal}>{t('newsType:create')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('newsType:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('newsType:title')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Table columns={columns} dataSource={types} pagination={false} />
            <Modal
                title={t('newsType:editType')}
                visible={type.display}
                onOk={sendForm}
                onCancel={closeModal}
                centered={true}
            >
                <label className="all-label">{t('newsType:nameType')}</label>
                <Input
                    value={type.name}
                    onChange={(e) => setType({ ...type, name: e.target.value })}
                />

                <label className="all-label">{t('newsType:colorType')}</label>
                <Input
                    value={type.colorhex}
                    onChange={(e) => setType({ ...type, colorhex: e.target.value })}
                />
            </Modal>
        </>
    )
}

export default withTranslation()(NewsTypeList);