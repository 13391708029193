import {makeAutoObservable} from 'mobx';
import sender from '../template/controllers/sender';
import hashController from '../template/controllers/hash.controller';

class complaintsController {

    elements = []
    types = []

    visible = false

    element = {}

    constructor() {
        makeAutoObservable(this)
    }

    list() {
        sender.get('/user/complaint')
            .then(result => {
                console.log(result)
                this.elements = result.data[0]
            })
            .catch(error => {
                console.log(error)
            })
    }

    listTypes() {
        sender.get('/user/complainttype')
            .then(result => {
                console.log(result)
                this.types = result.data[0]
            })
            .catch(error => {
                console.log(error)
            })
    }

    remove(element_id) {
        if(window.confirm(`Вы уверены, что хотите удалить элемент?`)) {
            let facts = {
                typestructure: "ksComplaints"
            }

            sender.delete(`/product/delete/${element_id}`, { data: "typestructure=ksComplaints&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.list()
                })
                .catch(response => {
                    console.log("Ошибка удаления")
                    console.log(response)
                })
        }
    }

    removeType(element_id) {
        if(window.confirm(`Вы уверены, что хотите удалить элемент?`)) {
            let facts = {
                typestructure: "ksComplaintType"
            }

            sender.delete(`/product/delete/${element_id}`, { data: "typestructure=ksComplaintType&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.listTypes()
                })
                .catch(response => {
                    console.log("Ошибка удаления")
                    console.log(response)
                })
        }
    }

    openEditType(element_id) {
        this.visible = true

        if(element_id > 0)
            this.element = this.types.find(el => el.id === element_id)
        else
            this.element = { name: '', object: '' }
    }

    save_type() {
        const data = new FormData()
        data.append('name', this.element.name)
        data.append('object', this.element.object)
        data.append("hash", hashController.setHash(data))

        const id = this.element.id && this.element.id > 0 ? this.element.id : 0

        sender.post(`/user/complainttype/${id}`, data)
            .then(result => {
                this.close()
                this.listTypes()
            })
            .catch(error => {
                console.log(error)
            })
    }

    close() {
        this.element = {}
        this.visible = false
    }

    onChange(name, value) {
        this.element[name] = value
    }

}

export default new complaintsController()