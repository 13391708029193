import {makeAutoObservable} from "mobx";
import instance from "../../../../store/axios";
import {notification} from "antd";

class shopProductStatusesController {
    constructor() {
        makeAutoObservable(this)
    }

    statuses = []
    status = {}

    createStatus() {
        this.clearStatus()
        this.status = {
            id: 0,
            name: "",
            colorhex: "7264D6"
        }
        this.statuses.unshift(this.status)
    }

    onSubmit(e) {
        e.preventDefault()

        let data = new FormData();
        data.append('name', this.status.name);
        data.append('colorhex', this.status.colorhex);
        data.append('viewcart', this.status.viewcart ? this.status.viewcart : 0);
        data.append('viewmain', this.status.viewmain ? this.status.viewmain : 0);

        let statusID = this.status.id && this.status.id > 0 ? this.status.id : 0

        instance.post('/shop/status/' + statusID + '/', data)
            .then(response => {
                console.log(response)
                this.getAllStatuses()
                this.clearStatus()
            })
    }

    clearStatus() {
        this.status = {}
    }

    onChangeSwitch(key, value) {
        let e = 0
        if(value) {
            e = 1
        }
        this.onChangeStatus(key, e)
    }

    selectStatus(statusID) {
        instance.get('/shop/status/' + statusID + '/')
            .then(response => {
                this.status = response.data.data
            })
    }

    onChangeStatus(key, value) {
        this.status[key] = value
    }

    getAllStatuses() {
        instance.get('/shop/statuses/')
            .then(success => {
                console.log(success)
                this.statuses = success.data.data[0]
            })
    }

    deleteStatus(statusID) {
        instance.delete('/shop/status/' + statusID + '/')
            .then(response => {
                if(response.data.code === 200){
                    this.getAllStatuses()
                }
        })
    }
}

export default new shopProductStatusesController()