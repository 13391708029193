import {autorun, makeAutoObservable} from "mobx";
import loaderController from "../../template/loader/loader.controller";
import store from "store";
import instance from "../../../store/axios";

class formController {
    constructor() {
        makeAutoObservable(this)
        console.log(this)
    }

    error = ""

    question = 0

    data = {
        name: "",
        cartType: 'yandex',
        apiKeyMap: '',
        metricType: 'yandex',
        apiKeyMetric: '',
        logo: false,
        variant: "1",
        variantProduct: "1",
        variantTitle: "1",
        search: "0",
        sortby: "0",
        favourite: "0",
        sam: "0",
        kur: "0",
        onlineCard: "0",
        apple: "0",
        google: "0",
        samsung: "0",
        cash: "0",
        curierCard: "0",
        schet: "0",
    }

    onChangeModule(key) {
        if(this.data[key] === "1") {
            this.data[key] = "0"
        } else {
            this.data[key] = "1"
        }
    }

    onChangeData(key, value) {
        this.data[key] = value
    }

    onChangeQuestion(value) {
        this.question = value
    }

    onSubmitTwo(e) {
        e.preventDefault()
        this.onChangeQuestion(2)
    }

    onSubmit() {
        loaderController.onChange(true)

        let facts = new FormData();
        facts.append('userID', store.get('user').id);
        facts.append('name', this.data.name);
        facts.append('cartType', this.data.cartType);
        facts.append('apiKeyMap', this.data.apiKeyMap);
        facts.append('metricType', this.data.metricType);
        facts.append('apiKeyMetric', this.data.apiKeyMetric);
        facts.append('variant', this.data.variant);
        facts.append('variantProduct', this.data.variantProduct);
        facts.append('variantTitle', this.data.variantTitle);
        facts.append('search', this.data.search);
        facts.append('sortby', this.data.sortby);
        facts.append('favourite', this.data.favourite);
        facts.append('sam', this.data.sam);
        facts.append('kur', this.data.kur);
        facts.append('onlineCard', this.data.onlineCard);
        facts.append('apple', this.data.apple);
        facts.append('google', this.data.google);
        facts.append('samsung', this.data.samsung);
        facts.append('cash', this.data.cash);
        facts.append('curierCard', this.data.curierCard);
        facts.append('schet', this.data.schet);

        if(this.data.logo){
            facts.append('logo', this.data.logo);
        }

        instance.post('/user/application/', facts)
            .then(response => {
                store.set('user', response.data);
                document.location.href="/";
            })
            .catch(error => {
                this.error = error.response.data
                loaderController.onChange(false)
            })
    }
}

export default new formController()