import {makeAutoObservable} from "mobx";
import hashController from "../../../template/controllers/hash.controller";
import sender from "../../../template/controllers/sender";
import {notification} from "antd";

class promoController {

    constructor() {
        makeAutoObservable(this)
    }

    coupon = {
        id: 0,
        datestart: Math.round(Date.now()),
        dateend: 0,
        text: "",
        countall: 0
    }

    coupons = []
    interval = {
        from: 0,
        to: 0
    }
    statistics = []

    breadcrumb = [
        { link: "/shop", value: "Мой магазин" },
        { link: "/shop/promo", value: "Промокоды" }
    ]

    getCoupon(couponID) {
        if(couponID > 0) {
            sender.get(`/product/promocode/${couponID}`)
                .then(response => {
                    console.log(response)
                    this.coupon = response.data
                    this.coupon.onetouser = response.data.onetouser > 0
                    this.coupon.firstorder = response.data.firstorder > 0
                    this.coupon.datestart = response.data.datestart > 0 ? response.data.datestart * 1000 : 0
                    this.coupon.dateend = response.data.dateend > 0 ? response.data.dateend * 1000 : 0
                })
                .catch(response => {

                })
        }
    }

    getCoupons() {
        sender.get(`/product/promocode`)
            .then(response => {
                console.log(response)
                this.coupons = response.data
            })
            .catch(response => {

            })
    }

    deleteCoupon(couponID) {
        if(window.confirm(`Вы уверены, что хотите удалить промокод?`)) {
            let facts = {
                typestructure: "ksPromocode"
            }

            sender.delete(`/product/delete/${couponID}`, { data: "typestructure=ksPromocode&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.getCoupons()
                })
                .catch(response => {
                    console.log("Ошибка удаления")
                    console.log(response)
                })
        }

    }

    saveCoupon(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append("name", this.coupon.name)
        facts.append("code", this.coupon.code)
        facts.append("text", this.coupon.text)
        facts.append("countall", this.coupon.countall)
        facts.append("count", this.coupon.countall > 0 ? this.coupon.countall : 1 )

        if(this.coupon.countall === 0) facts.append("nocount", 1)
        if(this.coupon.countall > 0) facts.append("nocount", 0)

        facts.append("datestart", this.coupon.datestart > 0 ? Math.floor(this.coupon.datestart/1000) : 0)
        facts.append("dateend", this.coupon.dateend > 0 ? Math.floor(this.coupon.dateend/1000) : 0)
        facts.append("onetouser", this.coupon.onetouser === true ? 1 : 0)
        facts.append("firstorder", this.coupon.firstorder === true ? 1 : 0)
        facts.append("summcard", this.coupon.summcard)

        facts.append("value", this.coupon.value)
        facts.append("type", this.coupon.type)
        facts.append('hash', hashController.setHash(facts))

        sender.post(`/product/promocode/${this.coupon.id}`, facts)
            .then(response => {
                if(response.status === 201) {
                    window.location = "/shop/promo"
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Промокод успешно изменен'
                    })
                }
            })
            .catch(response => {
                console.log(response)
            })
    }

    onChange(name, value) {
        if(name === "countall" && value !== this.coupon.count && value !== 0 && this.coupon.id) {
            if(!window.confirm(`Вы уверены, что хотите изменить кол-во использований промокода? В случае согласия, кол-во неиспользованных промокодов будет изменено на ${value}`)) {
                return false
            }
        }
        if(name === "datestart" || name === "dateend") {
            value = Math.floor(Date.parse(value))
        } else {

        }
        this.coupon[name] = value
    }

    onChangeInterval(name, value) {
        this.interval[name] = Math.floor(Date.parse(value))
        this.getCouponStatistics(this.coupon.id)
    }

    getCouponStatistics(couponID) {
        let intervals = ``
        if(this.interval.from > 0 && this.interval.to > 0) {
            intervals = `?filter[date_le]=${Math.floor(this.interval.to/1000)}&filter[date_ge]=${Math.floor(this.interval.from/1000)}`
        }

        sender.get(`/product/promocodestat/${couponID}${intervals}`)
            .then(response => {
                console.log(response)
                this.statistics = response.data[0]
            })
            .catch(response => {

            })
    }
}

export default new promoController()