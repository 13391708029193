import { makeAutoObservable } from "mobx"
import __filter from "./_filter"
import __configurations from "./__configurations.helper"
import i18n from '../../../i18n';
import hashController from "../../template/controllers/hash.controller"
import instance from '../../../store/axios';

class __constructor {

    list = []

    breadcrumb = [
        { key: "element", link: null, value: i18n.t('constructor:title') }
    ]

    buttons = [
        // { title: i18n.t('constructor:buttons.addBlock') , type: "button", ref: () => this.addBlock() }
    ]

    statuses = []

    modal = false       // Признак модального окна для редактирования/создания блока
    config = []         // Конфигурация модального окна при редактировании/создании блока
    selectable = {}     // Выбранный блок для редактирования/создания

    constructor() {
        makeAutoObservable(this)
    }

    getUrl(type) {
        let returned = "page"

        if(type === "ksbuttontext") { returned = "buttontext" }
        if(type === "ksbaner") { returned = "poster" }
        if(type === "ksvideo") { returned = "video" }
        if(type === "ksvideocategory") { returned = "videocat" }
        if(type === "ksstory") { returned = "story" }
        if(type === "ksstoryslide") { returned = "storyslide" }
        if(type === "ksstoryblock") { returned = "storyblock" }
        if(type === "ksblockproduct") { returned = "blockproduct" }
        if(type === "ksslider") { returned = "slider" }

        return returned
    }

    setClass(className) {
        let returned = "ksPage"
        if(className === "ksstoryblock") { returned = "ksStoryblock" }
        if(className === "ksslider") { returned = "ksSlider" }
        if(className === "ksbaner") { returned = "ksBaner" }
        if(className === "ksblockproduct") { returned = "ksBlockproduct" }
        if(className === "ksbuttontext") { returned = "ksButtontext" }
        if(className === "ksvideocategory") { returned = "ksVideocategory" }
        if(className === "ksvideo") { returned = "ksVideo" }
        if(className === "ksstory") { returned = "ksStory" }
        if(className === "ksstoryslide") { returned = "ksStoryslide" }
        if(className === "ksstoryblock") { returned = "ksStoryblock" }
        if(className === "ksblockproduct") { returned = "ksBlockproduct" }

        return returned
    }

    getNameBlockByType(type) {
        let returned = ""

        if(type === "ksslider") { returned = i18n.t('constructor:types.slider') }
        else if(type === "ksbuttontext") { returned = i18n.t('constructor:types.buttonText') }
        else if(type === "ksblockproduct") { returned = i18n.t('constructor:types.products') }
        else if(type === "ksvideocategory") { returned = i18n.t('constructor:types.video') }
        else if(type === "ksstoryblock") { returned = i18n.t('constructor:types.story') }
        else { returned = __filter.data.types.find(el => el.type === type).title }

        return returned
    }

    delete(type, elementID, ref) {
        if(window.confirm(i18n.t("confirm:deleteElement"))) {
            let className = this.setClass(type)
            let facts = {
                typestructure: className
            }

            instance.delete(`/product/delete/${elementID}`, {data: "typestructure=" + className + "&hash=" + hashController.setHashDelete(facts)})
                .then(success => {
                    ref()
                })
                .catch(error => console.log(error.response))
        }
    }

    post(url, facts, refs) {
        instance.post(url, facts)
            .then(response => {
                console.log(response)
                refs()
            })
            .catch(response => console.log("Error"))
    }

    setStatus(type, elementID, value) {
        let facts = new FormData()
        facts.append("active", value)
        facts.append("hash", hashController.setHash(facts))

        let url = `/home/${this.getUrl(type)}/${elementID}`

        this.post(url, facts, () =>  this.reload())
    }

    upOrDown(type, elementID, key, action="moveUp") {
        let facts = new FormData()
        facts.append("action", action)
        facts.append("class", type)
        facts.append("key", key)
        facts.append('hash', hashController.setHash(facts))

        this.post(`/home/position/${elementID}`, facts, () =>  this.reload())
    }

    set(prop, value) {
        if(prop === "type" && value !== this.selectable.element[prop]) {
            if(value === "product") {
                this.selectable.element.blockid = []
                this.selectable.element.product = []
            } else if(value === "productcategory" || value === "status") {
                this.selectable.element.blockid = 0
            }
        }

        this.selectable.element[prop] = value
    }

    closeModal() {
        this.modal = false
        // this.selectable = {}
    }

    reload() {
        this.get()
        this.closeModal()
    }

    createPage(type, blockID, active=1) {
        let facts = new FormData()
        facts.append("active", active)
        facts.append("place", __filter.data.selectableScreen)
        facts.append("type", type)
        facts.append("blockid", blockID)

        facts.append('hash', hashController.setHash(facts))

        this.post(`/home/page/0`, facts, () =>  this.reload())
    }

    sendError(text) {
        console.log(text)
        return false
    }

    save(event) {
        event.preventDefault()
        let facts = new FormData()

        let success = true



        this.selectable.item.map(item => {
            if(item.required && (!this.selectable.element[item.name] || this.selectable.element[item.name] === "" || this.selectable.element[item.name] === null || this.selectable.element[item.name] === undefined)) {


                if(item.type === "select") {
                    this.selectable.element[item.name] = item.items[0].value
                } else {
                    this.sendError(`${i18n.t('constructor:error.input')}: ${item.label}`)
                    success = false
                    return false
                }


            }
            if(this.selectable.element[item.name] === undefined || !this.selectable.element[item.name]) {
                if(item.type && item.type === "dependence") {
                    this.selectable.element[item.name] = 0
                } else if(item.type && item.type === "switch") {
                    this.selectable.element[item.name] = 0
                } else {
                    this.selectable.element[item.name] = ""
                }
            }
        })

        if(!success) return false


        this.selectable.item.map(item => {
            if(item.name !== "blockid") {
                console.log(item.name, this.selectable.element[item.name])
                facts.append(item.name, this.selectable.element[item.name])
            }
        })

        if(this.selectable.parentType && this.selectable.parentType !== "" && this.selectable.parentID > 0) {
            facts.append(this.selectable.parentType, this.selectable.parentID)
        }

        if(this.selectable.element.type && this.selectable.element.blockid) {
            console.log(this.selectable.element.type)
            if(Array.isArray(this.selectable.element.blockid)) {
                this.selectable.element.blockid.map(item => {
                    facts.append("blockid[]", item)
                })
            } else {
                facts.append("blockid", Number(this.selectable.element.blockid))
            }
        }

        console.log(facts)
        facts.append("hash", hashController.setHash(facts))

        let url = `/home/${this.getUrl(this.selectable.type)}`
        if(this.selectable.element.id && Number(this.selectable.element.id) > 0) { url = `${url}/${this.selectable.element.id}` }
        else { url = `${url}/0` }

        instance.post(url, facts)
            .then(response => {
                console.log(response)

                if(!this.selectable.element.id && !this.selectable.parentType) {
                    this.createPage(this.selectable.type, response.id, this.selectable.element.active)
                } else {
                    this.reload()
                }
            })
            .catch(response => {
                console.log(response)
                console.log("Error")
            })

    }

    get() {
        let url = `/home/page/${__filter.data.selectableScreen}`

        if(__filter.data.unSelectableTypes.length > 0) {
            url = `${url}${__filter.getFilterTypes()}`
        }

        console.log(url)

        instance.get(url)
            .then(response => {
                console.log(response)
                this.list = response
            })
            .catch(response => {
                console.log(i18n.t('categories:catalog'))
            })
    }

    addBlock(type, parentID=null) {
        if(__filter.data.types.find(el => el.type === type).disabled) {
            this.createPage(type, 0)
        } else {
            this.selectable = {
                type: type,
                item: [],
                element: { active: 1 },
                parentID: parentID
            }

            __configurations.getConfig(type)

            this.modal = true
        }
    }

    select(type, element, parentID=null) {

        this.selectable = {
            type: type,
            item: [],
            element: element,
            parentID: parentID
        }

        __configurations.getConfig(type)

        this.modal = true
    }

    selectConfig(config) {
        this.selectable.item = config
    }

    typeLink(type) {
        console.log(type)
        let title = ""

        if(type === "news") {
            title = i18n.t('constructor:links.news')
        } else if(type === "category") {
            title = i18n.t('constructor:links.category')
        } else if(type === "product") {
            title = i18n.t('constructor:links.product')
        } else if(type === "url") {
            title = i18n.t('constructor:links.web')
        } else if(type === "url_blank") {
            title = i18n.t('constructor:links.webBlank')
        }

        return title
    }

    getParentType(type) {
        let returned = ""

        if(type === "ksbaner") { returned = "sliderid" }
        else if(type === "ksvideo") { returned = "parentid" }
        else if(type === "ksstoryslide") { returned = "storyid" }
        else if(type === "ksstory") { returned = "storyblockid" }

        return returned
    }

    selectItem(element={}, type, parentID=0) {
        element = {...element, active: 1}
        this.selectable = {
            type: type,
            item: [],
            element: element,
            parentID: parentID,
            parentType: this.getParentType(type)
        }

        __configurations.getConfig(type)

        this.modal = true
    }

    getStatuses() {
        instance.get("/shop/statuses", {}, true)
            .then(response => {
                this.statuses = response.data[0]
            })
    }

    deleteProductFromArray(productID) {
        this.selectable.element.blockid = this.selectable.element.blockid.filter(el => Number(el) !== productID)
        this.selectable.element.product = this.selectable.element.product.filter(el => Number(el.id) !== productID)
    }

    addProductToArray(element) {
        let arr = this.selectable.element.product && Array.isArray(this.selectable.element.product) ? this.selectable.element.product : []
        arr.push(element)
        this.selectable.element.blockid.push(element.id)
        this.selectable.element.product = arr
    }
}

export default new __constructor()