import {observer} from "mobx-react-lite";
import {Breadcrumb, Button, Col, Row} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import usc from "./controllers/userSettingController"
import loaderController from "../../template/loader/loader.controller";
import LoaderPage from "../../template/loader/LoaderPage";

const UserSetting = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('orders:main')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <h1>Персональнальные данные</h1>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <h5>Ваши данные</h5>
                </Col>
                <Col span={24}>
                    <form onSubmit={e => usc.onData(e)}>
                        <Row className="template-form" gutter={[20, 10]}>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Ваше имя"
                                    type="text"
                                    onChange={e => usc.onChangeData("firstname", e.target.value)}
                                    value={usc.data.firstname}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Ваша фамилия"
                                    type="text"
                                    onChange={e => usc.onChangeData("lastname", e.target.value)}
                                    value={usc.data.lastname}
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Ваше отчество"
                                    type="text"
                                    onChange={e => usc.onChangeData("surname", e.target.value)}
                                    value={usc.data.surname}
                                />
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Button type="primary" htmlType="submit">Сохранить</Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col span={24}>
                    <h5>Изменить пароль</h5>
                </Col>
                <Col span={24}>
                    <form onSubmit={e => usc.checkPasswords(e)}>
                        <Row className="template-form" gutter={[20, 10]}>
                            <Col span={24}>
                                {usc.passwords.error}
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Ваш текущий пароль"
                                    type="password"
                                    onChange={e => usc.onChangePassword("oldPassword", e.target.value)}
                                    value={usc.passwords.oldPassword}
                                    required
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Введите новый пароль"
                                    type="password"
                                    onChange={e => usc.onChangePassword("password", e.target.value)}
                                    value={usc.passwords.password}
                                    required
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 5 }}>
                                <input
                                    placeholder="Повторите новый пароль"
                                    type="password"
                                    onChange={e => usc.onChangePassword("confirmPassword", e.target.value)}
                                    value={usc.passwords.confirmPassword}
                                    required
                                />
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={usc.passwords.oldPassword !== "" && usc.passwords.password !== "" && usc.passwords.confirmPassword !== "" ? "" : "disabled"}
                                >
                                    Изменить пароль
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col span={24}>
                    <Button href="/logout" type="primary" danger>Выйти</Button>
                </Col>
            </Row>
            {loaderController.load && <LoaderPage />}
        </>
    )
})

export default UserSetting