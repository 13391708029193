import { observer } from "mobx-react-lite"
import { Col, Upload } from "antd"
import { InboxOutlined } from "@ant-design/icons"
import getLoader from "../../../store/uploadFiles.controller"
import i from '../../../i18n';

const ConstructorImageHelper = observer(({ photo, refs, type, id=0 }) => {

    const updateData = (value) => {
        if(value.path && value.path !== "") {
            refs(value.path)
        }
    }

    return(
        <>
            <Col span={24}>
                <label className="all-label">{photo ? i.t('def:image.put') : i.t('def:image.upload')}</label>
                <Upload.Dragger
                    multiple={false}
                    fileList={[]}
                    customRequest={e => getLoader(e, "images", "file", type, updateData)}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{i.t('def:actions.upload')}</p>
                </Upload.Dragger>
            </Col>
            <Col span={24} className="gutter-row">
                {photo &&
                    <>
                        <label className="all-label">{i.t('def:image.image')}</label>
                        <div className="product-item_picture">
                            <img src={ photo } alt="" />
                            {/*{id > 0 &&*/}
                            {/*    <DeleteImage path={photo} id={id} type="banner" />*/}
                            {/*}*/}
                        </div>
                    </>
                }
            </Col>
        </>
    )
})

export default ConstructorImageHelper