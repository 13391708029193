import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import _promo from "../controllers/promo.controller"
import TemplateTitle from "../../../template/title/TemplateTitle";
import {Space, Table} from "antd";
import ButtonAction from "../../../template/buttons/ButtonsActions";
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"

const ShopPromoList = observer(() => {

    useEffect(() => {
        _promo.getCoupons()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Код промокода",
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: "Лимит использований",
            dataIndex: 'countall',
            key: 'countall',
            render: item => item === 0 ? "Не ограничено" : item
        },
        {
            title: "Осталось использований",
            dataIndex: 'countall',
            key: 'countall',
            render: (item, record) => item === 0 ? "Не ограничено" : record.count
        },
        {
            title: "Начало действия",
            dataIndex: 'datestart',
            key: 'datestart',
            render: (item) => item !== 0 ? newDateTimeHelper(item) : ""
        },
        {
            title: "Окончание действия",
            dataIndex: 'dateend',
            key: 'dateend',
            render: (item) => item !== 0 ? newDateTimeHelper(item) : ""
        },
        {
            title: "Операции",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <ButtonAction
                            item={{
                                type: "statistics",
                                typeButton: "link",
                                placeholder: "Статистика",
                                url: `/shop/promo/${record.id}/statistics`
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "edit",
                                typeButton: "link",
                                placeholder: "Редактировать",
                                url: "/shop/promo/" + record.id
                            }}
                        />
                        <ButtonAction
                            item={{
                                type: "delete",
                                typeButton: "button",
                                placeholder: "Удалить",
                                onClick: () => _promo.deleteCoupon(record.id)
                            }}
                        />
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <TemplateTitle
                title="Купоны"
                breadcrumb={_promo.breadcrumb}
                buttons={[
                    { title: "Создать", type: "link", link: "/shop/promo/0" }
                ]}
            />

            <Table columns={columns} dataSource={_promo.coupons} pagination={false} />
        </>
    )
})

export default ShopPromoList