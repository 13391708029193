import { observer } from "mobx-react-lite";
import products from "../../../store/shop/products";
import React, {useEffect} from "react";
import {Breadcrumb, Button, Col, Image, Row, Space, Switch, Table, Tooltip, Typography, Input, InputNumber, Pagination} from "antd";
import {CopyOutlined, DeleteOutlined, DownOutlined, SearchOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons";
import {useTranslation, withTranslation} from "react-i18next";
import {useQueryParams} from "hookrouter"

const SearchProducts = observer((query) => {
    const { t } = useTranslation();
    const [queryParams] = useQueryParams();

    const {q = ''} = queryParams;

    useEffect(() => {
        if(queryParams && queryParams.name) {
            products.onChange("name", queryParams.name)
        } else {
            products.getProductsByFilter();
        }

    }, [])

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:picture'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('categories:active'),
            key: 'active',
            render: record => (
                <Switch size="small" onChange={(e) => products.editActiveProduct(record.id, e)} checked={record.view === 1 ? "checked" : ""} />
            )
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('categories:edit')}>
                            <Button
                                type="dashed"
                                shape="circle"
                                href={"/shop/catalog/product/" + record.id}
                                target={"_blank"}
                                icon={<SettingOutlined />}
                            />
                        </Tooltip>
                        <Tooltip title={t('categories:delete')}>
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => products.deleteProduct(record.id, t('categories:confirmDelete'))}
                            />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Typography.Title>{t('search:searchProducts')}</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" href={"/shop/catalog/product/create"}>{t('categories:createProduct')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('categories:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('categories:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop/catalog"}>{t('categories:catalog')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('search:searchProducts')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row justify="space-around" align="middle" gutter={[8, 8]}>
                <Col span={16}>
                    <Input
                        placeholder={t('search:changeName')}
                        value={products.search.name}
                        onChange={(e) => products.onChange("name", e.target.value)}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        placeholder={t('search:idProduct')}
                        value={products.search.id}
                        style={{ width: 100 + "%" }}
                        onChange={(e) => products.onChange("id", e)}
                    />
                </Col>
                <Col span={4}>
                    <InputNumber
                        placeholder={t('search:article')}
                        value={products.search.id_crm}
                        style={{ width: 100 + "%" }}
                        onChange={(e) => products.onChange("id_crm", e)}
                    />
                </Col>
            </Row>
            <Table columns={columnsProducts} dataSource={products.products} pagination={false} />
            <br/><br/>
            <Pagination
                defaultCurrent={products.pagination.page}
                total={products.pagination.all}
                showSizeChanger={false}
                onChange={e => products.onClickPage(e)}
                pageSize={products.limit}
                current={products.page}
            />
        </>
    )
});

export default withTranslation()(SearchProducts);