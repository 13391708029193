import { observer } from "mobx-react-lite"
import shopProductController from "../controllers/productController"
import { useTranslation } from "react-i18next";
import {Row, Col, Table, Image, Switch, Space, Tooltip, Button} from "antd";
import pc from "../../../../properties/controllers/properties.controller";
import {CopyOutlined, DeleteOutlined, DownOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import React from "react";

const ShopProductFilterSubs = observer(({ items }) => {

    const { t } = useTranslation();

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <p>{ text }</p>
                    <p className="template-propertyList">
                        {pc.product.length > 0 && pc.product.map(item =>
                            <span key={"filter-item-" + item.id}>
                                {record.filtervalue[item.id] &&
                                    <>
                                        <span className="element">
                                            <span>{pc.product.find(elem => elem.id === item.id).name}: </span>
                                            {record.filtervalue[item.id].map(el =>
                                                <>

                                                        <span>{pc.product.find(elem => elem.id === item.id).filtervalue.find(it => it.id === el).value} </span>

                                                </>
                                            )}
                                        </span>
                                    </>
                                }
                            </span>
                        )}
                    </p>
                </>
            )
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('categories:edit')}>
                            <a href={"/shop/catalog/product/" + record.id}>
                                <Button type="dashed" shape="circle" icon={<SettingOutlined />} />
                            </a>
                        </Tooltip>
                        <Tooltip title={t('categories:delete')}>
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => shopProductController.deleteProductSub(record.id)}
                            />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    return(
        <Row>
            <Col span={24}>
                <Table columns={columnsProducts} dataSource={ items } pagination={false} />
            </Col>
        </Row>
    )
})

const ShopProductFilter = observer(() => {

    const { t } = useTranslation();

    const confirmCreateSub = (e) => {
        e.preventDefault()
        if(shopProductController.product.viewvariation === 0 && shopProductController.product.filtervalueid.length > 0) {
            if(window.confirm("Данное действие удалит все текущие фильтры у товара. Вы уверены?")) {
                shopProductController.createSub(shopProductController.product.id)
            }
        } else {
            shopProductController.createSub(shopProductController.product.id)
        }
    }

    return(
        <>
            <Row>
                <Col span={24}>
                    {shopProductController.product.isvariant === 0 &&
                        <>
                            <button onClick={confirmCreateSub} className="button-main_no-active-little active">Создать вариант товара</button>
                            <br/><br/>
                        </>
                    }

                    {shopProductController.product.viewvariation > 0 && shopProductController.product.sub && shopProductController.product.sub[0] && shopProductController.product.sub[0].length > 0 &&
                        <ShopProductFilterSubs items={ shopProductController.product.sub[0] } />
                    }
                    {shopProductController.product.viewvariation === 0 &&
                        <>
                            <p>Выберите характеристики</p>

                            {pc.product.length > 0 && pc.product.map(item =>
                                <>
                                    {item.filtervalue && item.filtervalue.length > 0 &&
                                    <Row>
                                        <Col span={24}>
                                            <p className="product-filters-title">{ item.name }</p>
                                            <p className="product-filters-elements">
                                                {item.filtervalue.map(el =>
                                                    <span
                                                        onClick={() => shopProductController.setFilters(el.id)}
                                                        className={shopProductController.product.filtervalueid.find(elem => elem === el.id) ? "active" : ""}
                                                    >
                                                            { el.value }
                                                        </span>
                                                )}
                                            </p>
                                        </Col>
                                    </Row>
                                    }
                                </>
                            )}
                        </>
                    }
                </Col>
            </Row>
        </>
    )
})

export default ShopProductFilter