import React from "react"
import { observer } from 'mobx-react-lite'
import TemplateTitle from '../template/title/TemplateTitle';
import {Row, Col, Button, Input, Select} from "antd"
import pushesController from './push.controller'

export const PushNotification = observer(() => {

    return(
        <>
            <TemplateTitle
                title="Отправка PUSH - уведомления"
            />

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <label>На какие устройства отправить сообщение?</label><br/>
                    <Select
                        style={{ width: '100%' }}
                        options={[
                            {label: "Всем", value: 0},
                            {label: "IOS", value: 1},
                            {label: "Android", value: 2}
                        ]}
                        value={pushesController.push_create.devices}
                        onChange={(e) => pushesController.push_create.devices = e}
                    />
                </Col>
                <Col span={24}>
                    <label>Заголовок</label><br/>
                    <Input
                        placeholder="Заголовок"
                        value={pushesController.push_create.title}
                        onChange={(e) => pushesController.push_create.title = e.target.value}
                    />
                </Col>
                <Col span={24}>
                    <label>Текст сообщения</label><br/>
                    <Input
                        placeholder="Текст"
                        value={pushesController.push_create.body}
                        onChange={(e) => pushesController.push_create.body = e.target.value}
                    />
                </Col>
                <Col span={24}>
                    <Button onClick={() => pushesController.send()} disabled={pushesController.dis}>
                        {pushesController.dis ? "Выполняется отправка... подождите..." : "Отправить сообщение"}
                    </Button>
                </Col>
            </Row>
        </>
    )
})