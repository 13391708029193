import React, {useState, useEffect} from 'react';
import {
    Row,
    Col,
    Button,
    Tooltip,
    Space,
    Modal
} from 'antd';

import { withTranslation } from 'react-i18next';

import HeaderMenu from "./HeaderMenu";
import HeaderSubMenu from "./HeaderSubMenu";
import { LogoutOutlined, SettingOutlined, TranslationOutlined } from '@ant-design/icons';
import Logo from "../images/saleskit.svg";
import i18n from "i18next";
import store from "store"

function Header({ t }) {

    const [local, setLocal] = useState({
        display: false,
        local: 'ru'
    });

    useEffect(() => {
        if(localStorage.language && localStorage.language !== "") {
            setLocal({
                ...local,
                local: localStorage.language
            });
        }
    }, []);

    const updateLocalisation = (local) => {
        i18n.changeLanguage(local);
        setLocal({ display: false, local: local });
        localStorage.language = local;
    };

    const closeModal = () => {
        setLocal({
            ...local,
            display: false
        });
    };

    const userLogout = () => {
        store.clearAll();
        document.location.href = "/";
    };

    return(
        <>
            {store.get("user") && store.get("user").active && Number(store.get("user").active) === 1 &&
                <>
                    <Row justify="space-around" align="middle" className="header">
                        <Col span={1} className="header-logo">
                            <a href="/"><img src={Logo} alt="" /></a>
                        </Col>
                        <Col span={11}>
                            <HeaderMenu />
                        </Col>
                        <Col span={4} className="header-icons">
                            <Space>
                                <Tooltip title={t('header:template.tooltip.language')}>
                                    <Button
                                        type="dashed"
                                        shape="round"
                                        className="header-language"
                                        icon={<TranslationOutlined />}
                                        onClick={() => setLocal({ ...local, display: true })}
                                    >
                                        {i18n.language}
                                    </Button>
                                </Tooltip>
                                <Tooltip title={t('header:template.tooltip.setting')}>
                                    <Button type="dashed" shape="circle" icon={<SettingOutlined />} />
                                </Tooltip>
                                <Tooltip title={t('header:template.tooltip.logout')}>
                                    <Button type="dashed" shape="circle" onClick={userLogout} icon={<LogoutOutlined />} />
                                </Tooltip>
                            </Space>
                        </Col>
                        <HeaderSubMenu />
                    </Row>
                </>
            }
            <Modal visible={local.display} footer={null} centered={true} onCancel={closeModal}>
                <div className="template-language">
                    <button onClick={() => updateLocalisation('ru')} className={local.local === "ru" ? "active" : ""}>Русский</button>
                    <button onClick={() => updateLocalisation('en')} className={local.local === "en" ? "active" : ""}>English</button>
                    <button onClick={() => updateLocalisation('ua')} className={local.local === "ua" ? "active" : ""}>Український</button>
                </div>
            </Modal>
        </>
    )
}

export default withTranslation()(Header);