import React, {useState, useEffect} from "react";
import axios from "axios";
import {notification, Tooltip, Button, Table, Row, Col, Typography, Image, Space, Breadcrumb, Pagination, Switch, Input, Empty} from 'antd';
import { UpOutlined, DownOutlined, SettingOutlined, DeleteOutlined, UnorderedListOutlined, CopyOutlined, PicLeftOutlined, FileImageOutlined } from '@ant-design/icons';
import {useTranslation, withTranslation} from "react-i18next";
import { SearchOutlined } from '@ant-design/icons';
import instance from "../../../../store/axios";
import { Link, useParams } from "react-router-dom";
import _post from "../../../../modules/shop/posts/controllers/_post";
import Posts from "../../../../modules/shop/posts/modules/Post";
import PostsByProduct from "../../../../modules/shop/posts/modules/PostsByProduct";
import {observer} from "mobx-react-lite";
import {BannersByProduct} from '../../../../modules/shop/banners/Banners';
import __banners from '../../../../modules/shop/banners/banners.controller';
const { Title } = Typography;

const Categories = observer(() => {

    let { categoryID } = useParams();

    const { t } = useTranslation();
    const [update, setUpdate] = useState(0);
    const [offset, setOffset] = useState(1);

    const [category, setCategory] = useState({
        category: null,
        offset: 0,
        categoryID: categoryID ? Number(categoryID) : 0,
        type: null,
        pagination: null,
        breadcrumb: [],
    });

    useEffect(() => {
        instance.get('/shop/categorys/' + category.categoryID + '/?page=' + offset).then(response => {
            console.log(response);
            if(response.data.data.type === "product") {
                setCategory({
                    ...category,
                    type: response.data.data.type,
                    category: response.data.data.items,
                    pagination: response.data.data.pagination,
                    breadcrumb: response.data.data.breadcrumb
                });
            } else if(response.data.data.type === "category") {
                setCategory({
                    ...category,
                    type: response.data.data.type,
                    category: response.data.data.items,
                    pagination: response.data.data.pagination,
                    breadcrumb: response.data.data.breadcrumb
                });
            }
        });
    }, [update]);

    function deleteElement(array) {
        setCategory({
            ...category,
            category: array
        });
    }

    const deleteClick = (id, type) => {
        if (window.confirm(t('categories:confirmElement'))) {
            instance.delete('/shop/category/' + id + '/').then(response => {
                if(response.data.code === 200){
                    notification.success({
                        placement: "bottomLeft",
                        message: t('categories:success'),
                        description: t('categories:deleteSuccess'),
                    });
                    deleteElement(category.category.filter(el => el.id !== id))
                }
            })
        }
    };

    const positionClick = (id, type, action) => {
        let facts = new FormData();
        facts.append("type", type);
        facts.append("action", action);

        instance.post('/shop/position/' + id + '/', facts)
            .then(response => {
                console.log(response);
                setUpdate(update + 1);
        })
    };

    function deleteProduct(id) {
        if (window.confirm(t('categories:confirmDelete'))) {
            instance.delete('/shop/product/' + id + '/').then(response => {
                if(response.data.code === 200){
                    notification.success({
                        placement: "bottomLeft",
                        message: t('categories:success'),
                        description: t('categories:deleteSuccess'),
                    });
                    deleteElement(category.category.filter(el => el.id !== id))
                }
            })
        }
    }

    const columns = [
        {
            title: t('categories:picture'),
            dataIndex: 'picture',
            key: 'picture',
            render: (text, record) => {
                let picture;
                if(text !== null) {
                    picture = JSON.parse(text);

                    return(
                        <>
                            {record.picture !== "" &&
                            <>
                                <Image
                                    width={'100px'}
                                    src={picture['path']}
                                />
                            </>
                            }
                        </>
                    )
                }


            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:active'),
            key: 'active',
            render: record => (
                <Switch size="small" onChange={(e) => editActiveCategory(record.id, e)} checked={record.view === 1 ? "checked" : ""} />
            )
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('categories:view')}><Button type="dashed" shape="circle" icon={<UnorderedListOutlined />} href={"/shop/catalog/category/" + record.id} /></Tooltip>
                        <Tooltip title={t('categories:top')}><Button type="dashed" shape="circle" icon={<UpOutlined />} onClick={() => positionClick(record.id, 'category', 'bottom')} /></Tooltip>
                        <Tooltip title={t('categories:bottom')}><Button type="dashed" shape="circle" icon={<DownOutlined />} onClick={() => positionClick(record.id, 'category', 'top')} /></Tooltip>
                        <Tooltip title={t('categories:edit')}><Button type="dashed" shape="circle" href={"/shop/catalog/category/edit/" + record.id} icon={<SettingOutlined />} /></Tooltip>
                        <Tooltip title={t('categories:delete')}><Button type="dashed" shape="circle" icon={<DeleteOutlined />} danger onClick={() => deleteClick(record.id, 'category')} /></Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const editActiveProduct = (id, value) => {
        let active = 1;
        if(value === false) {
            active = 0;
        }
        let data = new FormData();
        data.append('view', active);

        instance.post('/shop/product/' + id + '/', data)
            .then(response => {
                console.log(response);
                setUpdate(update + 1);
            });
    };

    const editActiveCategory = (id, value) => {
        let active = 1;
        if(value === false) {
            active = 0;
        }
        let data = new FormData();
        data.append('view', active);

        instance.post('/shop/category/' + id + '/', data)
            .then(response => {
                console.log(response);
                setUpdate(update + 1);
            });
    };

    const cloneProduct = (productID) => {
        if (window.confirm(t('categories:confirmClone'))) {
            instance.post("/shop/cloneproduct/" + productID)
                .then(success => {
                    console.log(success);
                    document.location.href = "/shop/catalog/product/" + success.data.id;
                });
        }
    };

    const columnsProducts = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:picture'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('categories:active'),
            key: 'active',
            render: record => (
                <Switch size="small" onChange={(e) => editActiveProduct(record.id, e)} checked={record.view === 1 ? "checked" : ""} />
            )
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title="Банеры">
                            <Button
                                type="dashed"
                                shape="circle"
                                icon={<FileImageOutlined />}
                                onClick={() => __banners.getBannersByProductID(record.id)}
                            />
                        </Tooltip>
                        <Tooltip title="Посты"><Button type="dashed" shape="circle" icon={<PicLeftOutlined />} onClick={() => _post.getListByProductID(record.id)} /></Tooltip>
                        <Tooltip title={t('categories:top')}><Button type="dashed" shape="circle" icon={<UpOutlined />} onClick={() => positionClick(record.id, 'product', 'bottom')} /></Tooltip>
                        <Tooltip title={t('categories:bottom')}><Button type="dashed" shape="circle" icon={<DownOutlined />} onClick={() => positionClick(record.id, 'product', 'top')} /></Tooltip>
                        <Tooltip title={t('categories:clone')}>
                            <Button type="dashed" shape="circle" icon={<CopyOutlined />} onClick={() => cloneProduct(record.id)} />
                        </Tooltip>
                        <Tooltip title={t('categories:edit')}>
                            <Link to={"/shop/catalog/product/" + record.id}>
                                <Button type="dashed" shape="circle" icon={<SettingOutlined />} />
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('categories:delete')}><Button type="dashed" shape="circle" icon={<DeleteOutlined />} danger onClick={() => deleteProduct(record.id, 'product')} /></Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const handlePagination = (value) => {
        setOffset(value);
        setUpdate(update + 1);
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('categories:catalog')}</Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="dashed" href={"/shop/search"} icon={<SearchOutlined />}>
                            {t('search:searchProducts')}
                        </Button>
                        {category.type === null &&
                        <>
                            <Link to={"/shop/catalog/category/create/" + category.categoryID}>
                                <Button type="primary">{t('categories:createCategory')}</Button>
                            </Link>
                            <Link to={"/shop/catalog/product/create/" + category.categoryID}>
                                <Button type="primary">{t('categories:createProduct')}</Button>
                            </Link>
                        </>
                        }
                        {category.type === "category" &&
                            <Button type="primary" href={"/shop/catalog/category/create/" + category.categoryID}>{t('categories:createCategory')}</Button>
                        }
                        {category.type === "product" &&
                            <Button type="primary" href={"/shop/catalog/product/create/" + category.categoryID}>{t('categories:createProduct')}</Button>
                        }
                    </Space>
                </Col>
                {/*<Col span={24} className="template-search">*/}
                {/*    <Input.Search placeholder="Поиск товаров" enterButton />*/}
                {/*</Col>*/}
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('categories:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('categories:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop/catalog"}>{t('categories:catalog')}</Breadcrumb.Item>
                        {category.breadcrumb.map(item => (
                                <Breadcrumb.Item href={"/shop/catalog/category/" + item[0]}>{item[1]}</Breadcrumb.Item>
                        ))}
                        <Breadcrumb.Item>{t('categories:inCategory')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {category.type === "product" &&
                <Table columns={columnsProducts} dataSource={category.category} pagination={false} />
            }
            {category.type === "category" &&
                <Table columns={columns} dataSource={category.category} pagination={false} />
            }
            {category.category === null &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
            {category.pagination !== null &&
                <>
                    <br/>
                    <Pagination
                        defaultCurrent={category.pagination[0]}
                        total={Number(category.pagination[1])}
                        showSizeChanger={false}
                        onChange={handlePagination}
                    />
                </>
            }

            {_post.visibleList && <PostsByProduct />}
            {__banners.visible && <BannersByProduct />}
            {_post.visible && <Posts />}
        </>
    )
})

export default withTranslation()(Categories);