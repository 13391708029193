import axios from "axios";
import store from "store";

const HOST_PRODUCTION = process.env.REACT_APP_HOST_PRODUCTION;
const APIKEY = process.env.REACT_APP_APIKEY;

const sender = axios.create({
    baseURL: HOST_PRODUCTION ? HOST_PRODUCTION : "https://devprod.saleskit.biz/api/v1",
    headers: {
        "Authorizations": APIKEY,
        "bandl": store.get("project") && store.get("project").bandl !== "" ? store.get("project").bandl : "",
        "Type-Device": store.get("project") && store.get("project").bandl !== "" ? "react_" + store.get("project").bandl : "react"
    }
});

export default sender;