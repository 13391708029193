import { observer } from "mobx-react-lite";
import { YMaps, Map, Polygon, FullscreenControl, ZoomControl } from 'react-yandex-maps';
import {Space, Button, Modal, Input, InputNumber, Table} from "antd"
import zoneStore from "../../store/shop/zoneStore";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";

const ZoneDelivery = observer(({ t }) => {

    useEffect(() => {
        zoneStore.getAllZone()
    }, [])

    const draw = ref => {
        ref.editor.startEditing();
    };

    const columns = [
        {
            title: t('delivery:zones.name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('delivery:zones.price'),
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: t('delivery:zones.minPrice'),
            dataIndex: 'minprice',
            key: 'minprice',
        },
        {
            title: t('delivery:zones.maxPrice'),
            dataIndex: 'maxprice',
            key: 'maxprice',
        },
        {
            title: t('delivery:zones.actions'),
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => zoneStore.edit(record.id)}
                    />
                    <Button
                        type="dashed"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => zoneStore.deleteByID(record.id)}
                        danger
                    />
                </Space>
            ),
        },
    ];

    const optionsActive = {
        fillColor: '#00FF00',
        strokeColor: '#0000FF',
        opacity: 0.5,
        strokeWidth: 5,
        strokeStyle: 'shortdash',
        draggable: true
    };

    const optionsAll = {
        fillColor: '#000000',
        strokeColor: '#000000',
        opacity: 0.5,
        strokeWidth: 5,
        strokeStyle: 'shortdash'
    };

    return(
        <>
            <YMaps
                version={'2.1'}
                query={{
                    apikey: "50d6500a-1178-4721-9006-de55e27a6dc2",
                }}
            >
                <div className="zone">
                    <div className="buttons">
                        <Space>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => zoneStore.openModal()}
                                disabled={zoneStore.active.id > -1 ? "disabled" : ""}
                            >
                                {t('delivery:zones.create')}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                danger
                                onClick={() => zoneStore.delete()}
                                disabled={zoneStore.active.id > -1 ? "" : "disabled"}
                            >
                                {t('delivery:zones.delete')}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => zoneStore.saveActive()}
                                disabled={zoneStore.active.id > -1 ? "" : "disabled"}
                            >
                                {t('delivery:zones.save')}
                            </Button>
                        </Space>
                    </div>
                    <Map
                        state={{ center: zoneStore.center, zoom: zoneStore.zoom }}
                        width={100 + "%"}
                        height={600}
                        modules={["geoObject.addon.editor", "geolocation", "geocode"]}
                        onLoad={ymaps => zoneStore.locationUser(ymaps)}
                        onBoundsChange={e => zoneStore.onChangeMap(e)}
                    >
                        <FullscreenControl />
                        <ZoomControl options={{ float: 'right' }} />
                        {zoneStore.active.id > -1 &&
                            <Polygon
                                geometry={[zoneStore.active.coordinates]}
                                options={optionsActive}
                                onGeometryChange={e => zoneStore.onChange(e)}
                                instanceRef={ref => ref && draw(ref)}
                            />
                        }
                        {zoneStore.list.map(item =>
                            <>
                                {zoneStore.active.id !== item.id &&
                                    <Polygon
                                        geometry={[item.coordinates]}
                                        options={optionsAll}
                                        onClick={() => zoneStore.select(item.id)}
                                    />
                                }
                            </>
                        )}
                    </Map>
                </div>
            </YMaps>
            <Modal
                visible={zoneStore.modal}
                title={t('delivery:zones.edit')}
                onCancel={() => zoneStore.closeModal()}
                footer={null}
                centered={true}
            >
                <form onSubmit={e => zoneStore.saveDataZone(e)}>
                    <h3>{t('delivery:zones.nameZone')}</h3>
                    <Input
                        value={zoneStore.active.name}
                        onChange={e => zoneStore.onChangeInput("name", e.target.value)}
                        placeholder={t('delivery:zones.nameZone')}
                        required
                    />
                    <br/><br/>
                    <h3>{t('delivery:zones.minPrice')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={zoneStore.active.minprice}
                        onChange={e => zoneStore.onChangeInput("minprice", e)}
                        placeholder={t('delivery:zones.minPrice')}
                        min={0}
                    />
                    <br/><br/>
                    <h3>{t('delivery:zones.maxPrice')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={zoneStore.active.maxprice}
                        onChange={e => zoneStore.onChangeInput("maxprice", e)}
                        placeholder={t('delivery:zones.maxPrice')}
                        min={0}
                    />
                    <br/><br/>
                    <h3>{t('delivery:zones.price')}</h3>
                    <InputNumber
                        style={{
                            width: 100 + "%"
                        }}
                        value={zoneStore.active.price}
                        onChange={e => zoneStore.onChangeInput("price", e)}
                        placeholder={t('delivery:zones.price')}
                        min={0}
                    />
                    <br/><br/>
                    <Space>
                        <Button
                            htmlType="submit"
                        >
                            {t('delivery:zones.map')}
                        </Button>
                        {zoneStore.active.id > 0 &&
                            <Button type="primary" onClick={() => zoneStore.saveActive()}>{t('delivery:save')}</Button>
                        }
                    </Space>
                </form>
            </Modal>

            <br/><br/>
            <Table columns={columns} dataSource={zoneStore.list} />
        </>
    )
});

export default withTranslation()(ZoneDelivery);