import {observer} from "mobx-react-lite";
import formController from "./form.controller"
import {useTranslation} from "react-i18next";
import HeaderDefault from "../../template/header/HeaderDefault";
import {Button, Col, Row, Upload} from "antd";
import React from "react";
import store from "store";
import {InboxOutlined} from "@ant-design/icons";

import variant from "./variants/variant.json"
import product from "./variants/product.json"
import title from "./variants/title.json"
import loaderController from "../../template/loader/loader.controller";
import LoaderPage from "../../template/loader/LoaderPage";
import authorizationController from "../authorization/authorization.controller";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

const UserQuestionTitle = observer(({ h1, h4=false }) => {
    const { t } = useTranslation();

    return(
        <>
            <h1>{ h1 }</h1>
            {h4 && <h4>{h4}</h4>}
            {formController.question > 0 && <span className="step">{t('form:step')} {formController.question} {t('form:of')} 7</span>}
        </>
    )
})

const UserQuestionOne = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="middle" className="authorization-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:setting')} h4={t('form:form')} />

                        <div className="template-form">
                            <Row gutter={[10, 10]}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Button
                                        type="primary"
                                        block
                                        onClick={() => formController.onChangeQuestion(1)}
                                    >
                                        {t('form:continue')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionTwo = observer(() => {
    const { t } = useTranslation();

    const propsUpload = {
        name: 'file',
        multiple: false,
        action: store.get("project") && store.get("project").baseURL !== ""
            ? store.get("project").baseURL + '/shop/upload/'
            : HOST + '/shop/upload/',
        headers: {
            Authorizations: APIKEY,
            bandl: store.get("user").bandl
        },
        data: {
            path: store.get("user").id + '/logotype',
        },
        accept: ".cdr,.eps,.ai,.sketch",
        onChange(info) {
            console.log(info);
            const { status } = info.file;
            if (status === 'done') {
                formController.onChangeData("logo", info.file.response.data.url)
            }
        },
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="authorization-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:main')} />

                        <div className="template-form">
                            <form onSubmit={e => formController.onSubmitTwo(e)}>
                                <input
                                    type="text"
                                    placeholder={t('form:name')}
                                    value={formController.data.name}
                                    onChange={e => formController.onChangeData("name", e.target.value)}
                                    required
                                    autoFocus
                                />
                                <div className="drag">
                                    <Upload.Dragger {...propsUpload}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">{t('form:logo')}</p>
                                        <p className="ant-upload-hint">
                                            .cdr, .eps, .ai, .sketch
                                        </p>
                                    </Upload.Dragger>
                                </div>

                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                        <Button
                                            type="primary"
                                            block
                                            htmlType="submit"
                                        >
                                            {t('form:nextStep')}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionThree = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 23 }}
                    md={{ span: 23 }}
                    lg={{ span: 23 }}
                    xl={{ span: 23 }}
                    xxl={{ span: 22 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:category')} />

                        <Row justify="space-around" align="middle" className="examples">
                            {variant.map(item =>
                                <Col
                                    xs={{ span: 12 }}
                                    sm={{ span: 12 }}
                                    md={{ span: 8 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                    xxl={{ span: 4 }}
                                >
                                    <label>
                                        <img
                                            src={item.url}
                                            alt=""
                                            className={item.key === formController.data.variant ? "preview-select" : "preview"}
                                            onClick={() => formController.onChangeData("variant", item.key)}
                                        />
                                    </label>
                                </Col>
                            )}
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onChangeQuestion(3)}
                                >
                                    {t('form:nextStep')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionFour = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 23 }}
                    md={{ span: 23 }}
                    lg={{ span: 23 }}
                    xl={{ span: 23 }}
                    xxl={{ span: 22 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:product')} />

                        <Row justify="space-around" align="middle" className="examples">
                            {product.map(item =>
                                <Col
                                    xs={{ span: 12 }}
                                    sm={{ span: 12 }}
                                    md={{ span: 8 }}
                                    lg={{ span: 4 }}
                                    xl={{ span: 4 }}
                                    xxl={{ span: 4 }}
                                >
                                    <label>
                                        <img
                                            src={item.url}
                                            alt=""
                                            className={item.key === formController.data.variantProduct ? "preview-select" : "preview"}
                                            onClick={() => formController.onChangeData("variantProduct", item.key)}
                                        />
                                    </label>
                                </Col>
                            )}
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onChangeQuestion(4)}
                                >
                                    {t('form:nextStep')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionFive = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:product')} />

                        <Row justify="space-around" align="middle" className="examples">
                            {title.map(item =>
                                <Col span={12}>
                                    <label>
                                        <img
                                            src={item.url}
                                            alt=""
                                            className={item.key === formController.data.variantTitle ? "preview-select" : "preview"}
                                            onClick={() => formController.onChangeData("variantTitle", item.key)}
                                        />
                                    </label>
                                </Col>
                            )}
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onChangeQuestion(5)}
                                >
                                    {t('form:nextStep')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionSix = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22 }}
                    md={{ span: 20 }}
                    lg={{ span: 18 }}
                    xl={{ span: 16 }}
                    xxl={{ span: 14 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:modules')} />

                        <Row justify="space-around" className="examples" gutter={[20, 20]}>
                            <Col span={8}>
                                <div
                                    className={formController.data.search === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("search")}
                                >
                                    <p>{t('form:search')}</p>
                                    <span>{t('form:searchDescription')}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div
                                    className={formController.data.favourite === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("favourite")}
                                >
                                    <p>{t('form:favorites')}</p>
                                    <span>{t('form:favoritesDescription')}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div
                                    className={formController.data.sortby === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("sortby")}
                                >
                                    <p>{t('form:sort')}</p>
                                    <span>{t('form:sortDescription')}</span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onChangeQuestion(6)}
                                >
                                    {t('form:nextStep')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionSeven = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 18 }}
                    lg={{ span: 16 }}
                    xl={{ span: 14 }}
                    xxl={{ span: 12 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:delivery')} />

                        <Row justify="space-around" className="examples" gutter={[20, 20]}>
                            <Col span={12}>
                                <div
                                    className={formController.data.sam === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("sam")}
                                >
                                    <p>{t('form:pickup')}</p>
                                    <span>{t('form:pickupDescription')}</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div
                                    className={formController.data.kur === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("kur")}
                                >
                                    <p>{t('form:express')}</p>
                                    <span>{t('form:expressDescription')}</span>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onChangeQuestion(7)}
                                >
                                    {t('form:nextStep')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})
const UserQuestionEight = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 8 }}
                >
                    <div className="authorization">
                        <UserQuestionTitle h1={t('form:pay')} />

                        <Row justify="space-around" className="examples" gutter={[20, 20]}>
                            <Col span={24}>
                                <div
                                    className={formController.data.onlineCard === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("onlineCard")}
                                >
                                    <p>{t('form:online')}</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.apple === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("apple")}
                                >
                                    <p>Apple Pay</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.google === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("google")}
                                >
                                    <p>Google Pay</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.samsung === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("samsung")}
                                >
                                    <p>Samsung Pay</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.cash === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("cash")}
                                >
                                    <p>{t('form:cash')}</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.curierCard === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("curierCard")}
                                >
                                    <p>{t('form:cart')}</p>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div
                                    className={formController.data.schet === "1" ? "select-module selected" : "select-module"}
                                    onClick={() => formController.onChangeModule("schet")}
                                >
                                    <p>{t('form:score')}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={[10, 10]}>
                            <Col span={24}>
                                {formController.error !== "" &&
                                <p>{formController.error}</p>
                                }
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => formController.onSubmit()}
                                >
                                    {t('form:finish')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    )
})

const UserForm = observer(() => {
    const { t } = useTranslation();

    return(
        <>
            <HeaderDefault />
            {formController.question === 0 && <UserQuestionOne />}
            {formController.question === 1 && <UserQuestionTwo />}
            {formController.question === 2 && <UserQuestionThree />}
            {formController.question === 3 && <UserQuestionFour />}
            {formController.question === 4 && <UserQuestionFive />}
            {formController.question === 5 && <UserQuestionSix />}
            {formController.question === 6 && <UserQuestionSeven />}
            {formController.question === 7 && <UserQuestionEight />}
        </>
    )
})

export default UserForm