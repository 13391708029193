import { makeAutoObservable } from 'mobx'
import sender from '../template/controllers/sender';

class pushesController {

    push_create = {
        title: '',
        body: '',
        devices: 0,
    }

    dis = false

    constructor() {
        makeAutoObservable(this)
    }

    send() {
        this.dis = true
        if(!window.confirm("Вы уверены, что хотите отправить сообщение?")) return

        console.log('Отправляем')

        const data = new FormData()
        data.append('title', this.push_create.title)
        data.append('body', this.push_create.body)
        data.append('devices', this.push_create.devices)

        sender.post(`/notifications/push`, data)
            .then(result => {
                console.log('Сообщение успешно отправлено')
                alert('Сообщение успешно отправлено')
                this.push_create = {
                    title: '',
                    body: '',
                    devices: 0,
                }
                this.dis = false
            })
            .catch(error => {
                console.log('Ошибка')
                console.log(error)
            })
    }

}

export default new pushesController()