import { observer } from "mobx-react-lite";
import TemplateTitle from "../../../../template/title/TemplateTitle";
import {Upload, Col, Input, Row, Tag} from "antd";
import _post from "./controllers/_post";
import Editor from "../../../../editor/Editor";
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import getLoader from "../../../../../store/uploadFiles.controller";
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom";
import _editor from "../../../../editor/_editor";
import shopProductController from "./controllers/productController";

const EditPost = observer(() => {

    const { postID, productID } = useParams();

    const [inputVisible, setInputVisible] = useState(false)

    const showInput = () => {
        setInputVisible(true);
    }

    const updateData = (value) => {
        let data = value && value.path ? value : {}
        _post.onChange("photo", data)
    }

    useEffect(() => {
        if(postID && Number(postID) > 0) {
            _post.getPost(Number(postID))
        }
        if(productID && Number(productID) > 0) {
            _post.onChange("productid", Number(productID))
        }
    }, [])

    return(
        <>
            <form onSubmit={(event) => _post.onSubmit(event)}>
                <TemplateTitle
                    title="Создание поста"
                    buttons={[
                        { type: "submit", title: "Сохранить", primary: true }
                    ]}
                />

                <Row>
                    <Col span={8} className="product-input_block left">
                        <h3>Наименование</h3>
                        <br/>
                        <Input
                            name="name"
                            value={ _post.data.name }
                            onChange={ (e) => _post.onChange("name", e.target.value) }
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <br/><br/>
                        <h3>Обложка</h3>
                        <br/><br/>
                        {_post.data.photo && _post.data.photo.path &&
                            <>
                                <img src={_post.data.photo.path} alt="" width={`200px`}/>
                            </>
                        }
                        <Upload.Dragger
                            multiple={true}
                            fileList={[]}
                            customRequest={e => getLoader(e, "images", "file", "product", updateData)}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Перетащите изображение</p>
                            <p className="ant-upload-hint">
                                или выберите со своего пк
                            </p>
                        </Upload.Dragger>
                    </Col>
                    <Col span={24}>
                        <h3>Хэштеги</h3>
                        {_post.data.hashtag && _post.data.hashtag.map(el =>
                            <Tag
                                closable
                                onClose={() => _post.deleteTag(el.id)}
                            >
                                {el.name}
                            </Tag>
                        )}

                        {_post.new_tag_visible && (
                            <Input
                                type="text"
                                size="small"
                                className="tag-input"
                                value={_post.new_tag_value}
                                onChange={(e) => _post.onChangeValueNewTag(e.target.value)}
                                onBlur={(e) => _post.onChangeNewTag(e.target.value)}
                                // onPressEnter={handleInputConfirm}
                                style={{ width: 78 }}
                            />
                        )}
                        {!_post.new_tag_visible && (
                            <Tag className="site-tag-plus" onClick={() => _post.onChangeVisibleNewTag(true)}>
                                <PlusOutlined /> New Tag
                            </Tag>
                        )}
                    </Col>
                    <Col span={24}>
                        <br/><br/>
                        <h3>Текст</h3>
                        <br/><br/>
                        <Editor />
                    </Col>
                </Row>
            </form>
        </>
    )
})

export default EditPost