import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Button, Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import orderController from "../order.controller";
import React, {useEffect} from "react";
import {SettingOutlined} from "@ant-design/icons";
import loaderController from "../../../template/loader/loader.controller";
import LoaderPage from "../../../template/loader/LoaderPage";
import TemplateTitle from "../../../template/title/TemplateTitle";
import TemplateList from "../../../template/list/TemplateList";

const OrderOrders = observer(() => {
    const { t } = useTranslation();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('orders:status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                let status_text;
                let status_color;
                if(text === 'accepted') {
                    status_text = t('orders:accepted');
                    status_color = 'green';
                } else if(text === 'new') {
                    status_text = t('orders:new');
                    status_color = 'blue';
                } else if(text === 'rejected') {
                    status_text = t('orders:rejected');
                    status_color = 'red';
                }
                return(
                    <>
                        {text.id && text.id > 0 ? <Tag color={"#" + text.colorhex}>{text.name}</Tag> : <Tag color={status_color}>{status_text}</Tag>}
                    </>
                )
            }
        },
        {
            title: t('orders:client'),
            dataIndex: 'userid',
            key: 'userid',
            render: text => (
                <a href={"/users/" + text}>{text}</a>
            )
        },
        {
            title: t('orders:price'),
            dataIndex: 'summ',
            key: 'summ',
        },
        {
            title: t('orders:statusPay'),
            dataIndex: 'paystatus',
            key: 'paystatus'
        },
        {
            title: t('orders:created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: t('orders:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('orders:view')}><Button type="dashed" shape="circle" href={"/shop/orders/" + record.id} icon={<SettingOutlined />} /></Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    useEffect(() => {
        loaderController.onChange(true)
        orderController.getOrders()
    }, [])

    const breadcrumb = [
        { link: "/shop", value: t('orders:shop') },
        { link: null, value: t('orders:orders') }
    ]

    return(
        <>
            <TemplateTitle
                title={t('orders:orders')}
                breadcrumb={breadcrumb}
            />

            <Table columns={columns} dataSource={orderController.orders} pagination={false} defaultPageSize={orderController.pagination[2]} />
            <br/>
            <Pagination
                defaultCurrent={orderController.pagination[0]}
                total={orderController.pagination[1]}
                showSizeChanger={false}
                onChange={(e) => orderController.setOffset(e)}
            />
            {loaderController.load && <LoaderPage />}
        </>
    )
})

export default OrderOrders