import { observer } from "mobx-react-lite"
import shopProductController from "../controllers/productController"
import {useTranslation} from "react-i18next";
import {
    Button,
    Col,
    Image,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Space,
    Table,
    Tooltip
} from "antd";
import {DeleteOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";
import products from "../../../../../../store/shop/products";

const ShopProductWith = observer(() => {

    const { t } = useTranslation();

    const deleteFrom = (productID, type) => {
        if(type === "similar") {
            shopProductController.deleteFromSimilar(productID);
        } else if(type === "with") {
            shopProductController.deleteFromWith(productID);
        }
        if(shopProductController.product.id && shopProductController.product.id > 0) {
            shopProductController.saveProduct()
        }

    };

    const saveSimilarAndWith = () => {
        shopProductController.closeSimilarModal();
        shopProductController.closeWithModal();
        if(shopProductController.product.id && shopProductController.product.id > 0) {
            shopProductController.saveProduct()
        }
    };

    const columnsProductsWith = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:picture'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('categories:edit')}>
                            {shopProductController.withArray.length > 0 && shopProductController.withArray.find(el => Number(el) === Number(record.id))
                                ? <Button
                                    type="dashed"
                                    shape="circle"
                                    danger
                                    onClick={() => shopProductController.deleteFromWith(record.id)}
                                    icon={<MinusOutlined />}
                                />
                                : <Button
                                    type="dashed"
                                    shape="circle"
                                    onClick={() => shopProductController.addToWith(record.id)}
                                    icon={<PlusOutlined />}
                                />
                            }

                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const tableWith = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: t('categories:idCrm'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: t('categories:picture'),
            dataIndex: 'photo',
            key: 'photo',
            render: text => {
                if(text !== "[]") {
                    text = JSON.parse(text);
                    return(
                        <>
                            <Image
                                width={'100px'}
                                src={text[0].path}
                            />
                        </>
                    )
                }
            },
        },
        {
            title: t('categories:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('categories:price'),
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: t('categories:count'),
            dataIndex: 'count',
            key: 'count'
        },
        {
            title: t('categories:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => deleteFrom(record.id, "with")}
                        />
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <Row>
                <Col span={24} className="product-input_block">
                    <p>{t('product:with.rule')}:</p>
                    <Radio.Group
                        options={[
                            { label: t('product:nowCategory'), value: '0' },
                            { label: t('product:selectCategory'), value: 'category' },
                            { label: t('product:bind'), value: 'product' }
                        ]}
                        onChange={(e) => shopProductController.setWithType(e.target.value)}
                        value={shopProductController.withType}
                        optionType="button"
                    />
                </Col>
                {shopProductController.withType === "category" &&
                <>
                    <Select
                        placeholder={t('product:notChosen')}
                        style={{ width: 100 + "%" }}
                        value={shopProductController.withCategory}
                        onChange={(e) => shopProductController.setWithCategory(e)}
                    >
                        {shopProductController.product.categoryslist.map(item => (
                            <Select.Option value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </>
                }
                {shopProductController.withType === "product" &&
                <>
                    <Col span={24} className="product-input_block">
                        <Button type="primary" onClick={() => shopProductController.openWithModal()}>{t('product:typeAdd')}</Button>
                    </Col>
                    <Col span={24} className="product-input_block">
                        <Table columns={tableWith} dataSource={shopProductController.withProducts} pagination={false} />
                    </Col>
                </>
                }
            </Row>

            <Modal
                title={t('product:with.modal')}
                visible={shopProductController.displayWith}
                onCancel={saveSimilarAndWith}
                centered={true}
                onOk={saveSimilarAndWith}
                width={1000}
            >
                <Row justify="space-around" align="middle" gutter={[8, 8]}>
                    <Col span={16}>
                        <Input
                            placeholder={t('search:changeName')}
                            value={shopProductController.search.name}
                            onChange={(e) => shopProductController.onChange("name", e.target.value)}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            placeholder={t('search:idProduct')}
                            value={shopProductController.search.id}
                            style={{ width: 100 + "%" }}
                            onChange={(e) => shopProductController.onChange("id", e)}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            placeholder={t('search:article')}
                            value={shopProductController.search.id_crm}
                            style={{ width: 100 + "%" }}
                            onChange={(e) => shopProductController.onChange("id_crm", e)}
                        />
                    </Col>
                </Row>
                <Table columns={columnsProductsWith} dataSource={shopProductController.products} pagination={false} />
                <br/><br/>
                <Pagination
                    defaultCurrent={shopProductController.pagination.page}
                    total={shopProductController.pagination.all}
                    showSizeChanger={false}
                    onChange={e => shopProductController.onClickPage(e)}
                    pageSize={shopProductController.limit}
                    current={shopProductController.page}
                />
            </Modal>
        </>
    )
})

export default ShopProductWith