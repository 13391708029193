import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import MetaTags from "react-meta-tags";
import HeaderDefault from "../../template/header/HeaderDefault";
import {Button, Checkbox, Col, Row} from "antd";
import registrationController from "./registration.controller"
import React, {useEffect} from "react";
import loaderController from "../../template/loader/loader.controller";
import LoaderPage from "../../template/loader/LoaderPage";

const UserRegistration = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        let arr = document.cookie.split(";")
        arr.map(item => {
            let element = item.split("=")
            if(element[0] === " ref") {
                registrationController.setRef(element[1])
            }
        })
    }, [])

    return(
        <>
            <MetaTags>
                <title>{t('register:title')}</title>
                <meta name="description" content={t('register:title')} />
                <meta property="og:title" content={t('register:title')} />
            </MetaTags>
            <HeaderDefault />
            <Row justify="space-around" align="top" className="registration-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                    <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                        {registrationController.error !== "" &&
                        <p className="message-error">{registrationController.error}</p>
                        }
                        <div className={registrationController.error !== "" ? "authorization-figure-one-error" : "authorization-figure-one"}>

                            <svg width="470" height="398" viewBox="0 0 470 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M79.7667 128.493L261.49 342.988C278.895 365.211 312.64 366.804 334.686 348.745C336.436 347.311 337.641 346.08 337.641 346.08L430.651 252.021C450.616 231.132 451.584 198.804 431.886 177.363C428.096 173.535 427.663 172.9 419.743 167.424L161.272 26.4607C139.232 13.4979 110.962 20.5788 94.8108 41.4462L79.432 60.7702C63.2806 81.6377 64.6312 109.336 79.7667 128.493Z" fill="#D6F1F9" />
                            </svg>
                        </div>
                    </Col>

                    <div className="authorization">
                        <h1>{t('register:register')}</h1>
                        <h5>{t('register:contacts')}</h5>

                        <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                            {registrationController.error !== "" &&
                            <p>{registrationController.error}</p>
                            }
                        </Col>

                        <div className="template-form">
                            <form onSubmit={e => registrationController.onSubmit(e)}>
                                <Row gutter={[30, 10]}>
                                    <Col span={24}>
                                        <input
                                            type="text"
                                            placeholder="ФИО"
                                            onChange={e => registrationController.onChangeData("name", e.target.value)}
                                            value={registrationController.data.name}
                                            required
                                            autoFocus
                                        />
                                    </Col>
                                    {/*<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        placeholder={t('register:surname')}*/}
                                    {/*        onChange={e => registrationController.onChangeData("surname", e.target.value)}*/}
                                    {/*        value={registrationController.data.surname}*/}
                                    {/*        required*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    {/*<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        placeholder={t('register:middleName')}*/}
                                    {/*        onChange={e => registrationController.onChangeData("patronymic", e.target.value)}*/}
                                    {/*        value={registrationController.data.patronymic}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                    <Col span={24}>
                                        <input
                                            type="text"
                                            placeholder={t('register:phone')}
                                            onChange={e => registrationController.onChangeData("phone", e.target.value)}
                                            value={registrationController.data.phone}
                                            required
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <input
                                            type="email"
                                            placeholder="E-mail"
                                            onChange={e => registrationController.onChangeData("email", e.target.value)}
                                            value={registrationController.data.email}
                                            required
                                        />
                                    </Col>
                                    {/*<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        placeholder={t('register:linkSite')}*/}
                                    {/*        onChange={e => registrationController.onChangeData("site", e.target.value)}*/}
                                    {/*        value={registrationController.data.site}*/}
                                    {/*    />*/}
                                    {/*</Col>*/}
                                </Row>
                                {/*<h5>{t('register:company')}</h5>*/}
                                {/*<Row gutter={[30, 10]}>*/}
                                {/*    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                {/*        <Checkbox onChange={e => registrationController.onChangeData("type", e.target.checked)}>{t('register:entity')}</Checkbox>*/}
                                {/*    </Col>*/}
                                {/*    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                {/*        <input*/}
                                {/*            type="text"*/}
                                {/*            placeholder={t('register:companyName')}*/}
                                {/*            onChange={e => registrationController.onChangeData("company", e.target.value)}*/}
                                {/*            value={registrationController.data.company}*/}
                                {/*            disabled={registrationController.data.type ? '' : 'disabled'}*/}
                                {/*            required={registrationController.data.type ? 'required' : ''}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>*/}
                                {/*        <input*/}
                                {/*            type="text"*/}
                                {/*            placeholder={t('register:inn')}*/}
                                {/*            onChange={e => registrationController.onChangeData("inn", e.target.value)}*/}
                                {/*            value={registrationController.data.inn}*/}
                                {/*            disabled={registrationController.data.type ? '' : 'disabled'}*/}
                                {/*            required={registrationController.data.type ? 'required' : ''}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <h5>Пароль</h5>
                                <Row gutter={[30, 10]}>
                                    <Col span={24}>
                                        <input
                                            type="password"
                                            placeholder={t('register:password')}
                                            onChange={e => registrationController.onChangeData("password", e.target.value)}
                                            value={registrationController.data.password}
                                            required
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <input
                                            type="password"
                                            placeholder={t('register:confirmPassword')}
                                            onChange={e => registrationController.onChangeData("confirmpassword", e.target.value)}
                                            value={registrationController.data.confirmpassword}
                                            required
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox
                                            onChange={e => registrationController.onChangeData("checked", e.target.checked)}
                                            checked={registrationController.data.checked}
                                            required
                                        >
                                            Согласен с условиями <a href="https://saleskit.biz/contract" target="_blank">
                                                договора-оферты
                                            </a>
                                        </Checkbox>
                                    </Col>
                                </Row>
                                <Row gutter={[10, 10]}>
                                    <Col span={24}>
                                        <Button type="primary" block htmlType="submit">{t('register:registered')}</Button>
                                    </Col>
                                    <Col span={24}>
                                        <a href="/signin" className="authorization-forgot">Авторизация</a>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
            {loaderController.load && <LoaderPage />}
        </>
    )
})

export default UserRegistration