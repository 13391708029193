import React, {useState, useEffect} from "react";
import {Button, Col, notification, Row, Typography, Input, Switch, Space, Breadcrumb} from 'antd';
import axios from "axios";
import { Editor } from 'primereact/editor';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import {useTranslation, withTranslation} from "react-i18next";
import instance from "../../store/axios";
import {useParams} from "react-router-dom";

const { Title } = Typography;
const { TextArea } = Input;

function PageDetail() {
    let { pageID } = useParams();
    const { t } = useTranslation();

    const [page, setPage] = useState({
        id: null,
        text: '',
        anotation: '',
        name: '',
        active: 1,
    });

    useEffect(() => {
        let page_id = 0;
        if(pageID && pageID > 0){
            page_id = Number(pageID);
            instance.get('/info/pages/' + page_id + '/').then(response => {
                setPage({
                    ...page,
                    id: response.data.data.id,
                    text: response.data.data.text,
                    anotation: response.data.data.anotation,
                    name: response.data.data.name,
                    active: response.data.data.active,
                });
            })
        }
    }, [pageID]);


    function sendForm() {
        let facts = new FormData();
        facts.append('id', page.id);
        facts.append('text', page.text);
        facts.append('anotation', page.anotation);
        facts.append('name', page.name);
        facts.append('active', page.active);
        console.log(page);
        instance.post('/info/pages/' + page.id + '/', facts).then(response => {
            if(response.data.code === 201){
                document.location.href="/pages";
            }else if(response.data.code === 200){
                setPage({
                    ...page,
                    id: response.data.data.id,
                    text: response.data.data.text,
                    anotation: response.data.data.anotation,
                    name: response.data.data.name,
                    active: response.data.data.active,
                });
                notification.success({
                    placement: "bottomLeft",
                    message: "Успешно",
                    description: t('page:success'),
                });
            }
        })
    }

    const switchChecked = (checked) => {
        if(checked) {
            setPage({ ...page, active: 1 });
        } else {
            setPage({ ...page, active: 0 });
        }
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('page:editPage')}</Title>
                </Col>
                <Col span={8} align="right">
                    <Space>
                        <Button type="primary" onClick={sendForm}>{t('page:save')}</Button>
                        <Button type="dashed" href={"/pages"}>{t('page:list')}</Button>
                    </Space>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('page:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/pages"}>{t('page:pages')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('page:editPage')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row justify="space-around">
                <Col span={24}>
                    <Switch
                        onChange={switchChecked}
                        checked={page.active > 0 ? 'checked' : ''}
                    />
                    <label className="label-inline" htmlFor="active">
                        {t('page:view')}
                    </label>
                </Col>
                <Col span={24}>
                    <label htmlFor="input1" className="all-label">{t('page:name')}</label>
                    <Input
                        name="name"
                        value={page.name}
                        onChange={e => setPage({ ...page, name: e.target.value }) }
                    />
                </Col>
                <Col span={24}>
                    <div className="form-group">
                        <label className="all-label">{t('page:previewText')}</label>
                        <TextArea
                            rows={4}
                            value={page.anotation}
                            onChange={e => setPage({ ...page, anotation: e.target.value }) }
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <label htmlFor="text" className="all-label">{t('page:description')}</label>
                    <Editor
                        style={{height:'320px'}}
                        value={page.text}
                        onTextChange={(e) => setPage({ ...page, text: e.htmlValue })}
                    />
                </Col>
            </Row>
        </>
    )
}

export default withTranslation()(PageDetail);