import {Row, Col, Button, Space, Tooltip, Modal} from 'antd';
import logo from '../images/saleskit.svg'
import {UserAddOutlined, LoginOutlined, LogoutOutlined, TranslationOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import i18n from "i18next";
import {useTranslation, withTranslation} from "react-i18next";

function HeaderAuth(props) {

    const { t } = useTranslation();

    const [local, setLocal] = useState({
        display: false,
        local: 'ru'
    });

    useEffect(() => {
        if(localStorage.language && localStorage.language !== "") {
            setLocal({
                ...local,
                local: localStorage.language
            });
        }
        console.log(localStorage.language);
    }, []);

    const updateLocalisation = (local) => {
        i18n.changeLanguage(local);
        setLocal({ display: false, local: local });
        localStorage.language = local;
    };

    const closeModal = () => {
        setLocal({
            ...local,
            display: false
        });
    };

    return(
        <>
            <Row className="header-auth" justify="space-around" align="middle">
                <Col span={12} className="forLogo">
                    <a href="/"><img src={logo} alt="SalesKit" className="logo" /></a>
                </Col>
                <Col span={12} className="right header-icons">
                    <Space>
                            <Button
                                type="dashed"
                                shape="round"
                                className="header-language"
                                icon={<TranslationOutlined />}
                                onClick={() => setLocal({ ...local, display: true })}
                            >
                                {i18n.language}
                            </Button>
                        {props.signin &&
                        <Button type="dashed" shape="round" icon={<LoginOutlined />} href="/signin">{t('header:template.tooltip.auth')}</Button>
                        }
                        {props.logout &&
                            <Tooltip title={t('header:template.tooltip.logout')}>
                                <Button type="dashed" shape="circle" href="/logout" icon={<LogoutOutlined/>}/>
                            </Tooltip>
                        }
                    </Space>
                </Col>
            </Row>
            <Modal visible={local.display} footer={null} centered={true} onCancel={closeModal}>
                <div className="template-language">
                    <button onClick={() => updateLocalisation('ru')} className={local.local === "ru" ? "active" : ""}>Русский</button>
                    <button onClick={() => updateLocalisation('en')} className={local.local === "en" ? "active" : ""}>English</button>
                    <button onClick={() => updateLocalisation('ua')} className={local.local === "ua" ? "active" : ""}>Український</button>
                </div>
            </Modal>
        </>
    )
}

export default withTranslation()(HeaderAuth);