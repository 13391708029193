import { makeAutoObservable, makeObservable } from "mobx";
import {message, notification} from "antd";
import instance from "../axios";

class zoneStore {

    list = [];

    center = [55.75, 37.57];
    zoom = 13;
    active = {
        id: -1,
        name: "",
        price: null,
        minprice: null,
        maxprice: null,
        coordinates: []
    }

    modal = false;

    constructor() {
        makeAutoObservable(this);
    }

    onChangeMap(e) {
        console.log(e.get('target').getCenter());
        this.setCenter(e.get('target').getCenter())
        this.zoom = e.get('target')._zoom;
    }

    openModal() {
        this.modal = true;
    }

    closeModal() {
        this.modal = false;
    }

    locationUser(ymaps) {
        let _this = this;
        ymaps.geolocation.get({
            provider: 'browser',
            mapStateAutoApply: true
        }).then(function (result) {
            console.log(result.geoObjects.get(0).geometry.getCoordinates());
            _this.setCenter(result.geoObjects.get(0).geometry.getCoordinates());
        });
    }

    getAllZone() {
        instance.get("/shop/zone")
            .then(success => {
                console.log(success);
                this.list = success.data[0]
            })
            .catch(error => console.log(error));
    }

    setCenter(coordinates) {
        console.log("Есть координаты");
        console.log(coordinates);
        this.center = coordinates;
    }

    addNewZone() {
        // let coordinates = [
        //     [this.center[0] + 0.1, this.center[1] - 0.1],
        //     [this.center[0] + 0.1, this.center[1] - 0.2],
        //     [this.center[0], this.center[1] - 0.3],
        //     [this.center[0] - 0.1, this.center[1] - 0.2],
        //     [this.center[0] - 0.1, this.center[1] - 0.1],
        //     [this.center[0], this.center[1]]
        // ];

        let item = 0.1 - (this.zoom / 100);

        console.log(this.center);
        let coordinates = [
            [this.center[0], this.center[1]],
            [this.center[0], this.center[1] - item],
            [this.center[0] - item, this.center[1] - item],
            [this.center[0] - item, this.center[1]]
        ];

        this.active.coordinates = coordinates;
        this.active.id = 0;
    }

    clearActive() {
        this.active = {
            id: -1,
            name: "",
            price: null,
            minprice: null,
            maxprice: null,
            coordinates: []
        }
    }

    saveActive() {

        let facts = new FormData();
        facts.append("name", this.active.name);
        facts.append("price", this.active.price);
        facts.append("minprice", this.active.minprice);
        facts.append("maxprice", this.active.maxprice);
        facts.append("coordinates", JSON.stringify(this.active.coordinates));

        // Запрос на сохранение
        let url = "/shop/zone";

        if(this.active.id > 0) {
            url = url + "/" + this.active.id;
        }

        instance.post(url, facts)
            .then(success => {
                this.clearActive()
                this.getAllZone()
                this.modal = false;
            })
            .catch(error => console.log(error));


    }

    onChange(e) {
        console.log(e.get("target").geometry._coordPath._coordinates[0])
        this.active.coordinates = e.get("target").geometry._coordPath._coordinates[0];
    }

    select(id) {
        if(this.active.id > -1) {
            message.error("Сохраните или удалите активную зону");
        } else {
            this.active = this.list.find(el => el.id === id);
        }
    }

    deleteByID(ID) {
        instance.delete("/shop/zone/" + ID)
            .then(success => {
                console.log(success);
                this.getAllZone()
            })
            .catch(error => console.log(error));
    }

    edit(ID) {
        this.active = {
            id: this.list.find(el => el.id === ID).id,
            name: this.list.find(el => el.id === ID).name,
            price: this.list.find(el => el.id === ID).price,
            minprice: this.list.find(el => el.id === ID).minprice,
            maxprice: this.list.find(el => el.id === ID).maxprice,
            coordinates: this.list.find(el => el.id === ID).coordinates
        }
        this.modal = true;
    }

    delete() {
        if(this.active.id > 0) {
            this.deleteByID(this.active.id)
            this.clearActive();
        } else {
            this.clearActive();
        }
    }

    saveDataZone(e) {
        e.preventDefault()
        this.closeModal()
        if(this.active.id > 0) {
            this.setCenter(this.active.coordinates[0]);
        }
        this.zoom = 13;
        if(this.active.id < 1) {
            this.addNewZone()
        }
    }

    onChangeInput(name, value) {
        this.active[name] = value;
    }

}

export default new zoneStore();