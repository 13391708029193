import {makeAutoObservable} from "mobx";
import instance from "../../../../store/axios";
import moment from "moment";

class intervalController {
    constructor() {
        makeAutoObservable(this)
    }

    interval = {
        viewintervaldelivery: false,
        timestartdelivery: 0,
        timeenddelivery: 0,
        timeinterval: 0,
        viewintervalpickup: false,
        timestartpickup: 0,
        timeendpickup: 0,
        timeintervalpickup: 0
    }

    intervalOrder = {
        viewintervaldelivery: false,
        timestartdelivery: 0,
        timeenddelivery: 0,
        timeinterval: 0,
        viewintervalpickup: false,
        timestartpickup: 0,
        timeendpickup: 0,
        timeintervalpickup: 0
    }

    getIntervalForOrder() {
        instance.get("/shop/intervaldelivery")
            .then(success => {
                console.log(success)
                this.intervalOrder = {
                    viewintervaldelivery: success.data.data.viewintervaldelivery,
                    timestartdelivery: success.data.data.timestartdelivery,
                    timeenddelivery: success.data.data.timeenddelivery,
                    timeinterval: success.data.data.timeinterval,
                    viewintervalpickup: success.data.data.viewintervalpickup,
                    timestartpickup: success.data.data.timestartpickup,
                    timeendpickup: success.data.data.timeendpickup,
                    timeintervalpickup: success.data.data.timeintervalpickup
                }
            })
            .catch(error => console.log(error))
    }

    getInterval() {
        instance.get("/shop/intervaldelivery")
            .then(success => {
                console.log(success)
                this.interval = {
                    viewintervaldelivery: success.data.data.viewintervaldelivery === 1 ? true : false,
                    timestartdelivery: this.convertFromSecond(success.data.data.timestartdelivery),
                    timeenddelivery: this.convertFromSecond(success.data.data.timeenddelivery),
                    timeinterval: success.data.data.timeinterval > 0 ? success.data.data.timeinterval/60 : 0,
                    viewintervalpickup: success.data.data.viewintervalpickup === 1 ? true : false,
                    timestartpickup: this.convertFromSecond(success.data.data.timestartpickup),
                    timeendpickup: this.convertFromSecond(success.data.data.timeendpickup),
                    timeintervalpickup: success.data.data.timeintervalpickup > 0 ? success.data.data.timeintervalpickup/60 : 0,
                }
            })
            .catch(error => console.log(error))
    }

    onChangeSwitch(name, value) {
        this.interval[name] = value
    }

    onChange(name, value) {
        this.interval[name] = value
    }

    onChangeTime(key, time, timeString) {
        console.log(timeString)
        let a = timeString.split(':');
        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
        console.log(seconds)
        this.interval[key] = timeString
    }

    convertToSeconds(time) {
        let startTime = time.split(':');
        return (+startTime[0]) * 60 * 60 + (+startTime[1]) * 60
    }

    convertFromSecond(time) {
        let hour = Math.floor(time/60/60)

        let minutesSeconds = time - (hour*60*60)

        return hour + ":" + minutesSeconds/60
    }

    onSubmit() {
        let facts = new FormData()

        facts.append("viewintervaldelivery", this.interval.viewintervaldelivery ? 1 : 0)
        facts.append("timestartdelivery", this.convertToSeconds(this.interval.timestartdelivery))
        facts.append("timeenddelivery", this.convertToSeconds(this.interval.timeenddelivery))
        facts.append("timeinterval", this.interval.timeinterval * 60)

        facts.append("viewintervalpickup", this.interval.viewintervalpickup ? 1 : 0)
        facts.append("timestartpickup", this.convertToSeconds(this.interval.timestartpickup))
        facts.append("timeendpickup", this.convertToSeconds(this.interval.timeendpickup))
        facts.append("timeintervalpickup", this.interval.timeintervalpickup * 60)

        instance.post("/shop/intervaldelivery", facts)
            .then(success => {
                console.log(success)
            })
            .catch(error => console.log(error.response.data))
    }

}

export default new intervalController()