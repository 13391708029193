import { observer } from "mobx-react-lite";
import { Checkbox, Col, Input, InputNumber, Row, Select } from "antd";
import {useTranslation} from "react-i18next";
import shopProductController from "../controllers/productController"
import { Editor } from 'primereact/editor'
import {runInAction} from 'mobx';

const ShopProductMain = observer(({ productID=0 }) => {

    const { t } = useTranslation();

    return(
        <>
            <Row>
                <Col span={24} className="product-input_block">
                    <Checkbox
                        checked={shopProductController.product.view === 1 ? 'selected' : ''}
                        onChange={ (e) => shopProductController.setProduct("view", e === true ? 1 : 0) }
                    >
                        {t('product:active')}
                    </Checkbox>
                </Col>
                <Col span={24} className="product-input_block">
                    <Checkbox
                        checked={shopProductController.product.addpost === 1 ? 'selected' : ''}
                        onChange={ (e) => shopProductController.setProduct("addpost", e === true ? 1 : 0) }
                    >
                        Разрешить создание постов
                    </Checkbox>
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input1">{t('product:nameProduct')}</label>
                    <Input
                        name="name"
                        value={ shopProductController.product.name }
                        onChange={ (e) => shopProductController.setProduct("name", e.target.value) }
                        required
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input1">{t('product:idCrm')}</label>
                    <Input
                        name="name"
                        value={ shopProductController.product.id_crm }
                        onChange={ (e) => shopProductController.setProduct("id_crm", e.target.value) }
                    />
                </Col>
                <Col span={8} className="product-input_block">
                    <label htmlFor="parentid">{t('product:parent')}</label>
                    <Select
                        value={shopProductController.product.parentid ? Number(shopProductController.product.parentid) : ""}
                        style={{ width: '100%' }}
                        onChange={ (e) => shopProductController.setProduct("parentid", e) }
                    >
                        {shopProductController.product.categoryslist.map(item => (
                            <Select.Option key={item.id} value={Number(item.id)}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input3">{t('product:oldPrice')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.priceold }
                        onChange={ (e) => shopProductController.setProduct("priceold", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="input2">{t('product:price')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.price }
                        onChange={ (e) => shopProductController.setProduct("price", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block">
                    <label htmlFor="count">Штрихкод</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.barcode }
                        onChange={ (e) => shopProductController.setProduct("barcode", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="count">{t('product:count')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={ shopProductController.product.count }
                        onChange={ (e) => shopProductController.setProduct("count", e) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label>{t('product:type')}</label>
                    <Select
                        placeholder={t('product:notSelected')}
                        onChange={ (e) => shopProductController.setProduct("type", e) }
                        style={{ width: '100%' }}
                        value={Number(shopProductController.product.type)}
                    >
                        <Select.Option key={"item0"} value={0}>{t('product:notSelected')}</Select.Option>
                        {shopProductController.product.statuses.map(item => (
                            <Select.Option key={item.id} value={Number(item.id)}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} className="product-input_block">
                    <label htmlFor="count">Цвет слева</label>
                    <Input
                        style={{ width: '100%' }}
                        value={ shopProductController.product.colorleft }
                        onChange={ (e) => shopProductController.setProduct("colorleft", e.target.value) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="count">Цвет по центру</label>
                    <Input
                        style={{ width: '100%' }}
                        value={ shopProductController.product.colorcenter }
                        onChange={ (e) => shopProductController.setProduct("colorcenter", e.target.value) }
                    />
                </Col>
                <Col span={8} className="product-input_block left">
                    <label htmlFor="count">Цвет справа</label>
                    <Input
                        style={{ width: '100%' }}
                        value={ shopProductController.product.colorright }
                        onChange={ (e) => shopProductController.setProduct("colorright", e.target.value) }
                    />
                </Col>
                <Col span={24} className="product-input_block left">
                    <label htmlFor="text">{t('product:description')}</label>
                    {/*<Input.TextArea*/}
                    {/*    value={shopProductController.product.text}*/}
                    {/*    onChange={ (e) => shopProductController.setProduct("text", e.target.value) }*/}
                    {/*    autoSize={{ minRows: 10, maxRows: 20 }}*/}
                    {/*/>*/}

                    <Editor
                        headerTemplate={ (
                            <span className="ql-formats">
                                <button className="ql-bold" aria-label="Bold"></button>
                                <button className="ql-italic" aria-label="Italic"></button>
                                <button className="ql-underline" aria-label="Underline"></button>
                            </span>
                        )}
                        style={{height:'320px'}}
                        value={shopProductController.product.text}
                        onTextChange={(e) => runInAction(() => shopProductController.product.text = e.htmlValue)}
                    />
                </Col>
            </Row>
        </>
    )
})

export default ShopProductMain