import {makeAutoObservable} from "mobx";
import instance from "../../../../store/axios";
import store from "store"
import sender from "../../../template/controllers/sender";

class dashboardController {
    constructor() {
        makeAutoObservable(this)
    }

    links = {
        ios: "",
        android: "",
        loading: true
    }

    getLinksProject() {
        if(store.get("project") && store.get("project").bandl) {
            sender.get("/admin/get/" + store.get("project").bandl)
                .then(success => {
                    console.log(success)
                    this.links = {
                        ios: success.data.urlios,
                        android: success.data.urlandroid
                    }
                })
                .catch(error => console.log(error.response.data))
        }
    }
}

export default new dashboardController()