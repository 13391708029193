import React, {useState, useEffect} from 'react';
import {
    Col,
    Menu, Row
} from 'antd';
import {withTranslation} from "react-i18next";

const nowPath = window.location.pathname;
const url = nowPath.split("/");

function HeaderSubMenu({t}) {

    const [key, setKey] = useState('');

    useEffect(() => {
        if(url[2] === '' || !url[2]) {
            setKey('catalog');
        } else {
            setKey(url[2]);
        }
    }, [url]);

    const subMenu = (
        <Col span={22} className="header-sub" align="left">
            <Menu mode="horizontal" selectedKeys={[key]}>
                <Menu.Item key="catalog">
                    <a href="/shop/catalog">{t('header:menu.submenu.shop.catalog')}</a>
                </Menu.Item>
                <Menu.Item key="search">
                    <a href="/shop/search">{t('header:menu.submenu.shop.search')}</a>
                </Menu.Item>
                <Menu.Item key="property">
                    <a href="/shop/property">{t('header:menu.submenu.shop.property')}</a>
                </Menu.Item>
                <Menu.Item key="status">
                    <a href="/shop/status">{t('header:menu.submenu.shop.status')}</a>
                </Menu.Item>
                <Menu.Item key="import">
                    <a href="/shop/import">{t('header:menu.submenu.shop.import')}</a>
                </Menu.Item>
                <Menu.Item key="orders">
                    <a href="/shop/orders">{t('header:menu.submenu.shop.orders')}</a>
                </Menu.Item>
                <Menu.Item key="points">
                    <a href="/shop/points">{t('header:menu.submenu.shop.points')}</a>
                </Menu.Item>
                <Menu.Item key="delivery">
                    <a href="/shop/delivery">{t('header:menu.submenu.shop.delivery')}</a>
                </Menu.Item>
            </Menu>
        </Col>
    );

    const subMenuNews = (
        <Col span={22} className="header-sub" align="left">
            <Menu mode="horizontal" selectedKeys={[key]}>
                <Menu.Item key="newsKist">
                    <a href="/news">{t('header:menu.submenu.info.news')}</a>
                </Menu.Item>
                <Menu.Item key="newsType">
                    <a href="/news/type">{t('header:menu.submenu.info.newsType')}</a>
                </Menu.Item>
            </Menu>
        </Col>
    );

    return(
        <>
            {url[1] === "shop" ? subMenu : '' }
            {url[1] === "news" ? subMenuNews : '' }
        </>
    )
}

export default withTranslation()(HeaderSubMenu);