import { makeAutoObservable, makeObservable } from "mobx";
import axios from "../axios";
import {message} from "antd";
import instance from "../axios";

class PointsStore {

    point = {
        id: 0,
        name: "",
        address: "",
        latitude: "",
        longitude: "",
        date1start: "",
        date1end: "",
        date2start: "",
        date2end: "",
        date3start: "",
        date3end: ""
    };

    phonesList = [];

    pointNewPhone = {
        phone: "",
        description: ""
    };

    constructor() {
        makeAutoObservable(this);
    }

    setPointByID(pointID) {
        instance.get('/shop/point/' + pointID + '/')
            .then(response => {
                console.log(response);
                let phone = [];
                if(response.data.data.phone !== "") {
                    let array = JSON.parse(response.data.data.phone);
                    let i = 1;
                    array.map(item => {
                        phone = [...phone, {
                            id: i,
                            phone: item.phone,
                            description: item.description
                        }];
                        i = i + 1;
                    });
                }
                this.phonesList = phone;
                this.point = {
                    id: response.data.data.id,
                    name: response.data.data.name,
                    latitude: response.data.data.latitude,
                    longitude: response.data.data.longitude,
                    address: response.data.data.address,
                    date1start: response.data.data.date1start,
                    date1end: response.data.data.date1end,
                    date2start: response.data.data.date2start,
                    date2end: response.data.data.date2end,
                    date3start: response.data.data.date3start,
                    date3end: response.data.data.date3end,
                    phone: phone,
                }
            })
            .catch(error => console.log(error.response))
    }

    savePoint(successText) {
        let data = new FormData();
        data.append('name', this.point.name);
        data.append('latitude', this.point.latitude);
        data.append('longitude', this.point.longitude);
        data.append('address', this.point.address);
        data.append('date1start', this.point.date1start);
        data.append('date1end', this.point.date1end);
        data.append('date2start', this.point.date2start);
        data.append('date2end', this.point.date2end);
        data.append('date3start', this.point.date3start);
        data.append('date3end', this.point.date3end);

        if(this.phonesList.length > 0) {
            let phonesArray = this.phonesList;
            if(this.pointNewPhone.phone !== "") {
                phonesArray = [...phonesArray, this.pointNewPhone];
            }

            data.append('phone', JSON.stringify(phonesArray));
        }

        if(this.pointNewPhone.phone !== "") {
            console.log(JSON.stringify(this.pointNewPhone));

        }

        instance.post('/shop/point/' + this.point.id + '/', data)
            .then(response => {
                if(response.data.code === 200) {
                    this.setPointByID(this.point.id);
                    message.success(successText);
                } else if (response.data.code === 201) {
                    document.location.href="/shop/points";
                }
            })
    }

    onChange(name, value) {
        this.point[name] = value;
    }

    onChangePhone(name, value, id=0) {
        if(id > 0) {
            this.phonesList.map(item => {
                if(item.id === id) {
                    item[name] = value;
                }
            });
        } else {
            this.pointNewPhone[name] = value;
        }
    }

    selectPhone(id=0) {
        if(id > 0) {
            let thisPhone = this.phonesList.find(el => el.id === id);
        } else {
            this.clearPhone();
        }
    }

    clearPhone() {
        this.pointNewPhone = {
            phone: "",
            description: ""
        };
    }

    addPhone() {
        let array = [];
        let i = 1;
        if(this.phonesList.length > 0) {
            this.phonesList.map(item => {
                array = [...array, {
                    id: i,
                    phone: item.phone,
                    description: item.description
                }];
                i = i + 1;
            });
        }
        if(this.pointNewPhone.phone !== "") {
            array = [...array, {
                id: i,
                phone: this.pointNewPhone.phone,
                description: this.pointNewPhone.description
            }];
            this.clearPhone();
        }
        this.phonesList = array;
    }

    deletePhone(id) {
        this.phonesList = this.phonesList.filter(el => el.id !== id);
    }

}

export default new PointsStore();