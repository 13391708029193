import { makeAutoObservable } from 'mobx'
import sender from '../../../../../template/controllers/sender';
import hashController from '../../../../../template/controllers/hash.controller';

class hashtag {

    constructor() {
        makeAutoObservable(this)
    }

    create(name) {
        const facts = new FormData()
        facts.append('name', name)
        facts.append("hash", hashController.setHash(facts))

        return sender.post('/post/hashtag', facts)
            .then(result => {
                return result.data.id
            })
            .catch(error => {
                throw new Error(error)
            })
    }

    isSet(name) {
        return sender.get(`/post/hashtags/?search=${name}`)
            .then(result => {
                if(result.data && result.data.items && result.data.items[0]) {
                    return result.data.items[0].id
                } else {
                    return this.create(name)
                        .then(result => {
                            return result
                        })
                        .catch(error => {
                            return 0
                        })
                }
            })
            .catch(error => {
                console.log(error)
                return 0
            })
    }
}

export default new hashtag()