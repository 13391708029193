import { observer } from "mobx-react-lite"
import { Tooltip, Button, Space, Switch } from "antd"
import {ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons"

const ConstructorActions = observer(({ actions=[] }) => {

    return(
        <Space>
            {actions.length > 0 && actions.map(item => (
                <Tooltip title={item.title} key={`space_tooltip_${item.name}`}>
                    {item.type === "active" &&
                        <Switch
                            size="small"
                            checked={item.value > 0}
                            onChange={(e, event) => {
                                item.refs(e ? 1 : 0)
                                event.stopPropagation()
                            }}
                        />
                    }
                    {item.type === "edit" && !item.disabled &&
                        <Button
                            size="small" shape="circle" type="dashed"
                            icon={<EditOutlined />}
                            onClick={(event) => {
                                event.stopPropagation()
                                item.refs()
                            }}
                        />
                    }
                    {item.type === "create" &&
                        <Button
                            size="small" shape="circle" type="dashed"
                            icon={<PlusOutlined />}
                            onClick={(event) => {
                                event.stopPropagation()
                                item.refs()
                            }}
                        />
                    }
                    {item.type === "moveDown" &&
                        <Button
                            shape="circle" size="small" type="dashed" icon={item.icon ? item.icon : <ArrowDownOutlined />}
                            onClick={(event) => {
                                event.stopPropagation()
                                item.refs()
                            }}
                        />
                    }
                    {item.type === "moveUp" &&
                        <Button
                            shape="circle" size="small" type="dashed" icon={item.icon ? item.icon : <ArrowUpOutlined />}
                            onClick={(event) => {
                                event.stopPropagation()
                                item.refs(item.id)
                            }}
                        />
                    }
                    {item.type === "delete" &&
                        <Button
                            shape="circle" size="small" type="dashed" danger icon={<DeleteOutlined />}
                            onClick={(event) => {
                                event.stopPropagation()
                                item.refs(item.id)
                            }}
                        />
                    }
                </Tooltip>
            ))}
        </Space>
    )
})

export default ConstructorActions