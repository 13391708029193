import {observer} from "mobx-react-lite";
import HeaderDefault from "../../template/header/HeaderDefault";
import {Col, Row} from "antd";

const UserDemo = observer(() => {

    return(
        <>
            <HeaderDefault />

            <Row justify="space-around" align="middle" className="authorization-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                    <div className="authorization">
                        <h1>Мы уже начали сборку Вашего приложения</h1>
                        <br/>
                        <h3>Сейчас Вы находитесь в демо режиме. Доступ к публикации приложения и наполнению контента ограничен.</h3>
                        <br/>
                        <p>В ближайшее время наш менеджер свяжется с Вами, активирует пробный период на 14 дней, расскажет все, что нужно знать и ответит на вопросы.</p>
                    </div>
                </Col>
            </Row>
        </>
    )
})

export default UserDemo