import {observer} from 'mobx-react-lite';
import controller from './banners.controller'
import React, {useEffect} from 'react';
import {Button, Col, DatePicker, Drawer, Image, Input, Row, Space, Table, Tag, Tooltip, Upload} from 'antd';
import {newDateTimeHelper} from '../../template/helpers/templateHelpers';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    InboxOutlined,
    PlusOutlined,
    SettingOutlined
} from '@ant-design/icons';
import _post from '../posts/controllers/_post';
import TemplateTitle from '../../template/title/TemplateTitle';
import {useParams} from 'react-router-dom';
import getLoader from '../../../store/uploadFiles.controller';
import Editor from '../../editor/Editor';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

export const Banners = observer(() => {

    useEffect(() => {
        controller.getList()
    }, [])

    const breadcrumb = [
        { link: "/shop", value: "Магазин" },
        { link: null, value: "Банеры" }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: (_this) => _this !== "" && _this !== null && _this.path ? <Image
                width={'100px'}
                src={_this.path}
            /> : ""
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Просмотр">
                        <Button type="dashed" shape="circle" href={`/banners/edit/${record.id}`} icon={<SettingOutlined />} />
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} onClick={() => controller.delete(record.id)} danger />
                    </Tooltip>
                </Space>
            ),
        },
    ]

    return(
        <>
            <TemplateTitle
                title="Список банеров"
                breadcrumb={breadcrumb}
            />

            <Table
                columns={columns}
                dataSource={controller.list}
                pagination={{
                    current: controller.page,
                    total: controller.count,
                    pageSize: controller.limit,
                    onShowSizeChange: (el, size) => controller.setLimit(size),
                    onChange: (page) => {
                        controller.page = page
                        controller.offset = page > 1 ? (page * controller.limit) - controller.limit : 0
                        controller.getList(controller.productId)
                    }
                }}
            />
        </>
    )
})

export const Banner = observer(() => {

    dayjs.extend(weekday)
    dayjs.extend(localeData)

    const { bannerID, productID } = useParams()

    const updateData = (value) => {
        console.log(value)
        controller.element.photo = value && value.path ? value : {}
    }

    useEffect(() => {
        if(bannerID && Number(bannerID) > 0) {
            controller.getBanner(Number(bannerID))
        }
        if(productID && Number(productID) > 0) {
            controller.element.productid = Number(productID)
        }
    }, [])

    return(
        <>
            <form onSubmit={(event) => controller.onSubmit(event)}>
                <TemplateTitle
                    title="Редактирование банера"
                    buttons={[
                        { type: "submit", title: "Сохранить", primary: true }
                    ]}
                />

                <Row>
                    <Col span={8} className="product-input_block left">
                        <h3>Наименование</h3>
                        <br/>
                        <Input
                            name="name"
                            value={controller.element.name}
                            onChange={(e) => controller.element.name = e.target.value}
                            required
                        />
                    </Col>
                    <Col span={4} className="product-input_block left">
                        <h3>Начало действия</h3>
                        <br/>
                        <DatePicker
                            value={controller.element.datestart > 0 && dayjs(controller.element.datestart * 1000, "DD.MM.YYYY")}
                            onChange={(e) => controller.element.datestart = dayjs(e).startOf('day').unix()}
                            format="DD.MM.YYYY"
                        />
                    </Col>
                    <Col span={4} className="product-input_block left">
                        <h3>Окончание действия</h3>
                        <br/>
                        <DatePicker
                            value={controller.element.dateend > 0 ? dayjs(controller.element.dateend*1000, "DD.MM.YYYY") : null}
                            onChange={(e) => controller.element.dateend = dayjs(e).endOf('day').unix()}
                            format="DD.MM.YYYY"
                        />
                    </Col>
                    <Col span={24}>
                        <br/><br/>
                        <h3>Обложка</h3>
                        <br/><br/>
                        {controller.element.photo && controller.element.photo.path &&
                            <>
                                <img src={controller.element.photo.path} alt="" width={`200px`}/>
                            </>
                        }
                        <Upload.Dragger
                            multiple={true}
                            fileList={[]}
                            customRequest={e => getLoader(e, "images", "file", "product", updateData)}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Перетащите изображение</p>
                            <p className="ant-upload-hint">
                                или выберите со своего пк
                            </p>
                        </Upload.Dragger>
                    </Col>
                    <Col span={24}>
                        <br/><br/>
                        <h3>Текст</h3>
                        <br/><br/>
                        <Editor />
                    </Col>
                </Row>
            </form>
        </>
    )
})

export const BannersByProduct = observer(() => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: (_this) => _this !== "" && _this !== null && _this.path ? <Image
                width={'100px'}
                src={_this.path}
            /> : ""
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Просмотр">
                        <Button type="dashed" shape="circle" href={`/banners/edit/${record.id}`} icon={<SettingOutlined />} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <Drawer
            title="Список банеров"
            placement="right"
            visible={controller.visible}
            onClose={() => controller.closeList()}
            width={50 + "%"}
        >
            <Button href={`/banners/create/${controller.productId}`} type="primary">Создать банер</Button>
            <Table
                columns={columns}
                dataSource={controller.list}
                pagination={{
                    current: controller.page,
                    total: controller.count,
                    pageSize: controller.limit,
                    onShowSizeChange: (el, size) => controller.setLimit(size, true),
                    onChange: (page) => {
                        controller.page = page
                        controller.offset = page > 1 ? (page * controller.limit) - controller.limit : 0
                        controller.getBannersByProductID(controller.productId)
                    }
                }}
            />
        </Drawer>
    )
})