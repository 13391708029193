import {observer} from "mobx-react-lite";
import {Breadcrumb, Col, Row, message, Button} from "antd";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./less/dashboard.less"
import {Link} from "react-router-dom";
import store from "store"
import { CopyOutlined } from '@ant-design/icons';
import dashboardController from "./controllers/dashboardController"


import iosIcon from "../../../images/ios.svg"
import androidIcon from "../../../images/android.svg"
import LoaderPage from "../../template/loader/LoaderPage";

const GetLink = ({ item }) => {

    const copyText = (text) => {
        navigator.clipboard.writeText(text)
        message.success('Ссылка скопирована в буфер обмена');
    }

    return(
        <Col span={24}>
            <Row justify="space-around" align="middle" className="dashboard-link">
                <Col
                    xs={{ span: 4 }}
                    sm={{ span: 4 }}
                    md={{ span: 5 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                    xxl={{ span: 4 }}
                >
                    <img src={item.icon} alt=""/>
                </Col>
                {
                    item.link === ""
                    ?   <Col
                            xs={{ span: 20 }}
                            sm={{ span: 20 }}
                            md={{ span: 19 }}
                            lg={{ span: 20 }}
                            xl={{ span: 20 }}
                            xxl={{ span: 20 }}
                        >
                            <p>Приложение еще не выпущено</p>
                        </Col>
                    : <>
                            <Col
                                xs={{ span: 16 }}
                                sm={{ span: 16 }}
                                md={{ span: 15 }}
                                lg={{ span: 16 }}
                                xl={{ span: 17 }}
                                xxl={{ span: 17 }}
                            >
                                <a href={ item.link } target="_blank">Перейти в { item.shop }</a>
                            </Col>
                            <Col
                                xs={{ span: 4 }}
                                sm={{ span: 4 }}
                                md={{ span: 4 }}
                                lg={{ span: 4 }}
                                xl={{ span: 3 }}
                                xxl={{ span: 3 }}
                                className="right"
                            >
                                <button
                                    onClick={() => copyText(item.link)}
                                >
                                    <CopyOutlined />
                                </button>
                            </Col>
                        </>
                }


            </Row>
        </Col>
    )
}

const GetLinks = observer(() => {

    const list = [
        { type: "ios", link: dashboardController.links.ios, icon: iosIcon, shop: "App Store" },
        { type: "android", link: dashboardController.links.android, icon: androidIcon, shop: "Google Play" }
    ]

    return(
        <Row>
            {list.map(item => <GetLink item={ item } /> )}
        </Row>
    )
})

const Dashboard = observer(() => {
    const { t } = useTranslation();

    const settings = [
        {
            active: true,
            title: "Ваши данные",
            description: "Измените свои личные данные или свой пароль",
            link: "/user"
        }
    ]

    useEffect(() => {

        dashboardController.getLinksProject()

        // instance.get("/project/bucket")
        //     .then(success => {
        //         console.log(success)
        //     })
    }, [])



    return(
        <>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('orders:main')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <h1>Рабочий стол</h1>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 14 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                    xl={{ span: 7 }}
                    xxl={{ span: 6 }}
                >
                    <div className="dashboard">
                        <h3>Ссылки на Ваше приложение</h3>
                        <GetLinks />

                        {dashboardController.links.loading && <LoaderPage classDefault="loader_local" />}
                    </div>
                </Col>
                {settings.map(item => (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                        xxl={{ span: 6 }}
                    >
                        <Link to={item.link} disabled={item.active ? "" : "disabled"}>
                            <div className={item.active ? "dashboard" : "dashboard dashboard-no-active"}>
                                <h3>{ item.title }</h3>
                                <p>{ item.description }</p>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
            {/*<Row gutter={[20, 20]}>*/}
            {/*    <Col*/}
            {/*        xs={{ span: 24 }}*/}
            {/*        lg={{ span: 16 }}*/}
            {/*        xl={{ span: 12 }}*/}
            {/*        xxl={{ span: 12 }}*/}
            {/*    >*/}
            {/*        <div className="dashboard">*/}
            {/*            <Row>*/}
            {/*                <Col span={24}>*/}
            {/*                    <h3>Дисковое пространство</h3>*/}
            {/*                </Col>*/}
            {/*                <Col span={12}>*/}
            {/*                    <Progress type="circle" status="exception" percent={75} format={percent => `${percent}% занято`} />*/}
            {/*                </Col>*/}
            {/*                <Col span={12}>*/}
            {/*                    <p>Всего: <strong>5 Гб</strong></p>*/}
            {/*                    <p>Свободно: <strong>3.2 Гб</strong></p>*/}

            {/*                    <Button type="primary">Увеличить</Button>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}


            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    )
})

export default Dashboard