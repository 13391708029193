import { observer } from "mobx-react-lite"
import { Row, Col, Button } from "antd"
import authorizationController from "./authorization.controller"
import "./authorization.less"
import HeaderDefault from "../../template/header/HeaderDefault";
import { useTranslation } from 'react-i18next';
import MetaTags from "react-meta-tags";
import loaderController from "../../template/loader/loader.controller"
import LoaderPage from "../../template/loader/LoaderPage";

const Authorization = observer(() => {

    const { t } = useTranslation();

    return(
        <>
            <MetaTags>
                <title>{t('signIn:title')}</title>
                <meta name="description" content={t('signIn:title')} />
                <meta property="og:title" content={t('signIn:title')} />
            </MetaTags>
            <HeaderDefault />
            <Row justify="space-around" align="middle" className="authorization-block">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 20 }}
                    md={{ span: 15 }}
                    lg={{ span: 11 }}
                    xl={{ span: 10 }}
                    xxl={{ span: 8 }}
                >
                        <Col xs={{ span: 0 }} sm={{ span: 24 }}>
                            {authorizationController.error !== "" &&
                                <p className="message-error">{authorizationController.error}</p>
                            }
                            <div className={authorizationController.error !== "" ? "authorization-figure-one-error" : "authorization-figure-one"}>

                                <svg width="470" height="398" viewBox="0 0 470 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M79.7667 128.493L261.49 342.988C278.895 365.211 312.64 366.804 334.686 348.745C336.436 347.311 337.641 346.08 337.641 346.08L430.651 252.021C450.616 231.132 451.584 198.804 431.886 177.363C428.096 173.535 427.663 172.9 419.743 167.424L161.272 26.4607C139.232 13.4979 110.962 20.5788 94.8108 41.4462L79.432 60.7702C63.2806 81.6377 64.6312 109.336 79.7667 128.493Z" fill="#D6F1F9" />
                                </svg>
                            </div>
                        </Col>

                    <div className="authorization">
                        <h1>{t('signIn:title')}</h1>

                        <Col xs={{ span: 24 }} sm={{ span: 0 }}>
                            {authorizationController.error !== "" &&
                            <p>{authorizationController.error}</p>
                            }
                        </Col>

                        <div className="template-form">
                            <form onSubmit={e => authorizationController.onSubmitForm(e)}>
                                <input
                                    type="email"
                                    placeholder="email@domain.com"
                                    onChange={e => authorizationController.onChangeData("email", e.target.value)}
                                    value={authorizationController.data.email}
                                    required
                                    autoFocus
                                />
                                <input
                                    type="password"
                                    placeholder={t('signIn:password')}
                                    onChange={e => authorizationController.onChangeData("password", e.target.value)}
                                    value={authorizationController.data.password}
                                    required
                                />
                                <Row gutter={[10, 10]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                        <Button type="primary" block htmlType="submit">{t('signIn:enter')}</Button>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                                        <Button type="primary" href="/signup" className="template-button-other" block htmlType="submit">{t('signIn:registration')}</Button>
                                    </Col>
                                    <Col span={24}>
                                        <a href="/forgot" className="authorization-forgot">{t('signIn:forgot')}</a>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
            {loaderController.load && <LoaderPage />}
        </>
    )
})

export default Authorization