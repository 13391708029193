import {makeAutoObservable} from "mobx";
import instance from "../../../../store/axios";
import loaderController from "../../../template/loader/loader.controller";
import sender from "../../../template/controllers/sender";
import deleteFile from "../../../../store/deleteFile.controller";
import hashController from "../../../template/controllers/hash.controller";
import pc from "../../properties/controllers/properties.controller"
import {notification} from "antd";

class categoryController {
    constructor() {
        makeAutoObservable(this)
    }

    baseUrl = "/product/categoryedit/"

    categories = {
        data: [],
        page: 1
    }

    deleteProps = []
    updatedProps = []

    category = {}

    breadcrumb = [
        { link: "/shop", value: "Мой магазин" },
        { link: "/shop/catalog", value: "Каталог" }
    ]

    tree = []

    setPage(page) {
        this.categories.page = page
        this.getCategories()
    }

    getCategories(parentID=0) {
        loaderController.onChange(true)
        instance.get(this.baseUrl + parentID + "?page=" + this.categories.page)
            .then(success => {
                this.categories.data = success.data.data
                loaderController.onChange(false)
            })
            .catch(error => {
                loaderController.onChange(false)
            })
    }

    activatedCategoryByCategoryID(categoryID, value) {

    }

    upOrDownCategoryByCategoryID(categoryID, value) {

    }

    pushToBreadcrumb(breadcrumb) {
        breadcrumb.map(item => this.breadcrumb.push({ link: item[2] === "category" ? "/shop/catalog/category/" + item[0] : "/shop/catalog/product/" + item[0], value: item[1] }))
    }

    getCategoryByCategoryID(type, categoryID) {
        if(type === "category") {
            sender.get("/product/categoryview/" + categoryID)
                .then(success => {
                    console.log(success)
                    let pictures = [];
                    if(success.data.picture !== null) {
                        pictures = success.data.picture;
                    }
                    if(success.data.breadcrumb) {
                        this.pushToBreadcrumb(success.data.breadcrumb)
                    }
                    this.category = {
                        category: success.data,
                        id: success.data.id,
                        name: success.data.name,
                        text: success.data.text,
                        filtercategory: success.data.filtercategory,
                        view: Number(success.data.view),
                        parentid: Number(success.data.parentid),
                        breadcrumb: success.data.breadcrumb,
                        picture: pictures
                    }
                })
                .catch(error => console.log(error))
        } else {
            sender.get('/product/categoryview/?parentid=' + categoryID)
                .then(success => {
                    console.log(success)
                    this.category = {
                        breadcrumb: success.data.breadcrumb,
                        parentid: categoryID,
                        categorys: success.data.categorys && success.data.categorys[0] ? success.data.categorys[0] : [],
                        view: 1
                    }
                })
                .catch(error => console.log(error))
        }
    }

    deleteCategoryByCategoryID(categoryID) {

    }

    saveCategory() {
        let facts = new FormData();
        facts.append('name', this.category.name);
        facts.append('text', this.category.text);
        facts.append('view', this.category.view);
        facts.append('parentid', this.category.parentid);

        if(this.category.picture) {
            facts.append('pictures', JSON.stringify(this.category.picture));
        }

        console.log(this.category.filtercategory)

        this.category.filtercategory && this.category.filtercategory.length > 0 && this.category.filtercategory.map(item => facts.append("filtercatid[]", item))
        facts.append('hash', hashController.setHash(facts));

        let url = "/product/category/"
        if(this.category.category && this.category.category.id && this.category.category.id > 0) {
            url = url + this.category.category.id
        } else {
            url = url + 0
        }

        sender.post(url, facts)
            .then(success => {
                if(success.status === 201) {
                    document.location.href="/shop/catalog/category/" + success.data.parentid;
                } else {
                    notification.success({
                        message: 'Готово',
                        description: 'Категория успешно изменена'
                    })
                }
            })
            .catch(error => console.log(error))
    }

    postCategory(e) {
        e.preventDefault()
        this.saveCategory()
    }

    setFilters(filterCategoryID) {

    }

    setCategory(name, value) {
        if(name === "filtercategory") {
            if(this.category.filtercategory && this.category.filtercategory.length > 0) {
                if(this.category.filtercategory.find(el => el === value)) {
                    console.log("Убираем")
                    this.category.filtercategory = this.category.filtercategory.filter(el => el !== value)
                } else {
                    console.log("Добавляем")
                    this.category.filtercategory.push(value)
                }
            } else {
                let arr = []
                arr.push(value)
                this.category.filtercategory = arr
            }
        } else {
            this.category[name] = value
        }
    }

    deletePicture(url) {
        deleteFile("category", url, Number(this.category.id))
        this.setCategory("picture", [])
    }

    getCategoryTree(parentID=0) {
        loaderController.onChange(true)
        sender.get("/product/category/" + parentID)
            .then(success => {
                console.log(success.data)
                this.tree = success.data.category
                loaderController.onChange(false)
            })
            .catch(error => {
                console.log(error)
                loaderController.onChange(false)
            })
    }

    getInfoByTab(tab) {
        switch (tab) {
            case "parent":
                this.getCategoryTree()
                break
            case "filter":
                pc.getProperties()
                break
        }
    }

    setTabs(value) {
        this.getInfoByTab(value)
    }

}

export default new categoryController()