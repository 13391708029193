import { makeAutoObservable } from "mobx"
import __constructor from "./__constructor.controller"
import i18n from '../../../i18n';

class __filter {

    data = {
        screens: [
            { title: i18n.t('constructor:filter.screens.items.home'), type: "home" }
        ],
        selectableScreen: "home",
        types: [
            { title: i18n.t('constructor:filter.blocks.items.ksStoryBlock'), type: "ksstoryblock", screens: ['home'] },
            { title: i18n.t('constructor:filter.blocks.items.ksSlider'), type: "ksslider", screens: ['home', 'catalog'] },
            { title: i18n.t('constructor:filter.blocks.items.ksBlockProduct'), type: "ksblockproduct", screens: ['home', 'basket'] },
            { title: i18n.t('constructor:filter.blocks.items.ksButtonText'), type: "ksbuttontext", screens: ['home'] },
            { title: i18n.t('constructor:filter.blocks.items.ksVideoCategory'), type: "ksvideocategory", screens: ['home'] },
            { title: i18n.t('constructor:filter.blocks.items.search'), type: "search", disabled: true, screens: ['home'] },
            { title: i18n.t('constructor:filter.blocks.items.order'), type: "lastorder", disabled: true, screens: ['home'] },
            { title: i18n.t('constructor:filter.blocks.items.buttons'), type: "buttons", disabled: true, screens: ['home'] }
        ],
        unSelectableTypes: []
    }

    conf = []

    config(type, config = {}) {
        this.conf.push({
            type: type,
            config: config
        })
    }

    constructor() {
        makeAutoObservable(this)
    }

    setPlace(place) {
        this.data.selectableScreen = place
        this.conf = []

        if(place === 'catalog') {
            this.config('select', { key: 'horizontal', value: false })
        }

        __constructor.get()
    }

    getFilterTypes() {
        let filter = ""
        this.data.types.map(item => {
            if(this.data.unSelectableTypes.findIndex(el => el === item.type) === -1) {
                filter = `${filter}&filterpage[type][]=${item.type}`
            }
        })
        return filter
    }

    select(type) {
        console.log(type)
        if(this.data.unSelectableTypes.findIndex(el => el === type) > -1) {
            this.data.unSelectableTypes = this.data.unSelectableTypes.filter(el => el !== type)
        } else {
            this.data.unSelectableTypes.push(type)
        }
        __constructor.get()
    }
}

export default new __filter()