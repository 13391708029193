import { makeAutoObservable } from "mobx"
import __constructor from "./__constructor.controller"
import i from 'i18next';

class __configurations {

    constructor() {
        makeAutoObservable(this)
    }

    ksslider = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.slider.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.slider.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "select",
            name: "typeview",
            label: i.t('constructor:config.slider.type'),
            refs: (e) => __constructor.set("typeview", e),
            items: [
                { label: i.t('constructor:config.slider.items.one'), value: 1, horizontal: false  },
                { label: i.t('constructor:config.slider.items.two'), value: 2, horizontal: false  },
                { label: i.t('constructor:config.slider.items.three'), value: 3, horizontal: false  },
                { label: i.t('constructor:config.slider.items.four'), value: 4, horizontal: false  },
                { label: i.t('constructor:config.slider.items.five'), value: 5, horizontal: true },
                { label: i.t('constructor:config.slider.items.six'), value: 6, horizontal: false },
                { label: i.t('constructor:config.slider.items.seven'), value: 7, horizontal: true },
                { label: i.t('constructor:config.slider.items.eight'), value: 8, horizontal: false }
            ],
            required: true
        }
    ]

    ksvideocategory = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.videoCategory.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.videoCategory.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "select",
            name: "typeview",
            label: i.t('constructor:config.videoCategory.type'),
            refs: (e) => __constructor.set("typeview", e),
            items: [
                { label: i.t('constructor:config.videoCategory.items.one'), value: 1 },
                { label: i.t('constructor:config.videoCategory.items.two'), value: 2 }
            ],
            required: true
        }
    ]

    ksstoryblock = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.storyBlock.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.storyBlock.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "select",
            name: "typeview",
            label: i.t('constructor:config.storyBlock.type'),
            refs: (e) => __constructor.set("typeview", e),
            items: [
                { label: i.t('constructor:config.storyBlock.items.one'), value: 1 },
                { label: i.t('constructor:config.storyBlock.items.two'), value: 2 }
            ],
            required: true
        }
    ]

    ksbuttontext = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.buttonText.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.buttonText.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "string",
            name: "text",
            label: i.t('constructor:config.buttonText.text'),
            refs: (e) => __constructor.set("text", e)
        },
        {
            type: "string",
            name: "buttontext",
            label: i.t('constructor:config.buttonText.buttonText'),
            refs: (e) => __constructor.set("buttontext", e)
        },
        {
            type: "string",
            name: "buttoncolor",
            label: i.t('constructor:config.buttonText.colorButton'),
            refs: (e) => __constructor.set("buttoncolor", e)
        },
        {
            type: "string",
            name: "buttontextcolor",
            label: i.t('constructor:config.buttonText.colorTextButton'),
            refs: (e) => __constructor.set("buttontextcolor", e)
        },
        {
            type: "link",
            name: "link"
        },
        {
            name: "typelink"
        }
    ]

    ksblockproduct = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.blockProduct.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.blockProduct.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "product",
            name: "type",
            label: i.t('constructor:config.blockProduct.typeView'),
            refs: (e) => __constructor.set("blockid", e),
            refsType: (e) => __constructor.set("type", e)
        },
        {
            type: "select",
            name: "typeview",
            label: i.t('constructor:config.blockProduct.type'),
            refs: (e) => __constructor.set("typeview", e),
            items: [
                { label: i.t('constructor:config.blockProduct.items.one'), value: 1 },
                { label: i.t('constructor:config.blockProduct.items.two'), value: 2 }
            ],
            required: true
        },
        {
            name: "blockid",
            label: i.t('constructor:config.blockProduct.value'),
            required: true
        }
    ]

    ksbaner = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.banner.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.banner.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "string",
            name: "text",
            label: i.t('constructor:config.banner.text'),
            refs: (e) => __constructor.set("text", e)
        },
        {
            type: "link",
            name: "link"
        },
        {
            name: "typelink"
        },
        {
            type: "image",
            name: "photo"
        }
    ]

    ksvideo = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.video.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.video.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "string",
            name: "video",
            label: i.t('constructor:config.video.link'),
            refs: (e) => __constructor.set("video", e)
        },
        {
            type: "image",
            name: "photo"
        }
    ]

    ksstory = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.story.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.story.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "image",
            name: "photo"
        },
        {
            type: "select",
            name: "typeview",
            label: i.t('constructor:config.story.type'),
            refs: (e) => __constructor.set("typeview", e),
            items: [
                { label: i.t('constructor:config.story.items.one'), value: 1 },
                { label: i.t('constructor:config.story.items.two'), value: 2 },
                { label: i.t('constructor:config.story.items.three'), value: 3 },
                { label: i.t('constructor:config.story.items.four'), value: 4 }
            ],
            required: true
        }
    ]

    ksstoryslide = [
        {
            type: "switch",
            name: "active",
            label: i.t('constructor:config.storySlide.active'),
            refs: (e) => __constructor.set("active", e)
        },
        {
            type: "string",
            name: "name",
            label: i.t('constructor:config.storySlide.name'),
            refs: (e) => __constructor.set("name", e)
        },
        {
            type: "string",
            name: "namecolor",
            label: i.t('constructor:config.storySlide.colorTitle'),
            refs: (e) => __constructor.set("namecolor", e)
        },
        {
            type: "string",
            name: "text",
            label: i.t('constructor:config.storySlide.text'),
            refs: (e) => __constructor.set("text", e)
        },
        {
            type: "string",
            name: "textcolor",
            label: i.t('constructor:config.storySlide.textColor'),
            refs: (e) => __constructor.set("textcolor", e)
        },
        {
            type: "image",
            name: "photo",
            label: i.t('constructor:config.storySlide.image'),
            required: true
        },
        {
            type: "select",
            name: "imagedisplaystory",
            label: i.t('constructor:config.storySlide.typeImage'),
            refs: (e) => __constructor.set("imagedisplaystory", e),
            items: [
                { label: i.t('constructor:config.storySlide.items.one'), value: 1 },
                { label: i.t('constructor:config.storySlide.items.two'), value: 2 }
            ],
            required: true
        },
        {
            type: "dependence",
            name: "button",
            label: i.t('constructor:config.storySlide.button'),
            refs: (e) => __constructor.set("button", e),
            dependence: true
        },
        {
            type: "string",
            dependences: "button",
            name: "buttoncolor",
            label: i.t('constructor:config.storySlide.buttonColor'),
            refs: (e) => __constructor.set("buttoncolor", e)
        },
        {
            type: "string",
            dependences: "button",
            name: "buttontext",
            label: i.t('constructor:config.storySlide.buttonText'),
            refs: (e) => __constructor.set("buttontext", e)
        },
        {
            type: "string",
            dependences: "button",
            name: "buttontextcolor",
            label: i.t('constructor:config.storySlide.buttonTextColor'),
            refs: (e) => __constructor.set("buttontextcolor", e)
        },
        {
            type: "link",
            dependences: "button",
            name: "link"
        },
        {
            name: "typelink"
        }
    ]

    getConfig(type) {
        __constructor.selectConfig(this[type])
    }

}

export default new __configurations()