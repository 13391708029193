import {makeAutoObservable} from "mobx";
import instance from "../../../store/axios";
import {notification} from "antd";
import {useEffect} from "react";

class forgotController {
    constructor() {
        makeAutoObservable(this)
    }

    data = {
        login: "",
        code: "",
        codeConfirm: null,
        status: 0,
        password: "",
        confirmPassword: ""
    }

    status = 0

    error = ""

    onChangeData(key, value) {
        this.data[key] = value

        if(key === "login") {
            let rand = 11111 - 0.5 + Math.random() * (99999 - 11111 + 1);
            this.data.codeConfirm = Math.round(rand)
        }
    }

    onChangeStatus(value) {
        this.status = value
    }

    onSubmit(e) {
        e.preventDefault()

        let facts = new FormData();
        facts.append('login', this.data.login);
        facts.append('code', this.data.code);
        facts.append('codeconfirm', this.data.codeConfirm);
        facts.append('password', this.data.password);
        facts.append('confirmpassword', this.data.confirmPassword);
        facts.append('status', this.status);

        console.log(this.data)

        instance.post('/user/forgot/', facts)
            .then(response => {
            console.log(response);
            if(response.status === 200){
                this.onChangeStatus(3);
            }
            else if(response.status === 201){
                this.onChangeStatus(1);
            }
            else if(response.status === 202){
                this.onChangeStatus(2);
            }
            else{
                notification.error({
                    placement: "topRight",
                    message: "Ошибка",
                    description: response.data.error,
                });
            }
        })
    }
}

export default new forgotController()