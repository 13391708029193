import { observer } from 'mobx-react-lite'
import controller from './controller'
import { useEffect } from 'react'
import TemplateTitle from '../template/title/TemplateTitle'
import { Button, Space, Table, Tooltip, Modal, Input, Select } from 'antd'
import { SettingOutlined, DeleteOutlined, UserOutlined, PicLeftOutlined } from '@ant-design/icons'

const getString = (timestamp) => {
    let date = new Date(timestamp * 1000)
    return `${('0' + (date.getUTCDay())).slice(-2)}.${('0' + (date.getUTCMonth())).slice(-2)}.${date.getUTCFullYear()} ${('0' + (date.getUTCHours())).slice(-2)}:${('0' + (date.getUTCMinutes())).slice(-2)}`
}

export const Complaints = observer(() => {

    useEffect(() => {
        controller.listTypes()
        controller.list()
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Дата создания",
            dataIndex: 'date',
            key: 'date',
            render: (el) => getString(el)
        },
        {
            title: "Жалоба на",
            dataIndex: 'object',
            key: 'object',
            render: (el, record) => controller.types.find(elem => elem.id === record.complainttypeid) ? controller.types.find(elem => elem.id === record.complainttypeid).name : 'Неизвестно'
        },
        {
            title: "Принадлежит пользователю",
            dataIndex: 'complaint_user_id',
            key: 'complaint_user_id',
            render: (el, record) => <a href={`/users/${record.complaint_user_id}`} target="_blank">Перейти</a>
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        {record.object === 'post' &&
                            <Tooltip title="Перейти к посту">
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    href={"/posts/" + record.objectid}
                                    icon={<PicLeftOutlined />}
                                    target="_blank"
                                />
                            </Tooltip>
                        }
                        {record.object === 'comment' &&
                            <Tooltip title={`Текст комментария: ${record.fullobject.comment.text}`} placement="left">
                                <Button
                                    type="dashed"
                                    shape="circle"
                                    icon={<PicLeftOutlined />}
                                />
                            </Tooltip>
                        }
                        <Tooltip title="Автор жалобы">
                            <Button
                                type="dashed"
                                shape="circle"
                                href={"/users/" + record.userid}
                                icon={<UserOutlined />}
                                target="_blank"
                            />
                        </Tooltip>
                        <Tooltip title="Удалить">
                            <Button danger type="dashed" shape="circle" onClick={() => controller.remove(record.id)} icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <TemplateTitle
                title="Список жалоб"
            />

            {controller.types.length > 0 &&
                <Table
                    columns={columns}
                    dataSource={controller.elements}
                    pagination={false}
                />
            }


        </>
    )
})

export const ComplaintsTypes = observer(() => {

    useEffect(() => {
        controller.listTypes()
    }, [])

    const columns = [
        {
            title: "Наименование",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Объект",
            dataIndex: 'object',
            key: 'object',
            render: (record) => record === 'post' ? 'Пост' : record === 'comment' ? 'Комментарий' : 'Пользователь'
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title="Редактировать">
                            <Button
                                type="dashed"
                                shape="circle"
                                onClick={() => controller.openEditType(record.id)} icon={<SettingOutlined />}
                            />
                        </Tooltip>
                        <Tooltip title="Удалить">
                            <Button danger type="dashed" shape="circle" onClick={() => controller.removeType(record.id)} icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    return(
        <>
            <TemplateTitle
                title="Типы жалоб"
                buttons={[
                    { title: "Создать", type: "button", ref: () => controller.openEditType(0) }
                ]}
            />

            <Table
                columns={columns}
                dataSource={controller.types}
                pagination={false}
            />

            <Modal
                title="Редактирование"
                open={controller.visible}
                onOk={() => controller.save_type()}
                onCancel={() => controller.close()}
            >
                <label>Наименование</label>
                <Input
                    value={controller.element.name}
                    onChange={(e) => controller.onChange('name', e.target.value)}
                />

                <br/><br/>
                <label>Объект</label>
                <Select defaultValue="user" value={controller.element.object} style={{ width: '100%' }}
                    onChange={(e) => controller.onChange('object', e)}
                >
                    <Select.Option value="user">Пользователь</Select.Option>
                    <Select.Option value="post">Пост</Select.Option>
                    <Select.Option value="comment">Комментарий</Select.Option>
                </Select>
            </Modal>

        </>
    )
})