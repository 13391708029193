import React from 'react';
import {
    Row,
    Col,
    Button,
    Tooltip,
    Space
} from 'antd';

import { InstagramOutlined, FacebookOutlined, BehanceOutlined } from '@ant-design/icons';

import { withTranslation } from 'react-i18next';

function Footer({ t }) {

    return(
        <>
            {localStorage.getItem('user_active') && localStorage.getItem('user_active') === '1' &&
            <div className="footer">
                <Row justify="space-around" className="in">
                    <Col span={10} className="logo">
                        <p>SalesKit</p>
                        <span>{t('footer.yourMobileApp')}</span>
                    </Col>
                    <Col span={10} className="icons">
                        <Space>
                            {/*<Tooltip title="Instagram"><Button shape="circle" href="#" icon={<InstagramOutlined />} ghost /></Tooltip>*/}
                            {/*<Tooltip title="Facebook"><Button shape="circle" href="#" icon={<FacebookOutlined />} ghost /></Tooltip>*/}
                            {/*<Tooltip title="Behance"><Button shape="circle" href="#" icon={<BehanceOutlined />} ghost /></Tooltip>*/}
                        </Space>
                    </Col>
                </Row>
            </div>
            }
        </>
    )
}

export default withTranslation()(Footer);