import { Row, Col } from "antd"
import saleskitLogo from "../../../images/saleskit.svg"
import Lang from "./Lang";
import "./header.less"
import {observer} from "mobx-react-lite";
import store from "store"

const HeaderDefault = observer(() => {

    return(
        <header className="header-default">
            <Row justify="space-around" align="middle">
                <Col span={6}>
                    {/*<Lang />*/}
                </Col>
                <Col span={12} className="for-logo">
                    <img
                        src={saleskitLogo}
                        alt="SalesKit"
                        className="logotype"
                    />
                </Col>
                <Col span={6} className="logout">
                    {store.get('user') && <a href="/logout">Выйти</a>}
                </Col>
            </Row>
        </header>
    )
})

export default HeaderDefault