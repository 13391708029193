import { makeAutoObservable, makeObservable } from "mobx";
import axios from "../axios";
import {notification} from "antd";
import {useTranslation, withTranslation} from "react-i18next";
import instance from "../axios";

class Products {

    products = [];
    limit = 10;
    page = 1;
    search = {
        name: "",
        id: null,
        id_crm: null
    };

    pagination = {
        page: 1,
        all: 0
    };

    fromSearch = 0;

    displaySimilar = false;
    displayWith = false;
    similarType = "0";
    withType = "0";
    similar = [];
    withArray = [];
    similarCategory = null;
    withCategory = null;
    similarProducts = [];
    withProducts = [];

    constructor() {
        makeAutoObservable(this);
    }

    onChange(name, value) {
        this.search[name] = value;
        this.getProductsByFilter();
    }

    getProductsByFilter() {
        console.log(this.search);
        let url = "/shop/search?limit=" + this.limit + "&page=" + this.page;

        if(this.search.id > 0) {
            url = url + "&id=" + this.search.id;
        } else if(this.search.id_crm !== null) {
            url = url + "&id_crm=" + this.search.id_crm;
        } else if(this.search.name !== "") {
            url = url + "&name=" + this.search.name;
        }

        instance.get(url)
            .then(success => {
                console.log(success);
                this.products = success.data.data.products[0];
                this.pagination.page = success.data.data.pagination[0];
                this.pagination.all = success.data.data.pagination[1];
            });
    }

    editActiveProduct(id, value) {
        let active = 1;
        if(value === false) {
            active = 0;
        }
        let data = new FormData();
        data.append('view', active);

        instance.post('/shop/product/' + id + '/', data)
            .then(response => {
                console.log(response);
                this.getProductsByFilter();
            });
    }

    deleteProduct(id, confirmText) {
        if (window.confirm(confirmText)) {
            instance.delete('/shop/product/' + id + '/').then(response => {
                if(response.data.code === 200){
                    this.getProductsByFilter();
                }
            })
        }
    }

    onClickPage(page) {
        this.page = page;
        this.getProductsByFilter();
    }

    setSimilar(array) {
        this.similar = array;
    }

    openSimilarModal() {
        this.displaySimilar = true;
        this.getProductsByFilter();
    }

    closeSimilarModal() {
        this.displaySimilar = false;
    }

    addToSimilar(productID) {
        this.similar = [...this.similar, productID];
        this.getProductsByFilter();
    }

    deleteFromSimilar(productID) {
        this.similar = this.similar.filter(el => el !== productID);
        this.getProductsByFilter();
    }

    setWith(array) {
        this.withArray = array;
    }

    openWithModal() {
        this.displayWith = true;
        this.getProductsByFilter();
    }

    closeWithModal() {
        this.displayWith = false;
    }

    addToWith(productID) {
        this.withArray = [...this.withArray, productID];
        this.getProductsByFilter();
    }

    deleteFromWith(productID) {
        this.withArray = this.withArray.filter(el => el !== productID);
        this.getProductsByFilter();
    }

    setSimilarType(type) {
        this.similarType = type;
    }

    setSimilarCategory(categoryID) {
        this.similarCategory = categoryID;
    }

    setSimilarProducts(array) {
        this.similarProducts = array;
    }

    setWithType(type) {
        this.withType = type;
    }

    setWithCategory(categoryID) {
        console.log(categoryID)
        this.withCategory = categoryID;
    }

    setWithProducts(array) {
        this.withProducts = array;
    }

}

export default new Products();