import store from "store";
import {notification} from "antd";
import instance from "./axios";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

async function getObjectByURL(url) {

    let img = new Image()
    img.src = url

    return new Promise(resolve => {
        img.onload = function () {
            resolve({
                width: img.width,
                height: img.height,
                path: url
            });
        }
    });
}

export default function getLoader(e, type, name, path, ref) {
    let facts = new FormData()
    facts.append("path", type + "/" + path)
    facts.append("file", e.file)
    instance.post("/shop/upload2/", facts)
        .then(success => {
            if(path !== "import") {
                getObjectByURL(success.data)
                    .then(r => {
                        ref(r);
                    })
            } else {
                ref(success.data);
            }
        })
        .catch(error => console.log(error))



    // if (info.file.status === "done") {
    //     let data = []
    //     info.fileList.map(item => {
    //         if(item.status === "done") {
    //             getObjectByURL(item.response)
    //                 .then(r => data.push(r))
    //         }
    //     });
    //     return new Promise(resolve => {
    //         resolve(data)
    //     })
    // }
}