import { makeAutoObservable } from "mobx"
import hashController from "../../../../../template/controllers/hash.controller";
import sender from "../../../../../template/controllers/sender";
import _editor from "../../../../../editor/_editor";
import hashtag from './hashtag'

class _post {

    new_tag_visible = false
    new_tag_value = ''

    data = {
        id: 0,
        name: "",
        photo: null,
        view: 1,
        productid: 0,
        text: []
    }

    constructor() {
        makeAutoObservable(this)
    }

    onChangeVisibleNewTag(visible = false) {
        this.new_tag_visible = visible
    }

    onChangeValueNewTag(value) {
        this.new_tag_value = value
    }

    onChange(name, value) {
        this.data[name] = value
    }

    deleteTag(tagId) {
        this.data.hashtagid = this.data.hashtagid.filter(el => el !== tagId)
        // this.save()
    }

    onChangeNewTag(name) {
        if(name !== '')
            hashtag.isSet(name)
                .then(result => {
                    this.data.hashtagid.push(result)
                    this.new_tag_value = ''
                    this.new_tag_visible = false
                    this.save()
                })
    }

    getPost(postID) {
        sender.get(`/post/post/${postID}`)
            .then(response => {
                console.log(response.data)
                this.data = response.data
                this.data.hashtagid = []

                if(this.data.hashtag && this.data.hashtag.length > 0) {
                    this.data.hashtag.map(el => this.data.hashtagid.push(el.id))
                }

                _editor.setBlocks(response.data.text)
            })
            .catch(response => console.log(response))
    }

    save() {
        let facts = new FormData()

        facts.append("name", this.data.name)
        facts.append("photo", this.data.photo ? JSON.stringify(this.data.photo) : null)
        facts.append("view", this.data.view)
        facts.append("userid", 0)
        facts.append("productid", this.data.productid)
        facts.append("text", JSON.stringify(_editor.blocks))

        if(this.data.hashtagid.length > 0)
            this.data.hashtagid.map(el => facts.append('hashtagid[]', el))

        facts.append("hash", hashController.setHash(facts))

        sender.post(`/post/post/${this.data.id}`, facts)
            .then(success => {
                console.log(success)
                if(this.data.id && this.data.id > 0)
                    this.getPost(this.data.id)
                else
                    document.location.href="/posts"
            })
            .catch(error => console.log(error))
    }

    onSubmit(e) {
        e.preventDefault()
        this.save()
    }
}

export default new _post()