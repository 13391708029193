import React, { useState, useEffect } from 'react'
import axios from "axios";
import {Tag, Button, Table, Col, Row, Typography, Tooltip, Breadcrumb} from 'antd';
import {SettingOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../store/axios";

const { Title } = Typography;

function PagesList({ t }) {
    const [data, setData] = useState({
        pages: null,
    });

    useEffect(() => {
        instance.get('/info/pages/')
            .then(response => {
                console.log(response)
                setData({
                    ...data,
                    pages: response.data.data[0]
                })
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    const columns = [
        {
            title: t('pages:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('pages:status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                let status = t('pages:notActive');
                if(record.active > 0) {
                    status = t('pages:active');
                }
                return(
                    <>{status}</>
                )
            }
        },
        {
            title: t('pages:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Tooltip title={t('pages:edit')}>
                    <Button type="dashed" shape="circle" href={"/pages/" + record.id} icon={<SettingOutlined />} />
                </Tooltip>
            ),
        },
    ];

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('pages:list')}</Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" href={"/pages/create"}>{t('pages:create')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('pages:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('pages:pages')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Table columns={columns} dataSource={data.pages} pagination={false} />
        </>
    )
}

export default withTranslation()(PagesList);