import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Button, Col, Row, Switch} from "antd";
import spsc from "../../controllers/shopProductStatuses.controller"
import { useEffect } from "react";
import {ColorPicker} from "primereact/colorpicker";
import {SettingOutlined} from "@ant-design/icons";

const Status = observer(({ status }) => {
    return(
        <>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
            >
                <form onSubmit={e => spsc.onSubmit(e)}>
                    <Row className="order-status">
                        {status.id !== spsc.status.id &&
                        <div className="for"><Button onClick={() => spsc.selectStatus(status.id)} shape="circle" type="dashed" icon={<SettingOutlined />} /></div>
                        }
                        <Col span={24}>
                            <p className="order_id">#{ status.id }</p>
                        </Col>
                        {status.id === spsc.status.id
                            ? <>
                                <span className="description">Название статуса</span>
                                <input
                                    type="text"
                                    className="status_input"
                                    placeholder="Наименование статуса"
                                    value={spsc.status.name}
                                    onChange={e => spsc.onChangeStatus("name", e.target.value)}
                                    required
                                />

                                <Row>
                                    <Col span={24}>
                                        <span className="description">Выберите цвет статуса</span>
                                    </Col>
                                    <Col>
                                        <input
                                            type="text"
                                            className="status_input"
                                            value={status.colorhex}
                                            style={{ colorText: status.colorhex }}
                                            onChange={(e) => spsc.onChangeStatus("colorhex", e.target.value)}
                                        />
                                        <ColorPicker value={status.colorhex} onChange={(e) => spsc.onChangeStatus("colorhex", e.value)} />
                                    </Col>
                                </Row>

                                <div className="select-main_block">
                                    <Row justify="space-around" align="middle" className="select-main">
                                        <Col span={20}>
                                            <span className="description">Выводить на главной</span>
                                        </Col>
                                        <Col span={4} className="right">
                                            <Switch
                                                checked={spsc.status.viewmain && spsc.status.viewmain > 0 && true}
                                                onChange={e => spsc.onChangeSwitch("viewmain", e)}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>

                                    <Row justify="space-around" align="middle" className="select-main">
                                        <Col span={20}>
                                            <span className="description">Рекомендовать в корзине</span>
                                        </Col>
                                        <Col span={4} className="right">
                                            <Switch
                                                checked={spsc.status.viewcart && spsc.status.viewcart > 0 && true}
                                                onChange={e => spsc.onChangeSwitch("viewcart", e)}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <Col span={24}>
                                    <Row gutter={[15, 15]}>
                                        <Col span={24}><Button htmlType="submit" block type="primary">Сохранить</Button></Col>
                                        {status.id > 3 && <Col span={24}><Button block type="primary" danger onClick={() => spsc.deleteStatus(status.id)}>Удалить</Button></Col>}
                                    </Row>
                                </Col>

                            </>
                            : <Col span={24}>
                                <p className="order_name" style={{ color: "#" + status.colorhex }}>{ status.name }</p>
                            </Col>
                        }
                    </Row>
                </form>
            </Col>
        </>
    )
})

const ShopProductStatuses = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        spsc.getAllStatuses()
    }, [])

    return(
        <>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('status:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('status:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('status:statuses')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18 }}>
                    <h1>Статусы товаров</h1>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 6 }} className="right">
                    <Button type="primary" onClick={() => spsc.createStatus()}>Создать статус</Button>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                {spsc.statuses.map(item => <Status status={item} />)}
            </Row>
        </>
    )
})

export default ShopProductStatuses