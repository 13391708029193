import { observer } from "mobx-react-lite";
import { Row, Col } from 'antd';


import "./styles/title.less"

const TemplateListTitle = observer(({ columns }) => {

    return(
        <Row className="template_list-title" justify="space-between">
            {columns.map(item =>
                <Col
                    key={ item.key }
                    className={ item.align ? "element right" : "element" }
                >
                    { item.title }
                </Col>
            )}
        </Row>
    )
})

const TemplateListElementActions = observer(({ element, item }) => {
    return(
        <Col
            className={ element.align ? "element right" : "element" }
        >
            {element.value.map(el => el.render(el, item))}
        </Col>
    )
})

const TemplateListElementContent = observer(({ element, item }) => {
    return(
        <Col
            className={ element.align ? "element right" : "element" }
        >
            <p>{ item[element.key] }</p>
        </Col>
    )
})

const TemplateListElement = observer(({ item, columns }) => {

    return(
        <Row className="template_list-element">
            {columns.map(el =>
                <>
                    {el.key === "actions" ? <TemplateListElementActions element={el} item={item} /> : <TemplateListElementContent element={el} item={item} />}
                </>
            )}
        </Row>
    )
})

const TemplateListElements = observer(({ data, columns }) =>
    data.map(item => <TemplateListElement item={ item } columns={ columns } />))

const TemplateList = observer(({ columns, data }) => {

    return(
        <>
            <TemplateListTitle columns={ columns } />
            <TemplateListElements data={ data } columns={ columns } />
        </>
    )
})

export default TemplateList