import { observer } from "mobx-react-lite";
import {Drawer, Image, Tag} from 'antd';
import _post from "../controllers/_post";
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"
import React from "react";


const PostsText = observer(() => {
    let listItems = _post.item.text.map(item => (
        <>
            {item.type === "text" && item.value}
            {item.type === "image" && <img src={item.value.path} alt="" width="100%" />}
            {item.type === "link" && <p><a href={item.value} target="_blank">Перейти по ссылке</a></p>}
        </>
    ))

    return listItems
})

const Posts = observer(() => {
    return(
        <Drawer title={_post.item.name} placement="right" visible={_post.visible} onClose={() => _post.close()} width={520}>
            <div>
                <label>Наименование</label>
                <p>{_post.item.name}</p>
            </div>
            <div>
                <label>Дата создания</label>
                <p>{newDateTimeHelper(_post.item.date)}</p>
            </div>

            {_post.item.photo !== "" && _post.item.photo !== null && _post.item.photo.path ?
                <>
                    <div>
                        <label>Обложка</label><br/>
                        <Image
                            width={'200px'}
                            src={_post.item.photo.path}
                        />
                    </div>
                </>
             : ""}



            <div>
                <label>Относится к статусу:</label><br/>
                <a href={`/shop/catalog/product/${_post.item.productid}`} target="_blank">Открыть в новой вкладке</a>
            </div>
            <div>
                <label>Создано пользователем:</label><br/>
                <a href={`/users/${_post.item.userid}`} target="_blank">{_post.item.username}</a>
            </div>
            <div>
                <label>Статус публикации:</label><br/>
                <p>
                    {_post.item.view === 0 && "Ожидает модерации"}
                    {_post.item.view === 1 && "Опубликовано"}
                    {_post.item.view === 2 && "Отклонено"}
                </p>
            </div>

            <PostsText />

        </Drawer>
    )
})

export default Posts