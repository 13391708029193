import { observer } from "mobx-react-lite";
import TemplateTitle from "../../../template/title/TemplateTitle";
import {Button, Image, Radio, Space, Table, Tooltip} from 'antd';
import _post from "../controllers/_post";
import React, {useEffect} from "react";
import {DeleteOutlined, SettingOutlined, CloseOutlined, CheckOutlined} from "@ant-design/icons";
import _loader from "../../../template/loader/loader.controller"
import { newDateTimeHelper } from "../../../template/helpers/templateHelpers"
import Posts from "./Post";

const PostsList = observer(() => {

    const breadcrumb = [
        { link: "/shop", value: "Магазин" },
        { link: null, value: "Посты" }
    ]

    const options = [
        { label: 'Ожидают модерации', value: 0 },
        { label: 'Опубликованные', value: 1 },
        { label: 'Отклоненные', value: 2 }
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            render: (_this) => _this !== "" && _this !== null && _this.path ? <Image
                width={'100px'}
                src={_this.path}
            /> : ""
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Автор',
            dataIndex: 'username',
            key: 'username',
            render: (_this, item) => (
                <a href={item.userid} target="_blank">{_this}</a>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'date',
            key: 'date',
            render: (_this) => newDateTimeHelper(_this)
        },
        {
            title: "Действия",
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Просмотр">
                        <Button type="dashed" shape="circle" href={`/posts/${record.id}`} icon={<SettingOutlined />} />
                    </Tooltip>
                    {record.view === 0 &&
                        <>
                            <Tooltip title="Одобрить">
                                <Button type="dashed" shape="circle" icon={<CheckOutlined />} onClick={() => _post.approve(record.id)} />
                            </Tooltip>
                            <Tooltip title="Отклонить">
                                <Button type="dashed" shape="circle" icon={<CloseOutlined />} onClick={() => _post.unApprove(record.id)} danger />
                            </Tooltip>
                        </>
                    }
                    {record.view === 1 &&
                        <>
                            <Tooltip title="Отклонить">
                                <Button type="dashed" shape="circle" icon={<CloseOutlined />} onClick={() => _post.unApprove(record.id)} danger />
                            </Tooltip>
                        </>
                    }
                    {record.view === 2 &&
                        <>
                            <Tooltip title="Одобрить">
                                <Button type="dashed" shape="circle" icon={<CheckOutlined />} onClick={() => _post.approve(record.id)} />
                            </Tooltip>
                        </>
                    }
                    <Tooltip title="Удалить">
                        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} onClick={() => _post.delete(record.id)} danger />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        _post.getList()
    }, [])

    return(
        <>
            <TemplateTitle
                title="Список постов"
                breadcrumb={breadcrumb}
            />

            <Radio.Group
                options={options}
                optionType="button"
                buttonStyle="solid"
                onChange={e => _post.onChangeTab(e.target.value)}
                value={_post.tab}
            />
            <br/><br/>

            <Table
                columns={columns}
                dataSource={_post.list}
                pagination={false}
            />

            {_post.end ? <Button onClick={() => _post.setOffset()}>Загрузить еще</Button> : "Показаны все записи"}

            {_post.visible && <Posts />}
        </>
    )
})

export default PostsList