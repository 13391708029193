import React, {useState, useEffect} from 'react';
import axios from "axios";

import {
    Table, Typography, Button, Col, Row, Modal, Space, Tooltip, Input, notification, Breadcrumb, DatePicker, TimePicker
} from 'antd';
import {DeleteOutlined, DownOutlined, SettingOutlined, UnorderedListOutlined, UpOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../../store/axios";

function Points({ t }) {

    const [update, setUpdate] = useState(0);
    const [points, setPoints] = useState([]);
    const [point, setPoint] = useState({
        display: false,
        id: 0,
        name: '',
        latitude: '',
        longitude: '',
        address: '',
        date1start: '',
        date1end: '',
        date2start: '',
        date2end: '',
        date3start: '',
        date3end: '',
        text: '',
    });

    useEffect(() => {
        instance.get('/shop/points/')
            .then(response => {
                setPoints(response.data.data[0]);
            })
    }, [update]);

    const closeModal = () => {
        setPoint({
            display: false,
            id: 0,
            name: '',
            latitude: '',
            longitude: '',
            address: '',
            date1start: '',
            date1end: '',
            date2start: '',
            date2end: '',
            date3start: '',
            date3end: '',
            text: '',
        });
    };

    const openModal = (id) => {
        instance.get('/shop/point/' + id + '/')
            .then(response => {
                setPoint({
                    ...point,
                    display: true,
                    id: response.data.data.id,
                    name: response.data.data.name,
                    latitude: response.data.data.latitude,
                    longitude: response.data.data.longitude,
                    address: response.data.data.address,
                    date1start: response.data.data.date1start,
                    date1end: response.data.data.date1end,
                    date2start: response.data.data.date2start,
                    date2end: response.data.data.date2end,
                    date3start: response.data.data.date3start,
                    date3end: response.data.data.date3end,
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    const columns = [
        {
            title: t('points:addressPoint'),
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: t('points:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <>
                    <Space>
                        <Tooltip title={t('points:edit')}><Button type="dashed" shape="circle" icon={<SettingOutlined />} href={"/shop/points/" + record.id} /></Tooltip>
                        <Tooltip title={t('points:delete')}><Button type="dashed" shape="circle" onClick={() => deletePoint(record.id)} icon={<DeleteOutlined />} danger /></Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const sendForm = () => {
        console.log(point);
        let data = new FormData();
        data.append('name', point.name);
        data.append('latitude', point.latitude);
        data.append('longitude', point.longitude);
        data.append('address', point.address);
        data.append('date1start', point.date1start);
        data.append('date1end', point.date1end);
        data.append('date2start', point.date2start);
        data.append('date2end', point.date2end);
        data.append('date3start', point.date3start);
        data.append('date3end', point.date3end);

        instance.post('/shop/point/' + point.id + '/', data)
            .then(response => {
                console.log(response);
                if(response.data.code === 200) {
                    setUpdate(update + 1);
                    notification.success({
                        placement: "bottomLeft",
                        message: t('points:success'),
                        description: t('points:editSuccess'),
                    });
                    closeModal();
                } else if (response.data.code === 201) {
                    document.location.href="/shop/points";
                }
            })
    };

    const deletePoint = (id) => {
        if (window.confirm(t('points:confirm'))) {
            instance.delete('/shop/point/' + id + '/')
                .then(response => {
                    if(response.data.code === 200){
                        notification.success({
                            placement: "bottomLeft",
                            message: t('points:success'),
                            description: t('points:deleteSuccess'),
                        });
                        setUpdate(update + 1);
                    }
                })
        }
    };

    const createPoint = () => {
        setPoint({
            display: true,
            id: 0,
            name: '',
            latitude: '',
            longitude: '',
            address: '',
            date1start: '',
            date1end: '',
            date2start: '',
            date2end: '',
            date3start: '',
            date3end: '',
            text: '',
        });
    };

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Typography.Title>{t('points:list')}</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" href={"/shop/points/0"}>{t('points:create')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('points:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{t('points:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('points:points')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Table columns={columns} dataSource={points} pagination={false} />
            <Modal
                title={t('points:editPoint')}
                visible={point.display}
                onOk={sendForm}
                onCancel={closeModal}
                centered={true}
            >
                <label className="all-label">{t('points:name')}</label>
                <Input
                    value={point.name}
                    onChange={(e) => setPoint({ ...point, name: e.target.value })}
                    required
                />
                <label className="all-label">{t('points:address')}</label>
                <Input
                    value={point.address}
                    onChange={(e) => setPoint({ ...point, address: e.target.value })}
                    required
                />
                <label className="all-label">{t('points:latitude')}</label>
                <Input
                    value={point.latitude}
                    onChange={(e) => setPoint({ ...point, latitude: e.target.value })}
                    required
                />
                <label className="all-label">{t('points:longitude')}</label>
                <Input
                    value={point.longitude}
                    onChange={(e) => setPoint({ ...point, longitude: e.target.value })}
                    required
                />

                <br/><br/>
                <Typography.Title level={5}>{t('points:weekdays')}</Typography.Title>
                <label className="all-label">{t('points:start')}</label>
                <Input
                    value={point.date1start}
                    onChange={(e) => setPoint({ ...point, date1start: e.target.value })}
                    required
                />

                <label className="all-label">{t('points:end')}</label>
                <Input
                    value={point.date1end}
                    onChange={(e) => setPoint({ ...point, date1end: e.target.value })}
                    required
                />

                <br/><br/>
                <Typography.Title level={5}>{t('points:saturday')}</Typography.Title>
                <label className="all-label">{t('points:start')}</label>
                <Input
                    value={point.date2start}
                    onChange={(e) => setPoint({ ...point, date2start: e.target.value })}
                    required
                />

                <label className="all-label">{t('points:end')}</label>
                <Input
                    value={point.date2end}
                    onChange={(e) => setPoint({ ...point, date2end: e.target.value })}
                    required
                />

                <br/><br/>
                <Typography.Title level={5}>{t('points:sunday')}</Typography.Title>
                <label className="all-label">{t('points:start')}</label>
                <Input
                    value={point.date3start}
                    onChange={(e) => setPoint({ ...point, date3start: e.target.value })}
                    required
                />

                <label className="all-label">{t('points:end')}</label>
                <Input
                    value={point.date3end}
                    onChange={(e) => setPoint({ ...point, date3end: e.target.value })}
                    required
                />
            </Modal>
        </>
    )
}

export default withTranslation()(Points);