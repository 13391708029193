import { makeAutoObservable } from "mobx";
import sender from "../../../template/controllers/sender";
import _loader from "../../../template/loader/loader.controller"
import hashController from "../../../template/controllers/hash.controller";

class _post {

    list = []

    item = {}
    visible = false
    visibleList = false
    visibleListBanners = false

    limit = 10
    offset = 0
    productID = 0

    end = true
    endList = true

    tab = 0

    constructor() {
        makeAutoObservable(this)
    }

    setItem(item={}) {
        this.item = item
        this.visible = true
    }

    close() {
        this.item = {}
        this.visible = false
    }

    setOffset(type="list") {
        this.offset = this.offset + this.limit

        if(type === "list") {
            this.getList()
        } else {
            this.getListByProductID(this.productID)
        }
    }

    closeList() {
        this.list = []
        this.visibleList = false
    }

    getBannersByProductID(productID) {
        this.productID = productID
        sender.get(`/post/post?filter[productid]=${productID}&limit=${this.limit}&offset=${this.offset}`)
            .then(response => {
                console.log(response)
                if(response.data.items.length === 0) {
                    this.endList = false
                }
                this.list = this.list.concat(response.data.items)
                this.visibleListBanners = true
            })
            .catch(response => {

            })
    }

    getListByProductID(productID) {
        this.productID = productID
        sender.get(`/post/post?filter[productid]=${productID}&limit=${this.limit}&offset=${this.offset}`)
            .then(response => {
                console.log(response)
                if(response.data.items.length === 0) {
                    this.endList = false
                }
                this.list = this.list.concat(response.data.items)
                this.visibleList = true
            })
            .catch(response => {

            })
    }

    getList() {
        _loader.onChange(true)
        sender.get(`/post/post?filter[view]=${this.tab}&limit=${this.limit}&offset=${this.offset}`)
            .then(response => {
                console.log(response)
                if(response.data.items.length === 0) {
                    this.end = false
                } else {
                    this.end = true
                }
                this.list = this.list.concat(response.data.items)
                _loader.onChange(false)
            })
            .catch(response => {
                _loader.onChange(false)
            })
    }

    onChangeTab(e) {
        console.log(e)
        this.tab = e
        this.list = []
        this.getList()
    }

    delete(postID) {
        if(window.confirm(`Вы уверены, что хотите удалить пост?`)) {
            let facts = {
                typestructure: "ksPost"
            }

            sender.delete(`/product/delete/${postID}`, { data: "typestructure=ksPost&hash=" + hashController.setHashDelete(facts) })
                .then(response => {
                    console.log(response)
                    this.getList()
                })
                .catch(response => {
                    console.log("Ошибка удаления")
                    console.log(response)
                })
        }
    }

    approve(postID) {
        let facts = new FormData()
        facts.append("view", 1)
        facts.append("hash", hashController.setHash(facts))
        sender.post(`/post/post/${postID}`, facts)
            .then(response => {
                console.log(response)
                this.list = this.list.filter(el => el.id !== postID)
                this.getList()
            })
            .catch(response => {

            })
    }

    unApprove(postID) {
        let facts = new FormData()
        facts.append("view", 2)
        facts.append("hash", hashController.setHash(facts))
        sender.post(`/post/post/${postID}`, facts)
            .then(response => {
                console.log(response)
                this.list = this.list.filter(el => el.id !== postID)
                this.getList()
            })
            .catch(response => {

            })
    }
}

export default new _post()