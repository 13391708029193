import React, {useState, useEffect} from 'react';
import {
    Menu
} from 'antd';
import {withTranslation} from "react-i18next";

const nowPath = window.location.pathname;
const url = nowPath.split("/");

function HeaderMenu ({ t }) {

    const [key, setKey] = useState('');

    useEffect(() => {
        if(url[1] === '') {
            setKey('dashboard');
        } else {
            setKey(url[1]);
        }
    }, [url]);

    return(
        <Menu mode="horizontal" selectedKeys={[key]}>
            <Menu.Item key="dashboard">
                <a href="/">{t('header:menu.dashboard')}</a>
            </Menu.Item>
            <Menu.Item key="shop">
                <a href="/shop">{t('header:menu.shop')}</a>
            </Menu.Item>
            <Menu.Item key="users">
                <a href="/users">{t('header:menu.users')}</a>
            </Menu.Item>
            <Menu.Item key="pages">
                <a href="/pages">{t('header:menu.pages')}</a>
            </Menu.Item>
            <Menu.Item key="news">
                <a href="/news">{t('header:menu.news')}</a>
            </Menu.Item>
            <Menu.Item key="contacts">
                <a href="/contacts">{t('header:menu.contacts')}</a>
            </Menu.Item>
        </Menu>
    )
}

export default withTranslation()(HeaderMenu);