import {makeAutoObservable} from "mobx";
import store from "store";
import instance from "../../../store/axios";
import loaderController from "../../template/loader/loader.controller";

class codeController {
    constructor() {
        makeAutoObservable(this)
    }

    error = ""
    code = null

    onSubmit(e) {
        e.preventDefault()

        loaderController.onChange(true)

        let facts = new FormData();
        facts.append('id', store.get("user").id);
        facts.append('code', this.code);

        instance.post('/user/authcode/', facts)
            .then(response => {
                store.set('user', response.data);
                document.location.href="/";
            })
            .catch(error => {
                this.error = error.response.data
                loaderController.onChange(false)
            })
    }

    onChange(value) {
        this.code = value
    }

}

export default new codeController()