import {useRoutes} from 'hookrouter';
import Routes from "./Routes";
import axios from 'axios';
import { YMInitializer } from 'react-yandex-metrika';
import store from "store"

import Header from "./components/Header";
import Footer from "./components/footer/Footer";

import HeaderUser from "./modules/template/header/HeaderUser";

import './App.less';
import {Col, Row} from "antd";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import React from "react";

const HOST = process.env.REACT_APP_HOST;
const APIKEY = process.env.REACT_APP_APIKEY;

const getCookies = url => {
    if(url !== "") {
        url = url.replace("?", "")
        let arr = url.split("&")
        if(arr.length > 0) {
            let utm = ""
            arr.map(item => {
                let element = item.split("=")
                if(element[0] === "ref") {
                    document.cookie = "ref=" + element[1]
                } else {
                    utm = utm + "&" + element[0] + "=" + element[1]
                }
            })
            store.set("utm", utm)
        }
    }
}


function App() {
    let routeResult = useRoutes(Routes);

    getCookies(window.location.search)

    axios.defaults.headers.common['Authorizations'] = APIKEY;
    axios.defaults.baseURL = HOST;

    if(store.get("user") && store.get("user").bandl && store.get("user").bandl !== null) {
        axios.defaults.headers.common['bandl'] = store.get("user").bandl;
    }

    if(document.referrer !== "") {

        if(store.get("referrer") && store.get("referrer") !== "") {
        } else {
            store.set("referrer", document.referrer)
        }
    }

    return (
        <>
            <YMInitializer
                accounts={[84756061]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true

                }}
            />
            <Router>
                <HeaderUser content={Routes} />
            </Router>
            {/*<Header />*/}
            {/*<Row justify="space-around" align="top" className="template-content">*/}
            {/*    <Col span={21} className="">*/}
            {/*        {routeResult}*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    );
}

export default App;
