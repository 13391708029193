import React, { useState, useEffect } from 'react'
import axios from "axios";
import {Button, Table, Col, Row, Typography, Tooltip, Breadcrumb, Space} from 'antd';
import {DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../store/axios";

function NewsList({ t }) {

    const [news, setNews] = useState([]);
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        instance.get("/info/news")
            .then(success => {
                console.log(success);
                setNews(success.data.data[0]);
            })
            .catch(error => console.log(error));
    }, [update]);

    const deleteNews = (newsID) => {
        if(window.confirm(t('news:confirm'))) {
            console.log("/info/news/" + newsID + "/");
            instance.delete("/info/news/" + newsID)
                .then(success => {
                    console.log(success);
                    setUpdate(update + 1);
                })
                .catch(error => console.log(error));
        }
    };

    const columns = [
        {
            title: t('news:view'),
            dataIndex: 'active',
            key: 'active'
        },
        {
            title: t('news:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('news:type'),
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: t('news:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Tooltip title={t('news:edit')}>
                        <Button type="dashed" shape="circle" href={"/news/" + record.id} icon={<SettingOutlined />} />
                    </Tooltip>
                    <Tooltip title={t('news:delete')}>
                        <Button type="dashed" shape="circle" icon={<DeleteOutlined />} danger onClick={() => deleteNews(record.id)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Typography.Title>{t('news:list')}</Typography.Title>
                </Col>
                <Col span={8} align="right">
                    <Button type="primary" href={"/news/new"}>{t('news:create')}</Button>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('news:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('news:news')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Table columns={columns} dataSource={news} pagination={false} />
        </>
    )
}

export default withTranslation()(NewsList);