import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {Col, Descriptions, Row, Typography, Button, Breadcrumb, Space} from 'antd';
import {withTranslation, useTranslation} from "react-i18next";
import instance from "../../store/axios";
import {useParams} from "react-router-dom";
import sender from '../../modules/template/controllers/sender';
import hashController from '../../modules/template/controllers/hash.controller';

const { Title } = Typography;

function UserDetail() {
    let { userID } = useParams();
    const { t } = useTranslation();
    const [user, setUser] = useState({
        id: 0,
        firstname: '',
        lastname: '',
        surname: '',
        email: '',
        phone: '',
        birthday: 0,
        ban: 0
    });

    const getUser = () => {
        instance.get('/users/user/' + userID + '/').then(response => {
            console.log(response);

            let date = 0;
            if(Number(response.data.data.birthday) > 0) {
                date = new Date(Number(response.data.data.birthday) * 1000);

                date = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
            }

            setUser({
                id: response.data.data.id,
                firstname: response.data.data.firstname,
                lastname: response.data.data.lastname,
                surname: response.data.data.surname,
                email: response.data.data.email,
                phone: response.data.data.phone,
                nikname: response.data.data.nikname,
                ban: response.data.data.ban,
                birthday: date,
            })
        })
    }

    useEffect(() => {
        getUser()
    }, []);

    const editUser = (data) => {
        const facts = new FormData()
        facts.append('ban', data.ban)
        facts.append("hash", hashController.setHash(facts))
        sender.post(`/user/useredit/${user.id}`, facts)
            .then(result => {
                console.log(result)
                getUser()
            })
    }

    const deactivateUser = () => {
        const data = {
            ban: 1
        }
        editUser(data)
    }

    const activateUser = () => {
        const data = {
            ban: 0
        }
        editUser(data)
    }

    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('user:info')}</Title>
                </Col>
                <Col span={8} className="right">
                    <Space>
                        {user.ban > 0
                            ? <Button type="dashed" onClick={activateUser}>Разблокировать</Button>
                            : <Button type="dashed" danger onClick={deactivateUser}>Заблокировать</Button>
                        }
                        <Button type="dashed" href={"/users"}>{t('user:list')}</Button>
                    </Space>
                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('user:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/users"}>{t('user:users')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('user:user')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Descriptions
                size="small"
            >
                <Descriptions.Item label="Никнейм">{user.nikname}</Descriptions.Item>
                <Descriptions.Item label="E-mail">{user.email}</Descriptions.Item>
                <Descriptions.Item label={t('user:phone')}>{user.phone}</Descriptions.Item>
                <Descriptions.Item label="ФИО">{user.firstname} {user.lastname} {user.surname}</Descriptions.Item>
                {Number(user.birthday) !== 0 &&
                    <Descriptions.Item label={t('user:birthday')}>{user.birthday}</Descriptions.Item>
                }
            </Descriptions>
        </>
    )
}

export default withTranslation()(UserDetail);